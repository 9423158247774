import get from 'lodash/get';

import {
  addMarketplaceEntities,
  getListingsEntities,
} from '../../ducks/marketplaceData.duck.js';
import {
  storableError,
} from '../../util/errors';
import {
  fetchAirplanesMetadata,
} from '../../modules/airplanes/airplanes.creator';
import {
  getImagesParams,
} from '../../util/listing';

// ================ Action types ================ //

export const FETCH_NEWEST_LISTINGS = 'app/NewestListings/FETCH_NEWEST_LISTINGS';
export const FETCH_NEWEST_LISTINGS_SUCCESS = 'app/NewestListings/FETCH_NEWEST_LISTINGS_SUCCESS';
export const FETCH_NEWEST_LISTINGS_ERROR = 'app/NewestListings/FETCH_NEWEST_LISTINGS_ERROR';

export const RESET_STATE = 'app/NewestListings/RESET_STATE';

// ================ Reducer ================ //

const initialState = {
  ids: [],
  isPending: false,
  error: null,
};

export default function newestListingsReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_NEWEST_LISTINGS:
      return {
        ...state,
        isPending: true,
        error: null,
      };

    case FETCH_NEWEST_LISTINGS_SUCCESS: {
      return {
        ...state,
        isPending: false,
        ids: payload,
      };
    }

    case FETCH_NEWEST_LISTINGS_ERROR:
      return {
        ...state,
        isPending: false,
        error: payload,
      };

    case RESET_STATE:
      return initialState;

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchNewestListingsRequest = () => ({
  type: FETCH_NEWEST_LISTINGS,
});

const fetchNewestListingsSuccess = ids => ({
  type: FETCH_NEWEST_LISTINGS_SUCCESS,
  payload: ids,
});

const fetchNewestListingsError = error => ({
  type: FETCH_NEWEST_LISTINGS_ERROR,
  payload: error,
});

const resetStateAction = () => ({
  type: RESET_STATE,
});

// ================ Selectors ============= //

const $subState = state => {
  return state.NewestListings;
};

export const $newestListingsState = state => {
  const subState = $subState(state);
  return {
    data: getListingsEntities(state)(subState.ids),
    isPending: subState.isPending,
    error: subState.error,
  };
};

// ================ Thunks ================ //

export const fetchNewestListings = () => (dispatch, getState, sdk) => {
  dispatch(fetchNewestListingsRequest());
  const params = {
    page: 1,
    perPage: 4,
    pub_show_in_search: 'yes',
    sort: 'pub_publishDate',
    include: ['author', 'images'],
    'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData.companyName'],
    ...getImagesParams(),
  };

  return sdk.listings
    .query(params)
    .then(async response => {
      const data = get(response, 'data.data', []);
      const ids = data.map(i => i.id);
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchAirplanesMetadata(data));
      dispatch(fetchNewestListingsSuccess(ids));
      return response;
    })
    .catch(e => {
      dispatch(fetchNewestListingsError(storableError(e)));
      throw e;
    });
};

export const resetState = () => dispatch => {
  dispatch(resetStateAction());
};
