import React from 'react';
import classnames from 'classnames';

import { GreatGoodDealsService } from '../../services/GreatGoodDeals.service';
import GreatDealIcon from '../PricingAnalysisIcons/GreatDealIcon';
import GoodDealIcon from '../PricingAnalysisIcons/GoodDealIcon';
import FairDealIcon from '../PricingAnalysisIcons/FairDealIcon';
import HighPriceIcon from '../PricingAnalysisIcons/HighPriceIcon';
import OverpricedIcon from '../PricingAnalysisIcons/OverpricedIcon';
import NoPriceAnalysisIcon from '../PricingAnalysisIcons/NoPriceAnalysisIcon';
import UncertainIcon from '../PricingAnalysisIcons/UncertainIcon';

import css from './DealBlock.module.scss';

const DealBlock = (props) => {
  const {
    rootClassName,
    estimate_evaluation_text,
  } = props;

  if (!estimate_evaluation_text) {
    return null;
  }

  let content;

  switch (estimate_evaluation_text) {
    case GreatGoodDealsService.GREAT_DEAL:
      content = (
        <>
          <GreatDealIcon />
          <span className={css.greatDeal}>{estimate_evaluation_text}</span>
        </>
      );
      break;

    case GreatGoodDealsService.GOOD_DEAL:
      content = (
        <>
          <GoodDealIcon />
          <span className={css.goodDeal}>{estimate_evaluation_text}</span>
        </>
      );
      break;

    case GreatGoodDealsService.FAIR_DEAL:
      content = (
        <>
          <FairDealIcon />
          <span className={css.fairDeal}>{estimate_evaluation_text}</span>
        </>
      );
      break;

    case GreatGoodDealsService.HIGH_DEAL:
      content = (
        <>
          <HighPriceIcon />
          <span className={css.highPrice}>{estimate_evaluation_text}</span>
        </>
      );
      break;

    case GreatGoodDealsService.OVERPRICED_DEAL:
      content = (
        <>
          <OverpricedIcon />
          <span className={css.overpriced}>{estimate_evaluation_text}</span>
        </>
      );
      break;

    case GreatGoodDealsService.NO_EVALUATION_AVAILABLE_DEAL:
      content = (
        <>
          <NoPriceAnalysisIcon />
          <span className={css.noPriceAnalysis}>{estimate_evaluation_text}</span>
        </>
      );
      break;

    default:
      content = (
        <>
          <UncertainIcon />
          <span className={css.uncertain}>{estimate_evaluation_text}</span>
        </>
      );
      break;
  }

  return (
    <div className={classnames(css.root, rootClassName)}>
      {content}
    </div>
  );
};

export default DealBlock;
