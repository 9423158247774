import { combineReducers } from 'redux';
import { simpleReduxFieldReducer } from 'simple-redux-field';

import { USER_LOGOUT } from './ducks/Auth.duck';
import * as globalReducers from './ducks';
import * as pageReducers from './containers/reducers';
import { servicesReducers } from './services/services';
import { DEFAULT_APP_FIELDS as NOTIFICATIONS_DEFAULT_APP_FIELDS } from './modules/notifications/notifications.constants';
import { DEFAULT_APP_FIELDS as USER_DEFAULT_APP_FIELDS } from './modules/user/user.constants';
import { DEFAULT_APP_FIELDS as AIRPLANES_DEFAULT_APP_FIELDS } from './modules/airplanes/airplanes.constants';

export const appReducers = {
  ...simpleReduxFieldReducer({
    ...NOTIFICATIONS_DEFAULT_APP_FIELDS,
    ...USER_DEFAULT_APP_FIELDS,
    ...AIRPLANES_DEFAULT_APP_FIELDS,
  }),
  ...globalReducers,
  ...pageReducers,
  ...servicesReducers,
};

/**
 * Function _createReducer_ combines global reducers (reducers that are used in
 * multiple pages) and reducers that are handling actions happening inside one page container.
 * Since we combineReducers, pageReducers will get page specific key (e.g. SearchPage)
 * which is page specific.
 * Future: this structure could take in asyncReducers, which are changed when you navigate pages.
 */
const appReducer = combineReducers(appReducers);

const createReducer = () => {
  return (state, action) => {
    const appState = action.type === USER_LOGOUT ? undefined : state;

    // Clear sessionStorage when logging out.
    if (action.type === USER_LOGOUT && typeof window !== 'undefined' && !!window.sessionStorage) {
      window.sessionStorage.clear();
    }

    return appReducer(appState, action);
  };
};

export default createReducer;
