import React from 'react';
import classnames from 'classnames';

import {
  IconFavorite,
} from '../../components';

import css from './AddFavorites.module.css';

const AddFavoritesButton = (props) => {
  const {
    isFavorite,
    showText,
    className,
    onClick,
  } = props;

  return (
    <div
      className={classnames(css.root, className)}
      onClick={onClick}
    >
      <IconFavorite
        isFavorite={isFavorite}
        iconClass={classnames(css.icon, { [css.iconActive]: isFavorite })}
        showText={showText}
      />
    </div>
  );
};

export default AddFavoritesButton;
