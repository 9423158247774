import get from 'lodash/get';
import { BaseReducer } from 'redux-di-container';

import { storableError } from '../util/errors';
import { handleAxiosResponse } from '../util/apiHelpers';
import { sortByDate } from '../util/listing';
import { accidents } from '../selectors/registrations.selectors';

export class NtsbService extends BaseReducer {
  constructor (opts) {
    super();
    this.restApiService = opts.restApiService;
  }

  getInitialState() {
    return {
      data: null,
      dataIsPending: false,
      dataError: null,
    };
  }

  $accidents(state) {
    const thisState = this.select(state);
    return accidents(get(thisState, 'data', null));
  }

  $registrations(state) {
    const thisState = this.select(state);
    const regs = get(thisState, 'data.faa_registrations_cleaned', []);
    return sortByDate([...regs]).filter(item => item.last_action_date !== null);
  }

  fetchData(listingId) {
    if (!listingId) {
      return Promise.resolve(null);
    }
    this.dispatchAction(state => {
      state.dataIsPending = true;
      state.dataError = null;
    }, 'fetchData');
    // listingId = '65d3be43-3958-4f8b-ac23-9ec86ab6b92e';
    return this.restApiService.publicInstance
      .getRaw(`/ntsb/data/${listingId}`)
      .then(handleAxiosResponse)
      .then(data => {
        this.dispatchAction(state => {
          state.data = data;
          state.dataIsPending = false;
        }, 'fetchDataSuccess');
        return data;
      })
      .catch(e => {
        this.dispatchAction(state => {
          state.dataIsPending = false;
          state.dataError = storableError(e);
        });
        throw e;
      })
    ;
  }
}
