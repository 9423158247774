import React from 'react';
import classnames from 'classnames';
import draftToHtml from 'draftjs-to-html';

import { replaceEmails } from '../../util/blockEmail';

import css from './DarftContent.module.css';

const DarftContent = props => {
  const {
    isPreview,
    draftContent,
    className,
    onContactUser,
  } = props;

  if (!draftContent) {
    return null
  }

  const contentRef = React.useRef();

  React.useEffect(() => {
    if (!onContactUser) {
      return ;
    }
    const clickHandler = event => {
      event.preventDefault();
      onContactUser();
    };

    const tags = contentRef.current.getElementsByClassName('message-seller');

    Array.prototype.forEach.call(tags, tag => {
      tag.addEventListener('click', clickHandler);
    });

    return () => {
      Array.prototype.forEach.call(tags, tag => {
        tag.removeEventListener('click', clickHandler);
      })
    };
  }, [draftContent]);

  const draftContentToString = JSON.stringify(draftContent);
  const draftContentToObject = JSON.parse(draftContentToString);

  const validContent = draftContentToObject.indexOf("blocks") !== -1;

  if (validContent) {
    let html;
    try {
      const formattedDraftContent = draftContent
        .replace(/(\"fontfamily-[^\}]*)/ig, '"fontfamily-Montserrat"');
      html = draftToHtml(JSON.parse(formattedDraftContent));
    } catch (e) {
      html = draftToHtml(JSON.parse(draftContent));
    }

    const replacement = `[${isPreview ? '' : '<a class="message-seller" style="cursor:pointer;">'}Message Seller${isPreview ? '' : '</a>'}]`
    html = replaceEmails(html, replacement);

    return (
      <div
        ref={contentRef}
        className={classnames(css.root, className)}
      >
        <div
          className="content"
          dangerouslySetInnerHTML={{__html: html.replace(/<a href=/ig, '<a rel="nofollow" href=')}}
        />
      </div>
    )
  } else {
    return (
      <p className={classnames(css.root, className)}>
        {draftContent}
      </p>
    )
  }
};

DarftContent.defaultProps = {
  isPreview: false,
};

export default DarftContent;
