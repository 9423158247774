import React from 'react';
import { Field } from 'react-final-form';
import { isValidPhoneNumber } from 'libphonenumber-js';
import PhoneInput from 'react-phone-input-2';

import { ValidationError } from '../index';

const FieldPhoneInput2 = (props) => {
  const {
    intl,
    name,
    id,
    label,
    isRequired,
  } = props;

  return (
    <Field
      id={id}
      name={name}
      format={v => v && v.value}
      validate={(valueObj) => {
        if (!valueObj || (valueObj && valueObj.value && valueObj.value === valueObj.dialCode)) {
          if (isRequired) {
            return 'This field is required.';
          }
          return undefined;
        }
        return isValidPhoneNumber(`+${valueObj.value}`)
          ? undefined
          : intl.formatMessage({ id: 'EditListingAirSummaryForm.listingPhoneNumberError' })
        ;
      }}
    >
      {({ input, meta }) => (
        <>
          <label htmlFor={id}>
            {label}
          </label>
          <PhoneInput
            countryCodeEditable={false}
            inputProps={{ className: 'hello' }}
            country={'us'}
            {...input}
            onChange={(value, data) => {
              input.onChange({
                value,
                dialCode: data.dialCode,
              });
            }}
          />
          <ValidationError fieldMeta={meta} />
        </>
      )}
    </Field>
  );
};

FieldPhoneInput2.defaultProps = {
  isRequired: false,
};

export default FieldPhoneInput2;
