export const NAMESPACE = 'AIRPLANES';

export const FIELD__AIRPLANES_CATEGORIES = `FIELD::${NAMESPACE}::CATEGORIES`;

export const FIELD__AIRPLANES_METADATA = `FIELD::${NAMESPACE}::METADATA`;

export const INITIAL__AIRPLANES_CATEGORIES = {
  inProgress: false,
  error: null,
  value: [],
};

export const INITIAL__AIRPLANES_METADATA = {
  inProgress: false,
  error: null,
  value: {},
};

export const DEFAULT_APP_FIELDS = {
  [FIELD__AIRPLANES_METADATA]: INITIAL__AIRPLANES_METADATA,
};
