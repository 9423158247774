import { apiBaseUrl } from '../../util/api';
import {
  apiEndpoint,
  handleJsonFetch,
  handleTextFetch,
  handleTransitAxiosResponse,
} from '../../util/apiHelpers';
import axios from 'axios';

export const fetchCommentNotifications = params => {
  const url = new URL(`${apiBaseUrl()}/api/notifications/comment`);
  url.searchParams.append('page', params.page);
  url.searchParams.append('u_id', params.userId);
  url.searchParams.append('per_page', params.perPage.toString());
  return window.fetch(url, {
    method: 'GET',
    credentials: 'include',
  }).then(handleJsonFetch);
};

export const fetchClearIsNewComment = ids => {
  const url = new URL(`${apiBaseUrl()}/api/notifications/clear-is-new`);
  return window.fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ids }),
  }).then(handleTextFetch);
};

export const fetchNewCommentsCount = params => {
  const url = new URL(`${apiBaseUrl()}/api/notifications/new-comments-count`);
  url.searchParams.append('u_id', params.userId);
  return window.fetch(url, {
    method: 'GET',
    credentials: 'include',
  }).then(handleJsonFetch);
};

export const fetchNewChatCount = params => {
  const url = new URL(`${apiBaseUrl()}/api/notifications/new-chat-count`);
  url.searchParams.append('u_id', params.userId);
  return window.fetch(url, {
    method: 'GET',
    credentials: 'include',
  }).then(handleJsonFetch);
};

export const fetchAddNotification = params => {
  return axios.post(`${apiEndpoint}/notifications`, params, {
    withCredentials: true,
  })
    .then(handleTransitAxiosResponse);
};

export const fetchChatNotifications = params => {
  const url = new URL(`${apiBaseUrl()}/api/notifications/chat`);
  url.searchParams.append('u_id', params.userId);
  url.searchParams.append('tx_ids', params.txIds);
  return window.fetch(url, {
    method: 'GET',
    credentials: 'include',
  }).then(handleJsonFetch);
};

export const fetchClearIsNewChat = (userId, txId) => {
  const url = new URL(`${apiBaseUrl()}/api/notifications/clear-is-new-chat`);
  return window.fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ u_id: userId, tx_id: txId }),
  }).then(handleTextFetch);
};
