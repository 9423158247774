import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { IncludeMapLibraryScripts } from '../../util/includeScripts';
import Routes from '../../routing/Routes';
import routeConfiguration from '../../routing/routeConfiguration';
import { useEffects, connector } from './helpers';

const ClientAppComponent = props => {
  useEffects(props);

  return (
    <HelmetProvider>
      <IncludeMapLibraryScripts />
      <BrowserRouter>
        <Routes routes={routeConfiguration()} />
      </BrowserRouter>
    </HelmetProvider>
  );
};

const ClientAppContainer = connector(ClientAppComponent);

export default ClientAppContainer;
