export default class LocalStorageService {
  static TOKEN_KEY = 'access_token';

  #version = 'v1';

  #getKey(key) {
    return `${this.#version}::${key}`;
  }

  setItem(key, value) {
    window.localStorage.setItem(this.#getKey(key), JSON.stringify(value));
  }

  setExpItem(key, value, ms = 60 * 60 * 1000) {
    const item = {
      value: value,
      exp: (new Date()).getTime() + ms,
    };
    this.setItem(key, item);
  }

  getItem(key, defaultValue = null) {
    const value = window.localStorage.getItem(this.#getKey(key));
    return value ? JSON.parse(value) : defaultValue;
  }

  getExpItem(key) {
    const item = this.getItem(key);
    if (item && item.exp < (new Date()).getTime()) {
      return null;
    } else if (item) {
      return item.value;
    }
    return item;
  }

  removeItem(key) {
    window.localStorage.removeItem(this.#getKey(key));
  }

  setToken(token) {
    this.setItem(LocalStorageService.TOKEN_KEY, token);
  }

  getToken() {
    return this.getItem(LocalStorageService.TOKEN_KEY);
  }

  removeToken() {
    this.removeItem(LocalStorageService.TOKEN_KEY);
  }
}
