import React from 'react';

const GoodDealIcon = (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        width="18"
        height="18"
        fill="#407E3F"
        {...props}
      />
      <path
        d="M7.71781 13.3744L5.59649 11.2531L8.42492 8.42463L6.71372 6.71343L12.2574 6.71343L12.2574 12.2571L10.5462 10.546L7.71781 13.3744Z"
        fill={props.symbol || "white"}
      />
    </svg>
  );
};

export default GoodDealIcon;
