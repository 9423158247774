import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconPreview.module.css';

const IconPreview = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      overflow="hidden"
      viewBox="0 0 1024 1024"
    >
      <path d="M976.474 571.934C968.072 582.174 766.886 821.88 512 821.88S55.928 582.174 47.496 571.934l-17.469-21.112 17.469-21.143C55.928 519.47 257.084 279.763 512 279.763s456.072 239.706 464.474 249.916l17.469 21.143-17.469 21.112zM512 344.817c-184.47 0-343.944 149.564-397.493 205.975 53.67 56.35 213.444 206.034 397.493 206.034 184.47 0 343.944-149.564 397.493-205.974-53.67-56.38-213.444-206.035-397.493-206.035zm0 346.955c-75.204 0-136.373-63.247-136.373-140.95s61.17-140.95 136.373-140.95 136.373 63.246 136.373 140.95-61.2 140.95-136.373 140.95zm0-216.847c-40.478 0-73.427 34.063-73.427 75.927s32.949 75.896 73.427 75.896 73.427-34.033 73.427-75.896-32.949-75.927-73.427-75.927z"></path>
    </svg>
  );
};

IconPreview.defaultProps = {
  rootClassName: null,
  className: null,
  fillColor: null,
};

IconPreview.propTypes = {
  rootClassName: string,
  className: string,
  fillColor: string,
};

export default IconPreview;
