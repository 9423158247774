import { ReduxDIContainer } from 'redux-di-container';
import { PlanePriceLandingPageService } from '../containers/PlanePriceLandingPage/PlanePriceLandingPage.service.js';
import { GreatGoodDealsService } from './GreatGoodDeals.service.js';

import RestApiService, {
  PublicAxiosService,
  SecureAxiosService,
} from './RestApi.service';
import LocalStorageService from './LocalStorage.service';
import { AirplanesService } from './Airplanes.service';
import { SignModalService } from './SignModal.service';
import { ContactPageService } from '../containers/ContactPage/ContactPage.service';
import { AircraftChildLandingService } from './AircraftChildLandingPage.service'
import { FinanceModalService } from '../containers/FinanceModal/FinanceModal.service';
import { AlertModalService } from '../containers/AlertModal/AlertModal.service';
import { LandingSearchFormService } from '../containers/LandingSearchForm/LandingSearchForm.service';
import { PricingAnalysisService } from './PricingAnalysis.service';
import { FeedbackModalService } from './FeedbackModal.service';
import { NtsbService } from './Ntsb.service';
import { ShareModalService } from './ShareModal.service';
import { NewsService } from './News.service';

const di = new ReduxDIContainer();
di.registerServices([
  { key: 'localStorageService', class: LocalStorageService },
  { key: 'publicAxiosService', class: PublicAxiosService },
  { key: 'secureAxiosService', class: SecureAxiosService },
  { key: 'restApiService', class: RestApiService },
  { key: 'signModalService', class: SignModalService },
  { key: 'financeModalService', class: FinanceModalService },
  { key: 'contactPageService', class: ContactPageService },
  { key: 'aircraftChildLandingPageService', class: AircraftChildLandingService },
  { key: 'planePriceLandingPageService', class: PlanePriceLandingPageService},
  { key: 'greatGoodDealsService', class: GreatGoodDealsService},
  { key: 'alertModalService', class: AlertModalService },
  { key: 'airplanesService', class: AirplanesService },
  { key: 'landingSearchFormService', class: LandingSearchFormService },
  { key: 'pricingAnalysisService', class: PricingAnalysisService },
  { key: 'feedbackModalService', class: FeedbackModalService },
  { key: 'ntsbService', class: NtsbService },
  { key: 'newsService', class: NewsService },
  { key: 'shareModalService', class: ShareModalService },
]);

const restApiService = di.getService('restApiService');
const signModalService = di.getService('signModalService');
const contactPageService = di.getService('contactPageService');
const aircraftChildLandingPageService = di.getService('aircraftChildLandingPageService');
const planePriceLandingPageService = di.getService('planePriceLandingPageService');
const greatGoodDealsService = di.getService('greatGoodDealsService');
const financeModalService = di.getService('financeModalService');
const alertModalService = di.getService('alertModalService');
const airplanesService = di.getService('airplanesService');
const landingSearchFormService = di.getService('landingSearchFormService');
const pricingAnalysisService = di.getService('pricingAnalysisService');
const feedbackModalService = di.getService('feedbackModalService');
const ntsbService = di.getService('ntsbService');
const shareModalService = di.getService('shareModalService');
const newsService = di.getService('newsService');
const localStorageService = di.getService('localStorageService');

export {
  di,
  restApiService,
  signModalService,
  contactPageService,
  aircraftChildLandingPageService,
  planePriceLandingPageService,
  greatGoodDealsService,
  financeModalService,
  alertModalService,
  airplanesService,
  landingSearchFormService,
  pricingAnalysisService,
  feedbackModalService,
  ntsbService,
  shareModalService,
  newsService,
  localStorageService,
};

export const servicesReducers = {
  AircraftChildLandingPageService: (state = aircraftChildLandingPageService.getInitialState(), action) => state,
  PlanePriceLandingPageService: (state = planePriceLandingPageService.getInitialState(), action) => state,
  GreatGoodDealsService: (state = greatGoodDealsService.getInitialState(), action) => state,
  SignModalService: (state = signModalService.getInitialState(), action) => state,
  FinanceModalService: (state = financeModalService.getInitialState(), action) => state,
  AlertModalService: (state = alertModalService.getInitialState(), action) => state,
  AirplanesService: (state = airplanesService.getInitialState(), action) => state,
  LandingSearchFormService: (state = landingSearchFormService.getInitialState(), action) => state,
  FeedbackModalService: (state = feedbackModalService.getInitialState(), action) => state,
  NtsbService: (state = ntsbService.getInitialState(), action) => state,
  ShareModalService: (state = shareModalService.getInitialState(), action) => state,
  NewsService: (state = newsService.getInitialState(), action) => state,
  PricingAnalysisService: (state = pricingAnalysisService.getInitialState(), action) => state,
};
