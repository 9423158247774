import get from 'lodash/get';
import { storableError } from '../../util/errors';
import { restApiService } from '../../services/services';
import { handleAxiosResponse } from '../../util/apiHelpers';

// ================ Action types ================ //

const RESET_STATE = 'app/RegistrationsPhotosPage/RESET_STATE';

const GROUPS_REQUEST = 'app/RegistrationsPhotosPage/GROUPS_REQUEST';
const GROUPS_REQUEST_SUCCESS = 'app/RegistrationsPhotosPage/GROUPS_REQUEST_SUCCESS';
const GROUPS_REQUEST_ERROR = 'app/RegistrationsPhotosPage/GROUPS_REQUEST_ERROR';

const PHOTOS_REQUEST = 'app/RegistrationsPhotosPage/PHOTOS_REQUEST';
const PHOTOS_REQUEST_SUCCESS = 'app/RegistrationsPhotosPage/PHOTOS_REQUEST_SUCCESS';
const PHOTOS_REQUEST_ERROR = 'app/RegistrationsPhotosPage/PHOTOS_REQUEST_ERROR';

const REMOVE_GROUP_REQUEST = 'app/RegistrationsPhotosPage/REMOVE_GROUP_REQUEST';
const REMOVE_GROUP_SUCCESS = 'app/RegistrationsPhotosPage/REMOVE_GROUP_SUCCESS';
const REMOVE_GROUP_ERROR = 'app/RegistrationsPhotosPage/REMOVE_GROUP_ERROR';

const PHOTO_ACTION_REQUEST = 'app/RegistrationsPhotosPage/PHOTO_ACTION_REQUEST';
const PHOTO_ACTION_SUCCESS = 'app/RegistrationsPhotosPage/PHOTO_ACTION_SUCCESS';
const PHOTO_ACTION_ERROR = 'app/RegistrationsPhotosPage/PHOTO_ACTION_ERROR';

const HIDE_PHOTOS = 'app/RegistrationsPhotosPage/HIDE_PHOTOS';

// ================ Reducer ================ //

const initialData = {
  data: [],
  metadata: {},
};

const initialState = {
  selectedRegNo: null,
  photosRequestInProgress: false,
  photosRequestError: null,
  photos: initialData,
  groupsRequestInProgress: false,
  groupsRequestError: null,
  groups: initialData,
  removeGroupInProgress: false,
  removeGroupError: null,
  photoInAction: null,
  photoActionInProgress: false,
  photoActionError: null,
};


export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case RESET_STATE:
      return {
        ...initialState,
      };

    case GROUPS_REQUEST:
      return {
        ...state,
        groupsRequestInProgress: true,
        groupsRequestError: null,
      };

    case GROUPS_REQUEST_SUCCESS:
      return {
        ...state,
        groupsRequestInProgress: false,
        groups: {
          data: [
            ...state.groups.data,
            ...payload.data,
          ],
          metadata: payload.metadata,
        },
      };

    case GROUPS_REQUEST_ERROR:
      return {
        ...state,
        groupsRequestInProgress: false,
        groupsRequestError: payload,
      };

    case PHOTOS_REQUEST:
      return {
        ...state,
        selectedRegNo: payload,
        photosRequestInProgress: true,
        photosRequestError: null,
        photos: payload !== state.selectedRegNo ? initialData : state.photos,
      };

    case PHOTOS_REQUEST_SUCCESS:
      return {
        ...state,
        photosRequestInProgress: false,
        photos: {
          data: [
            ...state.photos.data,
            ...payload.data,
          ],
          metadata: payload.metadata,
        },
      };

    case PHOTOS_REQUEST_ERROR:
      return {
        ...state,
        photosRequestInProgress: false,
        photosRequestError: payload,
      };

    case REMOVE_GROUP_REQUEST:
      return {
        ...state,
        removeGroupInProgress: true,
        removeGroupError: null,
      };

    case REMOVE_GROUP_SUCCESS:
      return {
        ...state,
        removeGroupInProgress: false,
        ...(payload.photos && { photos: payload.photos }),
        ...(payload.groups && { groups: payload.groups }),
      };

    case REMOVE_GROUP_ERROR:
      return {
        ...state,
        removeGroupInProgress: false,
        removeGroupError: payload,
      };

    case PHOTO_ACTION_REQUEST:
      return {
        ...state,
        photoActionInProgress: true,
        photoInAction: payload,
        photoActionError: null,
      };

    case PHOTO_ACTION_SUCCESS:
      return {
        ...state,
        photoActionInProgress: false,
        photoInAction: null,
        photos: {
          ...state.photos,
          data: payload.photos,
        },
        ...(payload.groups && {
          groups: {
            ...state.groups,
            data: payload.groups,
          },
        }),
      };

    case PHOTO_ACTION_ERROR:
      return {
        ...state,
        photoActionInProgress: false,
        photoActionError: payload,
        photoInAction: null,
      };

    case HIDE_PHOTOS:
      return {
        ...state,
        selectedRegNo: null,
        photos: initialData,
      };

    default:
      return state;
  }
};

// ================ Action creators ================ //

const resetStateAction = () => ({
  type: RESET_STATE,
});

export const groupsRequest = () => ({
  type: GROUPS_REQUEST,
});

export const groupsRequestSuccess = payload => ({
  type: GROUPS_REQUEST_SUCCESS,
  payload,
});

export const groupsRequestError = payload => ({
  type: GROUPS_REQUEST_ERROR,
  payload,
});

export const photosRequest = payload => ({
  type: PHOTOS_REQUEST,
  payload,
});

export const photosRequestSuccess = payload => ({
  type: PHOTOS_REQUEST_SUCCESS,
  payload,
});

export const photosRequestError = payload => ({
  type: PHOTOS_REQUEST_ERROR,
  payload,
});

export const removeGroupRequest = () => ({
  type: REMOVE_GROUP_REQUEST,
});

export const removeGroupSuccess = payload => ({
  type: REMOVE_GROUP_SUCCESS,
  payload,
});

export const removeGroupError = error => ({
  type: REMOVE_GROUP_ERROR,
  payload: error,
});

export const photoActionRequest = item => ({
  type: PHOTO_ACTION_REQUEST,
  payload: item,
});

export const photoActionSuccess = (payload) => ({
  type: PHOTO_ACTION_SUCCESS,
  payload,
});

export const photoActionError = error => ({
  type: PHOTO_ACTION_ERROR,
  payload: error,
});

export const hidePhotosAction = () => ({
  type: HIDE_PHOTOS,
});

// ================ Selectors ============= //

export const $groupsState = state => {
  const subState = state.RegistrationPhotosPage;
  return {
    ...subState.groups,
    isPending: subState.groupsRequestInProgress,
    error: subState.groupsRequestError,
  };
};

export const $photosState = state => {
  const subState = state.RegistrationPhotosPage;
  return {
    ...subState.photos,
    isPending: subState.photosRequestInProgress,
    error: subState.photosRequestError,
    photoInAction: subState.photoInAction,
    photoActionInProgress: subState.photoActionInProgress,
    photoActionError: subState.photoActionError,
  };
};

export const $selectedRegNo = state => {
  return state.RegistrationPhotosPage.selectedRegNo;
};

// ================ Thunks ================ //

export const resetState = () => dispatch => {
  dispatch(resetStateAction());
};

const fetchPhotos = regNo => (dispatch, getState) => {
  const state = getState();
  const selectedRegNo = $selectedRegNo(state);
  const { metadata } = $photosState(state);
  const page = (metadata && selectedRegNo === regNo)
    ? metadata.page + 1
    : 1
  ;
  dispatch(photosRequest(regNo));
  return restApiService.secureInstance
    .getRaw('/registrations/photos', {
      params: {
        rn: regNo,
        page,
      },
    })
    .then(handleAxiosResponse)
    .then(resp => {
      dispatch(photosRequestSuccess(resp));
      return resp;
    })
    .catch(e => {
      dispatch(photosRequestError(storableError(e)));
      throw e;
    })
  ;
};

const fetchGroups = (page = 1) => dispatch => {
  dispatch(groupsRequest());
  return restApiService.secureInstance
    .getRaw('/registrations/photo-groups', { params: { page } })
    .then(handleAxiosResponse)
    .then(resp => {
      dispatch(groupsRequestSuccess(resp));
      return resp;
    })
    .catch(e => {
      dispatch(groupsRequestError(storableError(e)));
      throw e;
    })
  ;
};

export const showMoreGroups = () => (dispatch, getState) => {
  const { metadata } = $groupsState(getState());
  return dispatch(fetchGroups(metadata.page + 1));
};

export const showMorePhotos = regNo => (dispatch) => {
  return dispatch(fetchPhotos(regNo));
};

export const removeGroup = regNo => (dispatch, getState) => {
  dispatch(removeGroupRequest());
  return restApiService.secureInstance
    .postRaw(`registrations/delete-photos/${regNo}`)
    .then(handleAxiosResponse)
    .then(resp => {
      const groupsState = $groupsState(getState());
      const payload = {
        ...(resp.acceptedPhoto ? {
          photos: resp.acceptedPhoto,
          groups: {
            ...groupsState,
            data: groupsState.data.map(i => {
              if (i.regNo !== regNo) {
                return i;
              }
              return { ...i, photosCount: 1, accepted: true };
            }),
          },
        } : {
          photos: initialData,
          groups: {
            ...groupsState,
            data: groupsState.data.filter(i => i.regNo !== regNo),
          },
        }),
      };
      dispatch(removeGroupSuccess(payload));
      return resp;
    })
    .catch(e => {
      dispatch(removeGroupError(storableError(e)));
      throw e;
    })
  ;
};

export const removePhoto = item => (dispatch, getState) => {
  const state = getState();
  const photosState = $photosState(state);

  if (photosState.photoActionInProgress) {
    return Promise.resolve();
  }

  dispatch(photoActionRequest(item));
  return restApiService.secureInstance
    .postRaw('registrations/delete-photo', {
      id: item.id,
      bucket: item.bucket,
      fileKey: item.fileKey,
    })
    .then(handleAxiosResponse)
    .then(resp => {
      const { data: photos } = $photosState(state);
      const newPhotos = photos.filter(i => i.id !== item.id);
      const payload = {
        photos: newPhotos,
      };
      const { data: groups } = $groupsState(state);
      if (newPhotos.length === 0) {
        payload.groups = groups.filter(i => i.regNo !== item.regNo);
      } else {
        payload.groups = groups.map(i => {
          if (i.regNo === item.regNo) {
            // TODO my: Decrease unapproved count
            return {
              ...i,
              photosCount: i.photosCount - 1,
            };
          }
          return i;
        });
      }
      dispatch(photoActionSuccess(payload));
      return resp;
    })
    .catch(e => {
      dispatch(photoActionError(storableError(e)));
      throw e;
    })
  ;
};

export const applyPhoto = item => (dispatch, getState) => {
  const state = getState();
  const photosState = $photosState(state);

  if (photosState.photoActionInProgress) {
    return Promise.resolve();
  }

  dispatch(photoActionRequest(item));
  return restApiService.secureInstance
    .postRaw(`registrations/accept-photo/${item.id}`, {
      rn: item.regNo,
    })
    .then(handleAxiosResponse)
    .then(resp => {
      const { data: photos } = $photosState(getState());
      const newPhotos = photos.map(i => {
        if (i.id === item.id) {
          return { ...i, accepted: true };
        }
        return i;
      });
      dispatch(photoActionSuccess({ photos: newPhotos }));
      return resp;
    })
    .catch(e => {
      dispatch(photoActionError(storableError(e)));
      throw e;
    })
  ;
};

export const previewPhoto = item => (dispatch, getState) => {
  const state = getState();
  const photosState = $photosState(state);

  if (photosState.photoActionInProgress) {
    return Promise.resolve();
  }

  const url = `${window.location.origin}/registration/${item.regNo}?previewPhoto=true&photoHref=${item.href}`;
  window.open(url, '__blank');
};

export const groupClick = item => () => {
  const url = `${window.location.origin}/registration/${item.regNo}`;
  window.open(url, '__blank');
};

export const hidePhotos = () => dispatch => {
  dispatch(hidePhotosAction());
};

export const fetchPhotoApi = regNo => () => {
  return restApiService.publicInstance
    .getRaw(`/registrations/photo/${regNo}`)
    .then(handleAxiosResponse)
  ;
};

export const setFeatured = item => (dispatch, getState) => {
  dispatch(photoActionRequest(item));
  return restApiService.secureInstance
    .postRaw('/registrations/set-featured', {
      id: item.id,
      regNo: item.regNo,
    })
    .then(resp => {
      const { data: photos } = $photosState(getState());
      const newPhotos = photos.map(photo => {
        return {
          ...photo,
          featured: photo.id === item.id,
        }
      });
      dispatch(photoActionSuccess({ photos: newPhotos }));
      return resp;
    })
    .catch(e => {
      dispatch(photoActionError(storableError(e)));
      throw e;
    })
  ;
};

export const loadData = () => dispatch => {
  return dispatch(fetchGroups());
};
