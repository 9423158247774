import React from 'react';

const LogoWhite = (props) => {
  const {
    className,
  } = props;

  return (
    <svg
      width="782"
      height="87"
      viewBox="0 0 782 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_459_3145)">
        <path d="M93.4 0.5H77.5V14.7H93.4V0.5Z" fill="#EF4123"/>
        <path d="M93.4 24.1001H77.5V84.0001H93.4V24.1001Z" fill="white"/>
        <path d="M115 33.6001H115.4C119 26.8001 123.1 23.3 130.1 23.3C131.9 23.3 132.9 23.4 133.8 23.8V37.7001H133.5C123.1 36.6001 115.6 42.1001 115.6 54.7001V83.9001H99.7V24.0001H115V33.6001Z" fill="white"/>
        <path d="M134.1 53.9999C134.1 36.0999 145.8 22.3999 164 22.3999C179.5 22.3999 189.1 31.3999 191.2 44.2999H175.7C174.6 38.9999 170.6 35.0999 164.7 35.0999C155.1 35.0999 150.2 42.4999 150.2 53.9999C150.2 65.2999 154.8 72.8999 164.4 72.8999C170.8 72.8999 175.4 69.4999 176.4 62.6999H191.7C190.6 75.3999 180.7 85.5999 164.7 85.5999C145.9 85.6999 134.1 71.8999 134.1 53.9999Z" fill="white"/>
        <path d="M212 33.6001H212.4C216 26.8001 220.1 23.3 227.1 23.3C228.9 23.3 229.9 23.4 230.8 23.8V37.7001H230.5C220.1 36.6001 212.6 42.1001 212.6 54.7001V83.9001H196.7V24.0001H212V33.6001Z" fill="white"/>
        <path d="M269.8 77.3001H269.6C266.6 81.9001 262.2 85.4001 252 85.4001C239.7 85.4001 231.1 79.0001 231.1 67.1001C231.1 53.9001 241.8 49.7001 255.1 47.8001C265 46.4001 269.6 45.6001 269.6 41.0001C269.6 36.7001 266.2 33.9001 259.6 33.9001C252.1 33.9001 248.5 36.6001 248 42.3001H233.9C234.4 31.8001 242.2 22.6001 259.7 22.6001C277.7 22.6001 284.9 30.7001 284.9 44.7001V75.2001C284.9 79.8001 285.6 82.4001 287 83.5001V84.1001H271.7C270.7 82.8001 270.1 80.0001 269.8 77.3001ZM269.9 62.8001V53.8001C267.1 55.4001 262.8 56.4001 258.8 57.3001C250.5 59.2001 246.4 61.0001 246.4 66.6001C246.4 72.2001 250.1 74.2001 255.7 74.2001C264.9 74.2001 269.9 68.6001 269.9 62.8001Z" fill="white"/>
        <path d="M325.1 24.0999H333.4V5.3999H348.9V24.0999H359.2V34.9999H348.9V66.2999C348.9 70.6999 351.4 72.2999 354.9 72.2999C356.9 72.2999 359.6 72.1999 359.6 72.1999V83.8999C359.6 83.8999 356 84.0999 349.7 84.0999C342 84.0999 333.5 81.0999 333.5 69.6999V34.9999H325.2V24.0999H325.1Z" fill="white"/>
        <path d="M395.7 0.899902H454.2V15.2999H412.7V35.3999H443.2V49.8999H412.7V84.3999H395.7V0.899902Z" fill="white"/>
        <path d="M445.9 54.4999C445.9 36.5999 458.6 22.8999 477.2 22.8999C495.8 22.8999 508.5 36.6999 508.5 54.4999C508.5 72.2999 495.8 86.0999 477.2 86.0999C458.6 86.0999 445.9 72.2999 445.9 54.4999ZM492.3 54.4999C492.3 42.7999 486.8 34.8999 477.1 34.8999C467.3 34.8999 461.9 42.7999 461.9 54.4999C461.9 66.1999 467.3 73.9999 477.1 73.9999C486.8 73.9999 492.3 66.0999 492.3 54.4999Z" fill="white"/>
        <path d="M529.1 34.1001H529.5C533.1 27.3001 537.2 23.8 544.2 23.8C546 23.8 547 23.9 547.9 24.3V38.2001H547.5C537.1 37.1001 529.6 42.6001 529.6 55.2001V84.4001H513.7V24.5001H529V34.1001H529.1Z" fill="white"/>
        <path d="M569.8 59.1H586.4C587.6 69.1 593.3 73.1 605.2 73.1C613.8 73.1 621.4 70.1 621.4 62.4C621.4 54.2 613.5 52.6 600.7 49.7C585.8 46.3 572.1 42.3 572.1 25C572.1 8.5 585.5 0 604.1 0C622.9 0 635 9.2 636.3 26H620.1C619.2 17.6 612.6 13.4 604 13.4C594.9 13.4 588.9 17.3 588.9 23.2C588.9 30 594.7 32 607.1 34.6C624.3 38.3 638.3 42.4 638.3 60.4C638.3 77.3 624.6 86.8 605.9 86.8C583.2 86.7 570.4 76.7 569.8 59.1Z" fill="white"/>
        <path d="M680.4 78.2H680.2C677.2 82.8 672.8 86.3 662.6 86.3C650.3 86.3 641.7 79.9 641.7 68C641.7 54.8 652.4 50.6 665.7 48.7C675.6 47.3 680.2 46.5 680.2 41.9C680.2 37.6 676.8 34.8 670.2 34.8C662.7 34.8 659.1 37.5 658.6 43.2H644.5C645 32.7 652.8 23.5 670.3 23.5C688.3 23.5 695.5 31.6 695.5 45.6V76.1C695.5 80.7 696.2 83.3 697.6 84.4V85H682.3C681.3 83.7 680.7 80.9 680.4 78.2ZM680.5 63.8V54.8C677.7 56.4 673.4 57.4 669.4 58.3C661.1 60.2 657 62 657 67.6C657 73.2 660.7 75.2 666.3 75.2C675.5 75.2 680.5 69.6 680.5 63.8Z" fill="white"/>
        <path d="M701.8 1.3999H717.7V84.7999H701.8V1.3999Z" fill="white"/>
        <path d="M722.7 54.8999C722.7 37.1999 734.7 23.3999 752.2 23.3999C771.5 23.3999 781.7 38.0999 781.7 59.4999H738.3C739.5 68.6999 744.6 74.3999 753.6 74.3999C759.8 74.3999 763.4 71.5999 765.2 66.9999H780.8C778.6 77.5999 769.1 86.5999 753.7 86.5999C733.9 86.5999 722.7 72.6999 722.7 54.8999ZM738.6 48.4999H765.2C764.7 40.8999 759.7 35.6999 752.5 35.6999C744 35.5999 740 40.5999 738.6 48.4999Z" fill="white"/>
        <path d="M286.8 24.0998H295V18.6998C295 4.09985 306.3 -1.00016 321.6 0.599841V12.8998C314.1 12.4998 310.9 13.4998 310.9 19.6998V24.0998H321.6V35.0998H310.9V83.9998H295V35.0998H286.8V24.0998Z" fill="white"/>
        <path d="M29.1 0.300049H46.7L76.2 84.0001H58.5L52.9 66.2001H22.7L17.2 84.0001H0L29.1 0.300049ZM26.6 53.2001H48.7L41.2 29.1001C39.8 24.7001 37.8 16.2001 37.8 16.2001H37.6C37.6 16.2001 35.5 24.6001 34.1 29.1001L26.6 53.2001Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_459_3145">
          <rect width="781.7" height="86.8" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoWhite;
