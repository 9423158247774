export const SET_IS_MOBILE_SEARCH_FORM_VISIBLE = 'app/TransactionPage/SET_IS_MOBILE_SEARCH_FORM_VISIBLE';

// ================ Reducer ================ //

const initialState = {
  isMobileSearchFormVisible: false,
};

export default function topbarReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_IS_MOBILE_SEARCH_FORM_VISIBLE:
      return { ...state, isMobileSearchFormVisible: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const $isMobileSearchFormVisible = state => {
  return state.Topbar.isMobileSearchFormVisible;
};

// ================ Action creators ================ //
const setIsMobileSearchFormVisibleAction = value => ({
  type: SET_IS_MOBILE_SEARCH_FORM_VISIBLE,
  payload: value,
});

// ================ Thunks ================ //

export const showMobileSearchForm = () => dispatch => {
  dispatch(setIsMobileSearchFormVisibleAction(true));
};

export const hideMobileSearchForm = () => dispatch => {
  dispatch(setIsMobileSearchFormVisibleAction(false));
};

export const toggleMobileSearchForm = () => (dispatch, getState) => {
  const value = !$isMobileSearchFormVisible(getState());
  dispatch(setIsMobileSearchFormVisibleAction(value));
};
