import React from 'react';

const HighPriceIcon = (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        width="18"
        height="18"
        fill="#D58E4D"
        {...props}
      />
      <path
        d="M5.11091 7.23222L7.23223 5.1109L10.0607 7.93932L11.7719 6.22813L11.7719 11.7718L6.22814 11.7718L7.93934 10.0606L5.11091 7.23222Z"
        fill={props.symbol || "white"}
      />
    </svg>
  );
};

export default HighPriceIcon;
