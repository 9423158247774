import { types as sdkTypes } from '../util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
const defaultLocations = [
  {
    id: 'default-united-states',
    predictionPlace: {
      address: 'United States',
      bounds: new LatLngBounds(new LatLng(71.420291, -66.8847656), new LatLng(18.8164227, -179.9)),
    },
  },
  {
    id: 'default-new-york',
    predictionPlace: {
      address: 'New York, United States',
      bounds: new LatLngBounds(new LatLng(40.917577, -73.700292), new LatLng(40.477399, -74.2596399)),
    },
  },
  {
    id: 'default-new-york-city',
    predictionPlace: {
      address: 'New York City, New York, United States',
      bounds: new LatLngBounds(new LatLng(45.0239467, -71.763627), new LatLng(40.4771401, -79.8046875)),
    },
  },
  {
    id: 'default-new-jersey',
    predictionPlace: {
      address: 'New Jersey, United States',
      bounds: new LatLngBounds(new LatLng(41.357607, -73.8706661), new LatLng(38.8510385, -75.5741778)),
    },
  },
];
export default defaultLocations;
