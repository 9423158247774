import React from 'react';

const UncertainIcon = (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="18"
        height="18"
        fill="#B6B6B6"
      />
      <g clipPath="url(#clip0_3856_364)">
        <path
          d="M9.86486 15H7.7027V12.8218H9.86486V15ZM12.1351 9.66337C11.7027 10.099 11.2703 10.3168 10.8378 10.4257C10.1892 10.8614 9.97297 10.6436 9.97297 11.7327H7.81081C7.81081 9.55446 9.10811 8.90099 9.97297 8.46535C10.2973 8.35644 10.5135 8.24752 10.7297 8.0297C10.8378 7.92079 11.0541 7.70297 10.8378 7.26733C10.6216 6.72277 9.97297 6.17822 9 6.17822C7.48649 6.17822 7.27027 7.48515 7.16216 7.81188L5 7.48515C5.10811 6.28713 6.08108 4 8.89189 4C10.6216 4 12.1351 4.9802 12.7838 6.39604C13.2162 7.59406 13 8.79208 12.1351 9.66337Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3856_364">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(15 3) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>

  );
};

export default UncertainIcon;
