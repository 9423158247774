import React from 'react';
import { string } from 'prop-types';

const IconArrow = props => {
  const { className } = props;

  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
      <path d="M9.9999 14.03L5.9999 14.03L5.9999 8.77547L2.77323 8.77547L7.9999 3.62601L13.2266 8.77547L9.9999 8.77547L9.9999 14.03Z"/>
    </svg>
  );
};

IconArrow.defaultProps = {
  className: null,
};

IconArrow.propTypes = {
  className: string,
};

export default IconArrow;
