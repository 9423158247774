import { BaseReducer } from 'redux-di-container';

import { LISTING_TYPE__AIRPLANES } from '../../util/editListingHelpers';

export class LandingSearchFormService extends BaseReducer {
  constructor (opts) {
    super();
    this.airplanesService = opts.airplanesService;
  }

  getInitialState() {
    return {
      listingType: LISTING_TYPE__AIRPLANES,
    };
  }

  $listingType(state) {
    return this.select(state).listingType;
  }

  selectListingType(type) {
    this.dispatchAction(state => {
      state.listingType = type;
    }, 'selectListingType');
  }

  init() {
    this.airplanesService.fetchCategories();
  }
}
