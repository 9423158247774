import { $fields } from 'simple-redux-field';

import {
  ADD_NOTIFICATION_FIELD,
  COMMENT_NOTIFICATIONS_COUNT_FIELD,
  INITIAL_ADD_NOTIFICATION_FIELD,
  INITIAL_COMMENT_NOTIFICATIONS_COUNT_FIELD,
  CHAT_NOTIFICATIONS_COUNT_FIELD,
  INITIAL_CHAT_NOTIFICATIONS_COUNT_FIELD,
} from './notifications.constants';

export const $commentNotificationsCountField = state => {
  return $fields(state)[COMMENT_NOTIFICATIONS_COUNT_FIELD]
    || INITIAL_COMMENT_NOTIFICATIONS_COUNT_FIELD
  ;
};

export const $addNotificationField = state => {
  return $fields(state)[ADD_NOTIFICATION_FIELD]
    || INITIAL_ADD_NOTIFICATION_FIELD
  ;
};

export const $chatNotificationsCountField = state => {
  return $fields(state)[CHAT_NOTIFICATIONS_COUNT_FIELD]
    || INITIAL_CHAT_NOTIFICATIONS_COUNT_FIELD
  ;
};
