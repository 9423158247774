import React from 'react';

const NoPriceAnalysisIcon = (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        width="18"
        height="18"
        fill="#B6B6B6"
        {...props}
      />
      <path
        d="M5 13L13 5"
        stroke={props.symbol || "white"}
        strokeWidth="2"
      />
      <path
        d="M5 5L13 13"
        stroke={props.symbol || "white"}
        strokeWidth="2"
      />
    </svg>

  );
};

export default NoPriceAnalysisIcon;
