import { BaseReducer } from 'redux-di-container';

export class AirplanesService extends BaseReducer {
  constructor (opts) {
    super();
    this.restApiService = opts.restApiService;
  }

  getInitialState() {
    return {
      categories: [],
    };
  }

  $categories = this.createSelector(
    state => this.select(state),
    thisState => {
      return thisState.categories;
    }
  );

  fetchCategories() {
    return this.restApiService.publicInstance
      .get('/airplanes/categories')
      .then(response => {
        const categories = response.data;
        this.dispatchAction(state => {
          state.categories = categories;
        }, 'fetchCategoriesSuccess');
        return categories;
      })
      .catch(e => {
        throw e;
      })
    ;
  }

  getLandingSearchMakeModelFamilyPromise(searchVal) {
    if (searchVal.length < 3) {
      return Promise.resolve([]);
    }
    const search = new URLSearchParams({
      val: searchVal,
    });
    return this.restApiService.publicInstance
      .get(`airplanes/landing-search-make-modelFamily?${search.toString()}`)
      .then(response => response.data)
      .catch(() => { })
    ;
  }
}
