// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
import { transit, types as sdkTypes } from './sdkLoader';
import config from '../config';
import Decimal from 'decimal.js';

export const apiEndpoint = `${config.apiEndpoint}/api`;

export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value),
  },
];

export const serialize = data => {
  return transit.write(data, { typeHandlers, verbose: config.sdk.transitVerbose });
};

export const deserialize = str => {
  return transit.read(str, { typeHandlers });
};

export const handleJsonFetch = response => {
  if (response.ok) {
    return response.json();
  }
  return response.json()
    .catch(() => {
      throw new Error(response.statusText);
    })
    .then(({error}) => {
      throw new Error(error || response.statusText);
    })
  ;
};

export const handleTextFetch = response => {
  if (response.ok) {
    return response.text();
  }
  return response.text()
    .catch(() => {
      throw new Error(response.statusText);
    })
    .then(({error}) => {
      throw new Error(error || response.statusText);
    })
  ;
};

export const handleTransitAxiosResponse = response => {
  const contentTypeHeader = response.headers.get('Content-Type');
  const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

  if (response.status >= 400) {
    const data = response.data;
    let e = new Error(response.status.toString());
    e = Object.assign(e, data);
    throw e;
  }

  if (contentType === 'application/transit+json') {
    return deserialize(JSON.stringify(response.data));
  }
  return response.data;
};

export const handleAxiosResponse = response => {
  if (response.status >= 400) {
    const data = response.data;
    let e = new Error(response.status.toString());
    e = Object.assign(e, data);
    throw e;
  }
  return response.data;
};
