export const NAMESPACE = 'USERS_PAGE';
export const PER_PAGE = 28;

export const FIELD__USERS_PAGE__USERS = `FIELD::${NAMESPACE}::USERS`;

export const INITIAL_USERS = {
  inProgress: false,
  error: null,
  users: [],
  pagination: null,
};
