import { BaseReducer } from 'redux-di-container';

export class FeedbackModalService extends BaseReducer {
  constructor(opts) {
    super();
    this.restApiService = opts.restApiService;
  }

  getInitialState() {
    return {
      isOpen: false,
      headline: null,
    };
  }

  $feedbackModalState(state) {
    return this.select(state);
  }

  openModal(headline) {
    this.dispatchAction(state => {
      state.isOpen = true;
      state.headline = headline;
    });
  }

  closeModal() {
    this.resetState();
  }
}
