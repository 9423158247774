/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { ensureCurrentUser } from '../../../util/data';

import {
  InlineTextButton,
  NamedLink,
  NotificationBadge,
  IconBell,
  AvatarLarge,
} from '../../../components';

import css from './TopbarMobileMenu.module.scss';
import get from 'lodash/get';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
    commentNotificationsCountField
  } = props;

  const user = ensureCurrentUser(currentUser);

  if (!isAuthenticated) {
    const signup = (
      <NamedLink
        className={css.signupLink}
        name="SignupPage"
        to={{ state: { from: `${location.pathname}${location.search}${location.hash}` } }}
      >
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink
        className={css.loginLink}
        name="LoginPage"
        to={{ state: { from: `${location.pathname}${location.search}${location.hash}` } }}
      >
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );
    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>
        </div>
        <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="SetNewListingTypePage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div>
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const companyLogo = currentUser?.attributes.profile.publicData.companyLogo
  const isAdmin = get(currentUser, 'attributes.profile.metadata.isAdmin', false);
  const linkProps = user?.id?.uuid
    ? { name: 'ProfilePage', params: { id: user.id.uuid } }
    : { name: 'ProfileBasePage' };

  return (
    <div className={css.root}>
      <AvatarLarge
        className={css.avatar}
        user={currentUser}
        emptyAvatar
        companyLogo={companyLogo?.newHref}
      />
      <div className={css.content}>
        <NamedLink {...linkProps}>
          <span className={css.greeting}>
            <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
          </span>
        </NamedLink>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('SearchPage'))}
          name="AircraftSearchPage"
        >
          <FormattedMessage id="TopbarMobileMenu.buy" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('SearchPage'))}
          name="SetNewListingTypePage"
        >
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.sell" />
          </span>
        </NamedLink>
        <a
          href='https://flyingfinance.com/?utm_source=aircraftforsale&utm_medium=button&utm_campaign=top_nav&utm_term=finance'
          target='_blank'
          className={classNames(css.navigationLink, currentPageClass('SearchPage'))}
        >
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.finance" />
          </span>
        </a>

        {currentUserHasListings && (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('NotificationsPage'))}
            name="NotificationsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.notifications" />
            <span className={classNames(css.inbox, css.inboxBell)}>
              <IconBell />
              {!!commentNotificationsCountField?.count && <span className={css.notificationCounter}>{commentNotificationsCountField.count}</span>}
            </span>
          </NamedLink>
        )}

        <NamedLink
          className={classNames(css.inbox, currentPageClass('InboxPage'))}
          name="InboxPage"
          params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
        >
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
          name="ManageListingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('FavoritesPage'))}
          name="FavoritesPage"
        >
          <FormattedMessage id="TopbarMobileMenu.wishlist" />
        </NamedLink>
        {isAdmin && (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('RegistrationPhotosPage'))}
            name="RegistrationPhotosPage"
          >
            <FormattedMessage id="TopbarDesktop.registrationsPhotos" />
          </NamedLink>
        )}
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('SavedSearchPage'))}
          name="SavedSearchPage"
        >
          <FormattedMessage id="TopbarMobileMenu.savedSearchLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
          name="ProfileSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>
        <div className={css.spacer} />
      </div>
      <div className={css.footer}>
        <NamedLink className={css.createNewListingLink} name="SetNewListingTypePage">
          <FormattedMessage id="TopbarMobileMenu.newListingLink" />
        </NamedLink>
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
