import { restApiService } from '../services/services';
import { axiosStorableError } from '../util/errors';
import { handleAxiosResponse } from '../util/apiHelpers';

// ================ Action types ================ //

export const ACTION_COUNTER = 'app/ListingCounters/ACTION_COUNTER';
export const ACTION_COUNTER_SUCCESS = 'app/ListingCounters/ACTION_COUNTER_SUCCESS';
export const ACTION_COUNTER_ERROR = 'app/ListingCounters/ACTION_COUNTER_ERROR';
export const GET_COUNTERS = 'app/ListingCounters/GET_COUNTERS';
export const GET_COUNTERS_SUCCESS = 'app/ListingCounters/GET_COUNTERS_SUCCESS';
export const GET_COUNTERS_ERROR = 'app/ListingCounters/GET_COUNTERS_ERROR';

// ================ Reducer ================ //

const initialState = {
  actionCounterIsPending: false,
  actionCounterError: null,
  counters: null,
  countersIsPending: false,
  countersError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case ACTION_COUNTER:
      return {
        ...state,
        actionCounterIsPending: true,
        actionCounterError: null,
      };

    case ACTION_COUNTER_SUCCESS:
      return {
        ...state,
        actionCounterIsPending: false,
      };

    case ACTION_COUNTER_ERROR:
      return {
        ...state,
        actionCounterIsPending: false,
        actionCounterError: payload,
      };

    case GET_COUNTERS:
      return {
        ...state,
        countersIsPending: true,
        countersError: null,
      };

    case GET_COUNTERS_SUCCESS:
      return {
        ...state,
        countersIsPending: false,
        counters: payload,
      };

    case GET_COUNTERS_ERROR:
      return {
        ...state,
        countersIsPending: false,
        countersError: payload,
      };

    default:
      return state;
  }
}

// ================ Actions ================ //

const actionCounterRequest = () => ({
  type: ACTION_COUNTER,
});

const actionCounterSuccess = () => ({
  type: ACTION_COUNTER_SUCCESS,
});

const actionCounterError = (error) => ({
  type: ACTION_COUNTER_ERROR,
  payload: error,
});

const getCountersRequest = () => ({
  type: GET_COUNTERS,
});

const getCountersSuccess = (counters) => ({
  type: GET_COUNTERS_SUCCESS,
  payload: counters,
});

const getCountersError = (error) => ({
  type: GET_COUNTERS_ERROR,
  payload: error,
});

// ================ Selectors ================ //

export const $countersState = state => {
  return state.ListingCounters;
};

// ================ Action creators ================ //

export const addCounter = (lType, type, listingId, authorId) => (dispatch, getState) => {
  const subState = $countersState(getState());
  if (subState.actionCounterIsPending) {
    return Promise.resolve();
  }
  dispatch(actionCounterRequest());
  return restApiService.publicInstance
    .postRaw('listingCounters/addCounter', {
      listingType: lType,
      type,
      listingId,
      authorId,
    })
    .then(() => dispatch(actionCounterSuccess()))
    .catch(e => {
      dispatch(actionCounterError(axiosStorableError(e)));
      throw e;
    })
  ;
};

export const removeLastCounter = (type, lId) => (dispatch, getState) => {
  const subState = $countersState(getState());
  if (subState.actionCounterIsPending) {
    return Promise.resolve();
  }
  dispatch(actionCounterRequest());
  return restApiService.publicInstance
    .postRaw('listingCounters/removeLastCounter', {
      type,
      listingId: lId,
    })
    .then(() => dispatch(actionCounterSuccess()))
    .catch(e => {
      dispatch(actionCounterError(axiosStorableError(e)));
      throw e;
    })
  ;
};

export const getCounters = (listingId) => dispatch => {
  dispatch(getCountersRequest());
  return restApiService.publicInstance
    .getRaw('listingCounters/counters', {
      params: {
        listingId,
      },
    })
    .then(handleAxiosResponse)
    .then(resp => {
      dispatch(getCountersSuccess(resp));
      return resp;
    })
    .catch(e => {
      dispatch(getCountersError(axiosStorableError(e)));
      throw e;
    })
  ;
};
