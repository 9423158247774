import React, { Component } from 'react';
import { bool, func, object, string } from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';

import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routing/routeConfiguration';
import config from '../../../config';
import { intlShape, injectIntl } from '../../../util/reactIntl';
import { isMainSearchTypeKeywords } from '../../../util/search';
import {
  FILTER_KEY_MAKER,
  FILTER_KEY_MODEL,
  FILTER_KEY_MODEL_FAMILY,
} from '../../../containers/SearchPage/SearchPage.constants';
import {
  LISTING_TYPE__AIRPLANES,
  LISTING_TYPE__REAL_ESTATE,
  LISTING_TYPE_URL__AIRPLANES,
  LISTING_TYPE_URL__REAL_ESTATE,
} from '../../../util/editListingHelpers';

import {
  Form,
} from '../../../components';

import SearchAsyncSelect from './SearchAsyncSelect';
import ListingTypeSelector from './ListingTypeSelector';
import LocationSearchField from '../../LocationSearchField/LocationSearchField';

import css from './TopbarSearchForm.module.scss';

class TopbarSearchFormComponent extends Component {
  constructor(props) {
    super(props);
    // onChange is used for location search
    this.onChange = this.onChange.bind(this);
    // onSubmit is used for keywords search
    this.onSubmit = this.onSubmit.bind(this);

    // Callback ref
    this.searchInput = null;
    this.setSearchInputRef = element => {
      this.setSearchInput = element;
    };

    this.state = {
      selectedListingType: LISTING_TYPE__AIRPLANES,
    };
  }

  componentDidMount () {
    if (window.location.pathname.includes(LISTING_TYPE_URL__AIRPLANES)) {
      this.setState({ selectedListingType: LISTING_TYPE__AIRPLANES });
    }
    if (window.location.pathname.includes(LISTING_TYPE_URL__REAL_ESTATE)) {
      this.setState({ selectedListingType: LISTING_TYPE__REAL_ESTATE });
    }
  }

  onChange(location) {
    if (location.selectedPlace) {
      const { search, selectedPlace } = location;
      const { origin, bounds } = selectedPlace;
      this.props.history.push(createResourceLocatorString(
        'RealEstateSearchPage',
        routeConfiguration(),
        {},
        {
          bounds,
          // origin,
          address: search,
        }
      ));
    }
  }

  onSubmit(values) {
    if (this.state.selectedListingType === LISTING_TYPE__AIRPLANES) {
      const { appConfig, onSubmit } = this.props;
      if (isMainSearchTypeKeywords(appConfig) && values) {
        if (values.value === 'pub_keywords') {
          const params = !values.makerId
            ? { pub_keywords: values.rawLabel }
            : {
              [FILTER_KEY_MAKER]: values.makerId,
              [FILTER_KEY_MODEL_FAMILY]: values.modelFamilyName,
            }

          onSubmit(params);
        } else {
          const params = {
            [FILTER_KEY_MAKER]: values.makerId,
            [FILTER_KEY_MODEL_FAMILY]: values.modelFamilyName,
            ...(values.modelId && { [FILTER_KEY_MODEL]: values.modelId }),
          };
          onSubmit(params);
        }
        // blur search input to hide software keyboard
        this.searchInput?.blur();
      }
    }
  }

  handleSelectChange = data => {
    this.onSubmit(data);
  };

  handleListingTypeSelect = listingType => {
    this.setState({ selectedListingType: listingType });
  };

  render() {
    const {
      searchIconClassName,
      searchFormIconClassName,
      onSubmit,
      appConfig,
      isTransparentBg,
      isTopbarScrolled,
      onGetMakeModelPromise,
      shouldClearQuickSearch,
      rootClassName,
      className,
      intl,
      isMobile,
      initialValues,
      desktopInputRoot,
      ...restOfProps
    } = this.props;

    const isAirplaneListingType = this.state.selectedListingType === LISTING_TYPE__AIRPLANES;
    const isKeywordsSearch = isMainSearchTypeKeywords(appConfig);
    const submit = isAirplaneListingType ? this.onSubmit : onSubmit;
    const classes = classNames(rootClassName, className);
    const desktopInputRootClass = desktopInputRoot || css.desktopInputRoot;
    const keywordSearchWrapperClasses = classNames(
      css.keywordSearchWrapper,
      isMobile ? css.mobileInputRoot : desktopInputRootClass
    );
    const searchPlaceholder = intl.formatMessage({ id: 'TopbarSearchForm.placeholder' });
    const menuStylesSearch = {
      width: '285px',
      left: '30px',
    }
    const searchField = isAirplaneListingType
      ? (
          <SearchAsyncSelect
            iconClassName={searchIconClassName}
            placeholder={searchPlaceholder}
            isMobile={isMobile}
            keywordSearchWrapperClasses={keywordSearchWrapperClasses}
            initialValue={initialValues && initialValues.pub_keywords}
            shouldClearQuickSearch={shouldClearQuickSearch}
            onGetMakeModelPromise={onGetMakeModelPromise}
            onChange={this.handleSelectChange}
            isTransparentBg={isTransparentBg}
            isTopbarScrolled={isTopbarScrolled}
            menuStyles={menuStylesSearch}
          />
        )
      : this.state.selectedListingType === LISTING_TYPE__REAL_ESTATE
        ? (
          <LocationSearchField
            search={this.props.history.location.search}
            desktopInputRootClass={desktopInputRootClass}
            rootIconClassName={css.locationIcon}
            intl={intl}
            isMobile={isMobile}
            inputRef={this.setSearchInputRef}
            onLocationChange={this.onChange}
          />
            // <div className={css.reSearchFieldWrapper}>
            //   <IconSearchDesktop className={classNames(css.reSearchFieldIcon, searchFormIconClassName)} />
            //   <FieldTextInput
            //     name='keywords'
            //     id='keywords'
            //     className={css.reSearchField}
            //     placeholder={searchPlaceholder}
            //   />
            // </div>
          )
        : null
    ;

    return (
      <div className={classes}>
        <FinalForm
          {...restOfProps}
          initialValues={initialValues}
          onSubmit={submit}
          render={formRenderProps => {
            const {
              intl,
              handleSubmit,
            } = formRenderProps;

            // Location search: allow form submit only when the place has changed
            const preventFormSubmit = e => e.preventDefault();
            const submitFormFn = isKeywordsSearch ? handleSubmit : preventFormSubmit;

            return (
              <Form
                className={css.form}
                onSubmit={submitFormFn}
                enforcePagePreloadFor="AircraftSearchPage"
              >
                {isKeywordsSearch ? searchField : (
                  <LocationSearchField
                    desktopInputRootClass={desktopInputRootClass}
                    intl={intl}
                    isMobile={isMobile}
                    inputRef={this.setSearchInputRef}
                    onLocationChange={this.onChange}
                  />
                )}
              </Form>
            );
          }}
        />
        <div className={css.vertRule} />
        <ListingTypeSelector
          intl={intl}
          selectedListingType={this.state.selectedListingType}
          onSelect={this.handleListingTypeSelect}
        />
      </div>
    );
  }
}

TopbarSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
  appConfig: config,
  isTransparentBg: false,
  isTopbarScrolled: false,
};

TopbarSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,
  appConfig: object,
  isTransparentBg: bool,
  isTopbarScrolled: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const TopbarSearchForm = injectIntl(TopbarSearchFormComponent);

export default TopbarSearchForm;
