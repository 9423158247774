import React, { Component } from 'react';
import { func, node, object, string } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { components } from 'react-select';

// import Select,  { Option } from 'react-select';
import {Form, ValidationError} from '../../components';

import css from './FieldSelect.module.scss';
import SearchIcon from "../Topbar/SearchIcon";

class FieldCreatableSelectComponent extends Component {
  render() {
    const {
      input,
      onChange,
      options,
      name,
      id,
      className,
      selectClassName,
      innerRef,
      meta,
      placeholder,
      label,
      styles,
      isMulti,
      isSearchable,
      isClearable,
      disabled,
      showSearchIcon,
      showMultiValues,
      onSelectValue,
    } = this.props;

    const { valid, invalid, touched, error } = meta;


    const customStyles = {
      control: (base, state) => ({
        height: isMulti ? 'auto' : '52px',
        display: 'flex',
        alignItems: 'center',
        padding: isMulti ? '8px 0' : '0',
      }),
      input: () => ({
        display: 'flex',
        alignItems: 'center',
        position: isMulti ? 'relative' : 'absolute',
      }),
      valueContainer: () => ({
        paddingLeft: '0px',
        position: 'relative',
        zIndex: 1,
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
      }),
      multiValue: () => ({
        alignItems: 'center',
        justifyContent: 'stretch',
        display: showMultiValues ? 'flex' : 'none',
        lineHeight: '20px',
        height: '36px',
        background: '#EFCA80',
        borderRadius: '4px',
        color: '#454545',
        fontSize: '16px',
        fontWeight: '700',
        padding: '0 12px',
        margin: '8px 8px 8px 0',
        maxWidth: '100%',
      }),
      indicatorsContainer: () => ({
        position: 'relative',
        zIndex: 2,
        left: '10px',
        top: '3px',
        cursor: 'pointer',
        height: '36px',
      }),
      option: () => ({
        padding: '10px',

        '&:hover': {
          borderColor: 'red',
        },
      }),
      indicatorSeparator: () => ({
        display: isMulti ? 'none' : 'block',
      }),
      placeholder: () => ({
        color: '#b2b2b2',
        position: 'absolute',
        transform: 'translateY(-50%)',
        top: '50%',
      }),
      menu: () => ({
        marginTop: '0',
      }),
      menuList: () => ({
        boxShadow: '-2px 7px 21px rgba(0, 0, 0, 0.1)',
        fontWeight: 200,
        maxHeight: '250px',
        overflow: 'auto',
        position: 'absolute',
        width: '100%',
        background: 'white',
        zIndex: 1000,
        left: '0',
        borderRadius: '6px'
      }),
      container: () => ({
        width: '100%',
        border: valid ? '1px solid #23A0EC' : '1px solid #b2b2b2',
        borderRadius: '6px',
        padding: showSearchIcon ? '0 17px 0 44px' : '0 20px 0 20px',
      }),
      ...styles,
    };

    const handleOnChange = (input, event) => {
      const { onBlur, onChange } = input;

      if(onSelectValue){
        onSelectValue(event)
      }

      onChange(event);
      onBlur(event);
    };

    const { onChange: inputOnChange, ...restOfInput } = input;
    const classes = classNames(css.root, className);
    if (typeof window === 'undefined') {
      return null;
    }
    const { default: CreatableSelect } = require('react-select/creatable'); // eslint-disable-line global-require

    return (
      <div className={classes}>
        {label ? <label htmlFor={id}>{label}</label> : null}
        <div className={css.selectHolder}>
          {showSearchIcon && <SearchIcon className={css.searchIcon}/>}
          <CreatableSelect
            {...input}
            id={id}
            name={name}
            isDisabled={disabled}
            className={selectClassName}
            options={options}
            value={input.value}
            placeholder={placeholder}
            styles={customStyles}
            onChange={event => handleOnChange(this.props.input, event)}
            onBlur={value => input.onBlur(value)}
            isMulti={isMulti}
            isSearchable={isSearchable}
            isClearable={isClearable}
            components={{
              Option: props => {
                const { children, innerProps, data } = props;

                return (
                  <div className={data.className} ref={innerRef} {...innerProps}>
                    {children}
                  </div>
                );
              },
              DropdownIndicator: props => {
                return (
                  <components.DropdownIndicator {...props}>
                    <div className={css.caret} />
                  </components.DropdownIndicator>
                );
              },
            }}
          />
        </div>

        <ValidationError fieldMeta={meta} />
      </div>
    );
  }
}

FieldCreatableSelectComponent.defaultProps = {
  rootClassName: null,
  className: null,
  name: null,
  selectClassName: null,
  id: null,
  label: null,
  children: null,
};

FieldCreatableSelectComponent.propTypes = {
  rootClassName: string,
  className: string,
  selectClassName: string,
  name: string,

  onChange: func,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Generated by final-form's Field component
  input: object.isRequired,
  meta: object.isRequired,

  children: node,
};

const FieldCreatableSelect2 = props => {
  return <Field component={FieldCreatableSelectComponent} options={props.options} {...props} />;
};

export default FieldCreatableSelect2;
