import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCheckFill.module.css';

const IconCheckFill = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <g clipPath="url(#clip0_403_797)">
        <path
          fill="#E32922"
          d="M12.585 1.087a3.613 3.613 0 00-5.168 0l-.777.798-1.113-.014a3.613 3.613 0 00-3.655 3.655l.013 1.113-.795.777a3.612 3.612 0 000 5.168l.796.777-.014 1.113a3.612 3.612 0 003.655 3.655l1.113-.013.777.795a3.613 3.613 0 005.168 0l.777-.796 1.113.014a3.613 3.613 0 003.655-3.655l-.013-1.113.795-.777a3.613 3.613 0 000-5.168l-.796-.777.014-1.113a3.612 3.612 0 00-3.655-3.655l-1.113.013-.777-.795v-.002zm.358 7.48l-3.75 3.75a.625.625 0 01-.885 0l-1.875-1.875a.626.626 0 11.885-.885l1.433 1.434 3.307-3.309a.626.626 0 01.885.885z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_403_797">
          <path fill="#fff" d="M0 0H20V20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

IconCheckFill.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconCheckFill.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconCheckFill;
