import {
  fieldsClose,
  fieldSet,
  fieldsOpen,
} from 'simple-redux-field';

import {
  FIELD__AIRPLANES_CATEGORIES,
  INITIAL__AIRPLANES_CATEGORIES,
  NAMESPACE,
  FIELD__AIRPLANES_METADATA,
} from './airplanes.constants';
import {
  fetchCategoriesApi,
  fetchAirplaneMetadataApi,
} from './airplanes.api';
import {
  airplaneMetadataRequestData,
} from './airplanes.helpers';
import { $airplanesMetadataField } from './airplanes.selectors';

export const openFields = () => dispatch => {
  dispatch(fieldsOpen([
    { key: FIELD__AIRPLANES_CATEGORIES, value: INITIAL__AIRPLANES_CATEGORIES },
  ], NAMESPACE));
};

export const closeFields = () => dispatch => {
  dispatch(fieldsClose([
    FIELD__AIRPLANES_CATEGORIES,
  ], NAMESPACE));
};

export const fetchCategories = () => dispatch => {
  dispatch(fieldSet(
    FIELD__AIRPLANES_CATEGORIES,
    { inProgress: true, error: null },
    NAMESPACE
  ));
  return fetchCategoriesApi()
    .then(data => {
      dispatch(fieldSet(
        FIELD__AIRPLANES_CATEGORIES,
        { inProgress: false, value: data.data },
        NAMESPACE
      ));
      return data.data;
    })
    .catch(e => {
      dispatch(fieldSet(
        FIELD__AIRPLANES_CATEGORIES,
        { inProgress: false, error: e.message },
        NAMESPACE
      ));
      throw e;
    })
  ;
};

export const fetchAirplanesMetadata = listings => (dispatch, getState) => {
  const reqData = airplaneMetadataRequestData(listings);
  if (!reqData || (reqData && !reqData.length)) {
    return Promise.resolve([]);
  }

  dispatch(fieldSet(
    FIELD__AIRPLANES_METADATA,
    { inProgress: true, error: null },
    NAMESPACE
  ));
  return fetchAirplaneMetadataApi(reqData)
    .then(response => {
      const stateMetadata = $airplanesMetadataField(getState());
      const metadata = {
        ...stateMetadata.value,
        ...response.data.data,
      };
      dispatch(fieldSet(
        FIELD__AIRPLANES_METADATA,
        { inProgress: false, value: metadata },
        NAMESPACE
      ));
      return response;
    })
    .catch(e => {
      dispatch(fieldSet(
        FIELD__AIRPLANES_METADATA,
        { inProgress: false, error: e.message },
        NAMESPACE
      ));
      throw e;
    })
  ;
};
