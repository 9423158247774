import React from 'react';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';

import IconSearchDesktop from './IconSearchDesktop';

const SingleValue = (props) => {
  const {
    data,
    isFocused,
  } = props;
  if (isFocused) {
    return <React.Fragment />;
  }

  return (
    <components.SingleValue
      {...props}
    >
      {data.rawLabel}
    </components.SingleValue>
  );
};

const Option = (props) => {
  const {
    data,
  } = props;
  const label = data.label;
  return (
    <components.Option
      {...props}
    >
      {data.group ? (
        <span
          dangerouslySetInnerHTML={{ __html: label }}
        />
      ) : (
        <span
          style={{
            paddingLeft: '20px',
          }}
          dangerouslySetInnerHTML={{ __html: label }}
        />
      )}
    </components.Option>
  );
};

const Menu = (props) => {
  if (!props.options.length) {
    return <React.Fragment />;
  }
  return (
    <components.Menu
      {...props}
    >
      {props.children}
    </components.Menu>
  );
};

const MobileSearchAsyncSelect = (props) => {
  const {
    isShowIcon,
    iconClassName,
    iconColorClassName,
    initialValue,
    onGetMakeModelPromise,
    onChange,
    isTransparentBg,
    isTopbarScrolled,
    placeholder,
  } = props;

  const promiseOptions = val => {
    if (val.length < 3) {
      return Promise.resolve([]);
    }
    return onGetMakeModelPromise(val);
  };

  const handleChange = data => {
    if (onChange) {
      onChange(data);
    }
  };

  return (
    <>
      {isShowIcon && (
        <IconSearchDesktop
          className={iconClassName}
          colorClassName={iconColorClassName}
          isTransparentBg={isTransparentBg}
          isTopbarScrolled={isTopbarScrolled}
        />
      )}
      <AsyncSelect
        onChange={handleChange}
        placeholder={placeholder}
        loadOptions={promiseOptions}
        blurInputOnSelect
        styles={{
          container: () => ({
            display: 'flex',
            width: '100%',
            flexGrow: 1,
          }),
          control: () => ({
            display: 'flex',
            border: 'none',
            width: '100%',
          }),
          placeholder: () => ({
            gridArea: '1/1/2/3',
            color: '#b6b6b6',
            marginLeft: '3px',
            marginRight: '2px',
            boxSizing: 'border-box',
            fontWeight: '400',
          }),
        }}
        components={{
          SingleValue: props => (
            <SingleValue
              {...props}
            />
          ),
          Option,
          DropdownIndicator: null,
          Menu,
        }}
      />
    </>
  );
};

MobileSearchAsyncSelect.defaultProps = {
  isShowIcon: true,
  applyFocusStyles: false,
};

export default MobileSearchAsyncSelect;
