import TagManager from 'react-gtm-module';

import { restApiService } from '../services/services';
import { axiosStorableError } from '../util/errors';

// ================ Action types ================ //

export const ESTIMATE_REQUEST = 'app/SectionCalculator/ESTIMATE_REQUEST';
export const ESTIMATE_REQUEST_SUCCESS = 'app/SectionCalculator/ESTIMATE_REQUEST_SUCCESS';
export const ESTIMATE_REQUEST_ERROR = 'app/SectionCalculator/ESTIMATE_REQUEST_ERROR';

// ================ Reducer ================ //

const initialState = {
  estimatePushIsLoading: false,
  estimatePushError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case ESTIMATE_REQUEST:
      return {
        ...state,
        estimatePushIsLoading: true,
        estimatePushError: null,
      };

    case ESTIMATE_REQUEST_SUCCESS:
      return {
        ...state,
        estimatePushIsLoading: false,
      };

    case ESTIMATE_REQUEST_ERROR:
      return {
        ...state,
        estimatePushIsLoading: false,
        estimatePushError: payload,
      };

    default:
      return state;
  }
};

// ================ Selectors ================ //

export const $estimatePushIsLoading = state => {
  return state.EstimateCalculator.estimatePushIsLoading;
};

// ================ Action creators ================ //

export const estimateRequest = () => ({
  type: ESTIMATE_REQUEST,
});

export const estimateRequestSuccess = () => ({
  type: ESTIMATE_REQUEST_SUCCESS,
});

export const estimateRequestError = error => ({
  type: ESTIMATE_REQUEST_ERROR,
  payload: error,
});

// ================ Thunks ================ //

export const listingEstimateFormSubmit = (values, metadata) => dispatch => {
  dispatch(estimateRequest());
  const leads = [
    {
      Lead_Source: 'aircraftforsale.com',
      Last_Name: values.lastName,
      First_Name: values.firstName,
      Email: values.email,
      Equip_Price: Number(values.purchasePrice),
      Down_Payment: Number(values.downPaymentAmount),
      Loan_Term_Years: Number(values.term),
      Phone: values.phone.value,
      ...metadata.makerName && { Equip_Make: metadata.makerName },
      ...metadata.modelName && { Equip_Model: metadata.modelName },
    },
  ];

  return restApiService.publicInstance
    .postRaw('/zoho/push-zoho-leads', { leads })
    .then(response => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'financecalc_submit',
          last_name: values.lastName,
          first_name: values.firstName,
          email: values.email,
          ...metadata.categoryName && { category: metadata.categoryName },
          ...metadata.makerName && { make: metadata.makerName },
          ...metadata.modelName && { model: metadata.modelName },
          price: values.purchasePrice,
          phone: values.phone.value,
          down_payment: Number(values.downPaymentAmount),
          loan_term_years: Number(values.term),
        },
      });
      dispatch(estimateRequestSuccess());
      return response;
    })
    .catch(error => {
      dispatch(estimateRequestError(axiosStorableError(error)));
      throw error;
    })
    ;
};

export const estimateFormSubmit = (values, metadata) => dispatch => {
  dispatch(estimateRequest());
  const leads = [
    {
      Lead_Source: 'aircraftforsale.com',
      Last_Name: values.lastName,
      First_Name: values.firstName,
      Email: values.email,
      State: values.state,
      Equip_Price: Number(values.purchasePrice),
      Down_Payment: Number(values.downPaymentAmount),
      Loan_Term_Years: Number(values.term),
      Phone: values.phone.value,
      Aircraft_Use: values.use.map(i => i.value).join(', '),
      ...metadata.makerName && { Equip_Make: metadata.makerName },
      ...metadata.modelName && { Equip_Model: metadata.modelName },
    },
  ];

  return restApiService.publicInstance
    .postRaw('/zoho/push-zoho-leads', { leads })
    .then(response => {
      dispatch(estimateRequestSuccess());
      return response;
    })
    .catch(error => {
      dispatch(estimateRequestError(axiosStorableError(error)));
      throw error;
    })
  ;
}
