import React, {
  useState,
  useEffect,
} from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { propTypes } from '../../../util/types';

import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  IconBell
} from '../../../components';
import SignUpDot from '../../../containers/SignUpDot/SignUpDot';
import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';
import EmptyAvatar from '../../Avatar/EmptyAvatar';

import css from './TopbarDesktop.module.scss';

const TopbarDesktop = props => {
  const {
    searchFormIconClassName,
    isVisibleSearchForm,
    shouldClearQuickSearch,
    onGetMakeModelPromise,
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    transparentBg,
    commentNotificationsCountField,
    history,
  } = props;

  const [mounted, setMounted] = useState(false);
  const [isTopbarScrolled, setIsTopbarScrolled] = useState(false);
  const [isTopbarChangedAfterLoad, setIsTopbarChangedAfterLoad] = useState(false);

  const someScrollFromTop = () => {
    // 83 - current topbar height
    if (!!window && window?.pageYOffset > 83) {
      setIsTopbarScrolled(true)
      setIsTopbarChangedAfterLoad(true)
    } else {
      setIsTopbarScrolled(false)
    }
  }

  useEffect(() => {
    setMounted(true);
    document.addEventListener('scroll', () => someScrollFromTop());

    return () => { document.removeEventListener('scroll', () => someScrollFromTop()) };

  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;
  const profileImage = get(currentUser, 'profileImage', null);

  const classes = classNames(
    rootClassName || css.root,
    className,
    { [css.rootScrolled]: isTopbarScrolled },
    { [css.rootChangedAfterLoad]: isTopbarChangedAfterLoad },
    { [css.rootTransparent]: transparentBg }
  );

  const search = isVisibleSearchForm ? (
    <TopbarSearchForm
      searchFormIconClassName={searchFormIconClassName}
      history={history}
      shouldClearQuickSearch={shouldClearQuickSearch}
      onGetMakeModelPromise={onGetMakeModelPromise}
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
      isTransparentBg={transparentBg}
      isTopbarScrolled={isTopbarScrolled}
    />
  ) : <div className={css.searchLink} /> ;

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.topbarLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const notificationsLink = authenticatedOnClientSide && currentUserHasListings ? (
    <NamedLink
      className={css.topbarLink}
      name='NotificationsPage'
    >
      <span className={classNames(css.inbox, css.inboxBell)}>
        <IconBell />
        {commentNotificationsCountField?.count ? <span className={css.notificationCounter}>{commentNotificationsCountField.count}</span> : null}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };
  const isAdmin = get(currentUser, 'attributes.profile.metadata.isAdmin', false);
  const companyLogo = currentUser?.attributes.profile.publicData.companyLogo;

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel
        className={css.profileMenuLabel}
        isOpenClassName={css.profileMenuIsOpen}
      >
        {profileImage || companyLogo ? (
          <Avatar
            className={css.avatar}
            user={currentUser}
            disableProfileLink
            companyLogo={companyLogo?.newHref}
          />
        ) : (
          <EmptyAvatar
            className={css.emptyAvatar}
            color={(isTopbarScrolled || !transparentBg) ? 'red' : 'white'}
          />
        )}
      </MenuLabel>
      <MenuContent className={css.profileMenuContent} useDefaultStyles={false}>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="FavoritesPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('FavoritesPage'))}
            name="FavoritesPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.wishlistLink" />
          </NamedLink>
        </MenuItem>
        {isAdmin && (
          <MenuItem key="RegistrationPhotosPage">
            <NamedLink
              className={classNames(css.profileSettingsLink, currentPageClass('RegistrationPhotosPage'))}
              name="RegistrationPhotosPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.registrationsPhotos" />
            </NamedLink>
          </MenuItem>
        )}
        <MenuItem key="SavedSearchPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('SavedSearchPage'))}
            name="SavedSearchPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.savedSearchLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink
      className={css.topbarLink}
      name="SignupPage"
      to={{ state: { from: `${location.pathname}${location.search}${location.hash}` } }}
    >
      <FormattedMessage id="TopbarDesktop.signup" />
      <SignUpDot className={css.notificationDot} />
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink
      className={css.topbarLink}
      name="LoginPage"
      to={{ state: { from: `${location.pathname}${location.search}${location.hash}` } }}
    >
      <FormattedMessage id="TopbarDesktop.login" />
    </NamedLink>
  );

  return (
    <nav className={classes}>
      <NamedLink className={css.logoLink} name="LandingPage">
        <Logo
          format="desktop"
          className={css.logo}
          isTopbarScrolled={isTopbarScrolled}
          isTransparentBg={transparentBg}
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
        />
      </NamedLink>
      {search}

      <NamedLink
        className={css.topbarLink}
        name="AircraftSearchPage"
      >
        <span className={css.createListing}>
          <FormattedMessage id="TopbarDesktop.buy" />
        </span>
      </NamedLink>
      <NamedLink className={css.topbarLink} name="SetNewListingTypePage">
        <span className={css.createListing}>
          <FormattedMessage id="TopbarDesktop.sell" />
        </span>
      </NamedLink>
      <a
        href='https://flyingfinance.com/?utm_source=aircraftforsale&utm_medium=button&utm_campaign=top_nav&utm_term=finance'
        target='_blank'
        className={css.topbarLink}
      >
        <span className={css.createListing}>
          <FormattedMessage id="TopbarDesktop.finance" />
        </span>
      </a>

      <div className={css.separator} />

      {notificationsLink}
      {inboxLink}
      {profileMenu}
      {signupLink}
      {loginLink}
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
  isVisibleSearchForm: true,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
  isVisibleSearchForm: bool,
};

export default TopbarDesktop;
