import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSmallLogo.module.css';

const IconSmallLogo = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      fill="none"
      viewBox="0 0 64 64"
    >
      <path fill="#24292F" d="M0 0H64V64H0z"></path>
      <path
        fill="#fff"
        d="M25.875 10L7.25 54.125h13.188l7.451-17.113h17.786l3.86-9.731H32.084l3.188-7.214h17.45L56.75 10H25.875z"
      ></path>
    </svg>
  );
};

IconSmallLogo.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconSmallLogo.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconSmallLogo;
