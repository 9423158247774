import React from 'react';
import PropTypes from 'prop-types';

import LogoWhite from './LogoWhite';
import LogoBlack from './LogoBlack';

const Logo = props => {
  const {
    className,
    isTopbarScrolled,
    isTransparentBg,
    isFooter,
  } = props;

  if (isFooter) {
    return <LogoBlack className={className} />;
  }

  if (!isTransparentBg) {
    return <LogoBlack className={className} />;
  }

  return isTopbarScrolled
    ? <LogoBlack className={className} />
    : <LogoWhite className={className} />
  ;
};

const { oneOf, string, bool } = PropTypes;

Logo.defaultProps = {
  className: null,
  format: 'desktop',
  isFooter: false,
  isTopbarScrolled: false,
  isTransparentBg: false,
};

Logo.propTypes = {
  className: string,
  format: oneOf(['desktop', 'mobile']),
  isFooter: bool.isRequired,
  isTopbarScrolled: bool.isRequired,
  isTransparentBg: bool.isRequired,
};

export default Logo;
