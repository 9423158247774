import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      {/* <p className={css.lastUpdated}>Last updated: July 1st, 2021</p> */}

      <h2>FLYING respects the privacy and security of its website users.</h2>
      <p>
        This Privacy Policy (“policy”) applies to the features and uses of a FLYING website (“website”). If you decide to sign up for
        special services that may be posted or linked to from time to time on the website (e.g., e-mail registrations, discussion list sign-up,
        our blog, etc.), additional terms and conditions regarding the collection and use of your information may apply. In all instances, however,
        we will provide notice to you about what personally identifiable information is collected; how, when, and for how long we might use
        your information; how we protect your information; who has access to your information; and how you can correct any inaccuracies in the information.
      </p>
      <p>
        This policy does not apply to any websites or services managed, maintained, or hosted by third parties not directly affiliated
        with FLYING that you may visit, e.g., via a link provided through the website or on a website maintained by any third party.
        FLYING does not control the privacy policies of other websites to which the website may provide links. We encourage you to
        learn more about the online privacy and data security policies of third party websites directly from such third parties, as
        their policies may differ from ours.
      </p>
      <p>
        By submitting information to us and by using our website, we will assume that you have read, understood, and consent to this
        policy and all other terms, conditions, and policies that may be applicable to our website from time to time. We also assume
        that you consent to our use of your information as described in this policy and all other such terms, conditions, and policies.
        If you do not consent to or disagree with, this policy or any such terms, conditions, and policies, please do not use our website.
      </p>


      <h2>
        Information We May Collect
      </h2>
      <p>
        FLYING collects certain information from and about its website users in three ways – directly from the user, from our web server logs,
        and with cookies and other web technologies, as described below.
      </p>
      <p>
        User-supplied Information. When you sign up for a FLYING service or register for a conference, we ask you for your name, email address,
        and other personal information. Additionally, if there are any charges for services we provide, if you make payments through the site,
        we may request credit card or other financial information from you to implement the transaction.
      </p>
      <p>
        Server Log Information. When you visit our website, as with any website, you disclose certain information about yourself,
        such as your internet (TCP/IP) address, the time of your visit, and the referring location (e.g., the website that provided
        the hypertext link to the FLYING page). This basic information is automatically recorded in the server log and is accessible
        to the FLYING to determine, for example, the number of unique visits to a webpage at any given time on any given day.
      </p>
      <p>
        Cookies and Other Web Technologies. The use of cookies allows us to provide a customized and more personal website experience.
        Cookies are small bits of code that can associate certain preferences and browsing history on a particular website to your laptop,
        PC, or other web-enabled devices. When you use that same device to return to the website, the cookie remembers your preferences as
        you load the website. The FLYING website uses cookies to store and help track your information.
      </p>
      <p>
        Most browsers are initially set up to accept cookies. Cookies do not collect any personally identifiable information (PII).
        If you prefer, however, you can reset your browser either to notify you when you receive a cookie or to refuse to accept cookies.
        You should understand that certain areas of many sites might not function properly if you set your browser not to accept cookies.
        FLYING may contract with trusted third parties to collect and analyze data about the use of the website, and such third-party
        contractors may use cookies and web scripts that send data directly to them for this purpose.
      </p>
      <p>
        The website uses clear gifs or pixel tags, which are tiny graphic images, in order: (i) to advise us of what parts of the
        website users have visited; (ii) to measure the effectiveness of any searches users perform; (iii) to track how visitors use
        the site to target ads to them on other websites; and (iv) to enable us to send emails in a format that users can read and
        tell us whether such emails have been opened in order to ensure us that we are sending messages that are of interest to users.
      </p>
      <p>
        We, our ad partners, and third parties may collect information about a user which enables us to recognize you when you
        access our website using different web browsers or devices. Our third party partners, such as web analytics companies and
        third party ad networks, may collect information about you and your online activities over time and across our website and
        other online properties via advertising identifiers and other technologies to support or deliver personalized advertising.
        This policy does not cover the practices of our ad partners and we do not control our ad partners’ technologies.
      </p>
      <p>
        For more information about our ad partners’ technologies, how they use the information collected by the technologies,
        and what options may be available to you, please visit our ad partners’ websites and review their privacy policies.
        Additional options, from participating companies and organizations, may be made available to you by visiting the
        DAA’s <a href="https://optout.aboutads.info/?lang=EN&c=2#!%2F" target='_blank'>opt-out page</a>, the
        Digital Advertising Alliance of Canada’s (DAAC’s) <a href="https://youradchoices.ca/en/tools" target='_blank'>opt-out page</a>, or one of the EDAA’s
        country-specific <a href="https://youronlinechoices.eu/" target='_blank'>opt-out pages</a>.
      </p>
      <p>
        How We Respond to Do Not Track Signals and Similar Mechanisms. Some browsers may be configured to send Do Not
        Track signals to websites, or users may use similar mechanisms, to indicate a user’s preference that certain web
        technologies not be used to track the user’s online activity. Our website does not accept or process such as Do
        Not Track signals or similar mechanisms.
      </p>


      <h2>
        How and When Information We Collect Is Used
      </h2>
      <p>
        We may use the information we collect in different ways, depending on the type of information:
      </p>
      <p>
        User-Supplied Information. This information is used for purposes such as personalizing your visit to our site and
        verifying payment and other information we receive from you. Examples of user-supplied information are your name,
        address, phone number, email address, and other demographic information. This information can be used in creating an
        individualized internet environment, selective email user groups, and targeted educational opportunities.
        We may, on occasion, send email to you about FLYING products or services, and third-party products or services.
        If you do not want to receive email from us, visit the “Opt-Out” or “Unsubscribe” link at the bottom of any such
        email to remove yourself from continued receipt of such email messages.
      </p>
      <p>
        Additionally, we may collect payment information for special events, dues, subscriptions, or other goods and services.
        Payment information is collected and processed on a secure, third-party verified server, dedicated solely for use by FLYING.
      </p>
      <p>
        Server Log Information. We use server log information to monitor and analyze use of the website and for technical
        administration of the website, to increase the functionality and user-friendliness of the website,
        and to better tailor it to the needs of users.
      </p>
      <p>
        Aggregated Information. We may aggregate information we collect about you with information collected from other users to
        attempt to provide you with a better experience, to improve the quality and value of the website, and to analyze and
        understand how the website is used. We may also contract with trusted third parties for these purposes.
      </p>


      <h2>
        How We Protect Your Information
      </h2>
      <p>
        The privacy and protection of your personal information are vitally important to us. Any statistics regarding your
        FLYING website usage that may be reviewed by our management or staff or shared with prospective or existing partners
        are provided in the aggregate only and do not include any personally identifiable information. When products or services
        are purchased on the site through the use of a credit card, individual payment information is processed via a secure,
        third-party server, as noted above, and is not otherwise disclosed outside of FLYING. Neither FLYING nor any third
        party acting on our behalf may use your personal information for any purpose outside the scope of your consent.
      </p>
      <p>
        No data transmission over the internet can be guaranteed to be 100 percent secure. Accordingly, despite the
        safeguards in place to protect your personal information, the FLYING cannot ensure or warrant the security of any
        information you transmit to us with respect to our online products or services. You transmit all such information at
        your own risk. However, once we receive your personal information, we will undertake all commercially reasonable
        efforts to ensure its security on our systems.
      </p>
      <p>
        Please note that if you use any bulletin board, chat room, blog, comment posting feature, or other public communication
        service, forum, or feature offered through the website, or post any information available for viewing by other
        users, any of the information that you share will be visible to other users. The information that you make
        available can be read, used, and collected by other users to send you unsolicited messages outside of the website.
        FLYING is not responsible for the manner in which any information that you decide to share will be used by other users.
      </p>


      <h2>
        Who Has Access to Your Information
      </h2>
      <p>
        Our policy is not to share user information, including personal information, we collect with third parties other than as
        specified below, or where a user expressly consents to our sharing of certain information with a third party.
        We may share user information with third parties under the following circumstances:
      </p>
      <ul>
        <li>
          We may disclose user information to certain third-party vendors in connection with the delivery of products or services related to the website,
          including, for example: in order to facilitate the website; to provide the website or portions of the website
          on our behalf; to perform related services, including without limitation, maintenance services, database management,
          fulfillment, web analytics, and improvement of the features or functionality; or to assist us in analyzing how the website
          is being used. In such cases, these third parties are generally under the same privacy obligations as are set forth in this policy.
        </li>
        <li>
          Occasionally, we make our mailing list available for purchase to select partners — those parties together with whom
          we offer certain of our products and services — for the distribution of informational material. These partners have
          been screened by FLYING for relevancy and are granted a one-time use of the addresses. You have the ability to opt
          out of this use of our mailing list at any time by sending an email to <a href="mailto:sales@flying.media">sales@flying.media</a>
        </li>
        <li>
          Where one of our products or services, is offered in conjunction with another named organization or company, it may be necessary
          for us to share user information with that entity in order to provide such “co-branded” services to you. If you choose to share
          your data with a provider of a co-branded service, you should understand that those providers may have different privacy policy
          practices. FLYING has no control over and accepts no liability for these providers’ practices.
        </li>
        <li>
          Finally, in some instances, FLYING may be under a legal obligation to disclose user information. For example, we may disclose
          user information to comply with the law, in response to a subpoena served to FLYING; to enforce or apply the policy or any other
          terms, conditions, or policies applicable to our website; to protect the rights, property, or safety of FLYING, our website
          users, or others; or to prevent activity that we believe, in our sole discretion, may be or may become illegal, unethical,
          or legally actionable.
        </li>
      </ul>


      <h2>
        How Long Your Information May Be Retained
      </h2>
      <p>
        We do not store your credit card information beyond the time required to process your payment for a product or service,
        but we may keep other user information on file indefinitely.
      </p>


      <h2>
        How to Correct Inaccuracies
      </h2>
      <p>
        If you purchase a FLYING service or product online, we may send you a confirmation email confirming your new account,
        product and/or service. The message will be sent to the address that you supplied us and will describe ways for you to correct,
        or otherwise change or delete, the account information you provided.
      </p>

      <h2>
        International Transfer
      </h2>
      <p>
        Please note that FLYING is based in the United States and the website is hosted on servers located in the United States.
        If you use the website from outside the United States, please note that your information may be transferred to and maintained
        on computers and servers located outside of your state, province, country, or other governmental jurisdiction, where privacy
        laws may not be as protective as those in your jurisdiction. Your consent to this policy, followed by your submission of
        information through the website, represents your agreement to the transfer of such information to the United States and the
        handling of your information consistent with applicable United States law.
      </p>


      <h2>
        Children’s Privacy
      </h2>
      <p>
        We are committed to protecting the privacy of children. Users must be at least thirteen (13) years of age to access and
        use the website. By using the website, you represent and warrant that you are thirteen (13) years of age or older and that
        you consent to this policy, and all other applicable terms, conditions, and policies. If we believe that you are under
        the age of thirteen (13) or that you are not old enough to consent to and be legally bound by the policy, we may, at any
        time, in our sole discretion, and with or without notice: (i) terminate your access to or use of the website (or any
        portion, aspect, or feature), or (ii) delete any content or information that you have posted through the website.
      </p>


      <h2>
        Future Changes to the FLYING Website and to this Policy
      </h2>
      <p>
        From time to time, we may use information collected through our website for new, unanticipated uses not previously
        disclosed in this policy. If our information practices change at some time in the future, we will post changes to
        this policy on the website. If you are concerned about how your information is used, please bookmark this page and
        check back periodically. The information we gather is and will be subject to the version of this policy in effect at the
        time the information is gathered.
      </p>
      <p>
        If you believe that our website is not following this policy, or if you have any further questions, please contact us:
      </p>
      <p>
        FLYING
        <br />
        <a href="https://goo.gl/maps/BzKkYzVsrrAeVquC7" target='_blank'>
          910 Georgia Ave #11588
          <br />
          Chattanooga, TN 37402
        </a>
        <br />
        Email: <a href="mailto:support@flying.media">support@flying.media</a>
      </p>

    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
