import axios from 'axios';
import get from 'lodash/get';

class AxiosBaseService {
  static handleSuccessResponse(response) {
    return get(response, 'data', null);
  }

  static handleErrorResponse(error) {
    throw get(error, 'response.data.errors', null);
  }

  #instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_ENDPOINT}/api`,
  });

  get instance() {
    return this.#instance;
  }

  get(url, config) {
    return this.instance
      .get(url, config)
      .then(AxiosBaseService.handleSuccessResponse)
      .catch(AxiosBaseService.handleErrorResponse);
  }

  post(url, data, config) {
    return this.instance
      .post(url, data, config)
      .then(AxiosBaseService.handleSuccessResponse)
      .catch(AxiosBaseService.handleErrorResponse);
  }

  patch(url, data, config) {
    return this.instance
      .patch(url, data, config)
      .then(AxiosBaseService.handleSuccessResponse)
      .catch(AxiosBaseService.handleErrorResponse)
    ;
  }

  delete(url, config) {
    return this.instance
      .delete(url, config)
      .then(AxiosBaseService.handleSuccessResponse)
      .catch(AxiosBaseService.handleErrorResponse)
    ;
  }

  getRaw(url, config) {
    return this.instance.get(url, config);
  }

  postRaw(url, data, config) {
    return this.instance.post(url, data, config);
  }

  patchRaw(url, data, config) {
    return this.instance.patch(url, data, config);
  }

  deleteRaw(url, config) {
    return this.instance.delete(url, config);
  }
}

export class PublicAxiosService extends AxiosBaseService {
}

export class SecureAxiosService extends AxiosBaseService {
  get(url, config) {
    return super.get(url, {
      withCredentials: true,
      ...config,
    });
  }

  post(url, data, config) {
    return super.post(url, data, {
      withCredentials: true,
      ...config,
    });
  }

  patch(url, data, config) {
    return super.patch(url, data, {
      withCredentials: true,
      ...config,
    });
  }

  delete(url, config) {
    return super.delete(url, {
      withCredentials: true,
      ...config,
    });
  }

  getRaw(url, config) {
    return super.getRaw(url, {
      withCredentials: true,
      ...config,
    });
  }

  postRaw(url, data, config) {
    return super.postRaw(url, data, {
      withCredentials: true,
      ...config,
    });
  }

  patchRaw(url, data, config) {
    return super.patchRaw(url, data, {
      withCredentials: true,
      ...config,
    });
  }

  deleteRaw(url, config) {
    return super.deleteRaw(url, {
      withCredentials: true,
      ...config,
    });
  }
}

export default class RestApiService {
  #publicInstance;
  #secureInstance;

  constructor (opts) {
    this.#publicInstance = opts.publicAxiosService;
    this.#secureInstance = opts.secureAxiosService;
  }

  get publicInstance() {
    return this.#publicInstance;
  }

  get secureInstance() {
    return this.#secureInstance;
  }
}
