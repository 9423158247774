import React from 'react';
import { Field } from 'react-final-form';
import classnames from 'classnames';

import { LocationAutocompleteInput } from '../index';

import css from './LocationSearchField.module.scss';
import usePrevious from '../../hooks/usePrevious';

const identity = v => v;

const LocationSearchField = props => {
  const {
    search,
    rootClassName,
    rootIconClassName,
    rootInputClassName,
    desktopInputRootClass,
    intl,
    isMobile,
    inputRef,
    onLocationChange,
    placeholder,
  } = props;

  return (
    <Field
      name="location"
      format={identity}
      render={({ input, meta }) => {
        const { onChange, ...restInput } = input;

        // Merge the standard onChange function with custom behaviur. A better solution would
        // be to use the FormSpy component from Final Form and pass this.onChange to the
        // onChange prop but that breaks due to insufficient subscription handling.
        // See: https://github.com/final-form/react-final-form/issues/159
        const searchOnChange = value => {
          onChange(value);
          onLocationChange(value);
        };

        const inputClassName = classnames(rootInputClassName, isMobile ? css.mobileInput : css.desktopInput);
        const iconClassName = classnames(rootIconClassName, isMobile ? css.mobileIcon : css.desktopIcon);

        return (
          <LocationAutocompleteInput
            historySearch={search}
            rootClassName={rootClassName}
            className={isMobile ? css.mobileInputRoot : desktopInputRootClass}
            iconClassName={iconClassName}
            inputClassName={inputClassName}
            predictionsClassName={isMobile ? css.mobilePredictions : css.desktopPredictions}
            predictionsAttributionClassName={isMobile ? css.mobilePredictionsAttribution : null}
            placeholder={placeholder || intl.formatMessage({ id: 'TopbarSearchForm.placeholder' })}
            closeOnBlur={!isMobile}
            inputRef={inputRef}
            input={{ ...restInput, onChange: searchOnChange }}
            meta={meta}
          />
        );
      }}
    />
  );
};

export default LocationSearchField;
