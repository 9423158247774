import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaPinterest.module.css';

const IconSocialMediaPinterest = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#E32922"
        d="M7.287 18.745c.88.266 1.769.422 2.713.422A9.166 9.166 0 10.833 10c0 3.896 2.448 7.242 5.904 8.562-.083-.715-.165-1.898 0-2.714L7.79 11.32s-.266-.532-.266-1.375c0-1.265.788-2.21 1.687-2.21.788 0 1.155.578 1.155 1.32 0 .79-.523 1.917-.789 2.998-.155.899.477 1.687 1.394 1.687 1.631 0 2.896-1.742 2.896-4.198 0-2.2-1.576-3.704-3.84-3.704-2.585 0-4.107 1.925-4.107 3.951 0 .789.257 1.586.678 2.109.083.055.083.128.055.265l-.266 1c0 .155-.1.21-.256.1-1.174-.513-1.852-2.181-1.852-3.529 0-2.897 2.053-5.527 6.013-5.527 3.154 0 5.61 2.264 5.61 5.27 0 3.154-1.952 5.684-4.748 5.684-.89 0-1.76-.477-2.072-1.036l-.614 2.172c-.21.789-.788 1.843-1.182 2.476v-.028z"
      ></path>
    </svg>
  );
};

IconSocialMediaPinterest.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaPinterest.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaPinterest;
