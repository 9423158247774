import { BaseReducer } from 'redux-di-container';

export class ShareModalService extends BaseReducer {
  getInitialState() {
    return {
      isOpen: false,
      data: null,
    };
  }

  $isOpen(state) {
    return this.select(state).isOpen;
  }

  $modalData(state) {
    return this.select(state).data;
  }

  openModal(data) {
    this.dispatchAction(state => {
      state.data = data;
      state.isOpen = true;
    });
  }

  closeModal() {
    this.resetState();
  }
}
