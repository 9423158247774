import React from 'react';
import { Field } from 'react-final-form';
import ReCAPTCHA from "react-google-recaptcha";

import config from '../../config';
import { required } from '../../util/validators';

const FieldRecaptcha = (props) => {
  const {
    intl,
    id,
    name,
  } = props;
  return (
    <Field
      id={id}
      name={name}
      validate={required(intl.formatMessage({ id: 'Recaptcha.required' }))}
    >
      {({ input }) => (
        <ReCAPTCHA
          sitekey={config.gRecaptchaKey}
          onChange={input.onChange}
        />
      )}
    </Field>
  );
};

FieldRecaptcha.reset = () => {
  window.grecaptcha.reset();
};

FieldRecaptcha.getResponse = () => {
  return window.grecaptcha.getResponse();
};

export default FieldRecaptcha;
