import React, { Component } from 'react';
import { bool, func, object, string } from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';

import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routing/routeConfiguration';
import config from '../../../config';
import { intlShape, injectIntl } from '../../../util/reactIntl';
import { isMainSearchTypeKeywords } from '../../../util/search';
import {
  FILTER_KEY_MAKER,
  FILTER_KEY_MODEL,
  FILTER_KEY_MODEL_FAMILY,
} from '../../../containers/SearchPage/SearchPage.constants';
import {
  LISTING_TYPE__AIRPLANES,
  LISTING_TYPE__REAL_ESTATE,
  LISTING_TYPE_URL__AIRPLANES,
  LISTING_TYPE_URL__REAL_ESTATE,
} from '../../../util/editListingHelpers';

import {
  Form,
  FieldTextInput,
} from '../../../components';
import ListingTypeSelector from './ListingTypeSelector';
import MobileSearchAsyncSelect from './MobileSearchAsyncSelect';

import css from './MobileTopbarSearchForm.module.scss';
import LocationSearchField from '../../LocationSearchField/LocationSearchField';

class MobileTopbarSearchForm extends Component {
  constructor(props) {
    super(props);
    // onChange is used for location search
    this.onChange = this.onChange.bind(this);
    // onSubmit is used for keywords search
    this.onSubmit = this.onSubmit.bind(this);

    // Callback ref
    this.searchInput = null;
    this.setSearchInputRef = element => {
      this.setSearchInput = element;
    };

    this.state = {
      selectedListingType: LISTING_TYPE__AIRPLANES,
    };
  }

  componentDidMount () {
    if (window.location.pathname.includes(LISTING_TYPE_URL__AIRPLANES)) {
      this.setState({ selectedListingType: LISTING_TYPE__AIRPLANES });
    }
    if (window.location.pathname.includes(LISTING_TYPE_URL__REAL_ESTATE)) {
      this.setState({ selectedListingType: LISTING_TYPE__REAL_ESTATE });
    }
  }

  onChange(location) {
    const { appConfig, onSubmit } = this.props;
    if (!isMainSearchTypeKeywords(appConfig) && location.selectedPlace) {
      // Note that we use `onSubmit` instead of the conventional
      // `handleSubmit` prop for submitting. We want to autosubmit
      // when a place is selected, and don't require any extra
      // validations for the form.
      onSubmit({ location });
      // blur search input to hide software keyboard
      this.searchInput?.blur();
    }
  }

  onSubmit(values) {
    if (this.state.selectedListingType === LISTING_TYPE__REAL_ESTATE) {
      const params = {
        keywords: values.keywords,
      };
      this.props.history.push(createResourceLocatorString(
        'RealEstateSearchPage',
        routeConfiguration(),
        {},
        params
      ));
      return ;
    }

    if (this.state.selectedListingType === LISTING_TYPE__AIRPLANES) {
      const { appConfig, onSubmit } = this.props;
      if (isMainSearchTypeKeywords(appConfig) && values) {
        if (values.value === 'pub_keywords') {
          const params = !values.makerId
            ? { pub_keywords: values.rawLabel }
            : {
              [FILTER_KEY_MAKER]: values.makerId,
              [FILTER_KEY_MODEL_FAMILY]: values.modelFamilyName,
            }

          onSubmit(params);
        } else {
          const params = {
            [FILTER_KEY_MAKER]: values.makerId,
            [FILTER_KEY_MODEL_FAMILY]: values.modelFamilyName,
            ...(values.modelId && { [FILTER_KEY_MODEL]: values.modelId }),
          };
          onSubmit(params);
        }
        // blur search input to hide software keyboard
        this.searchInput?.blur();
      }
    }
  }

  handleSelectChange = data => {
    this.onSubmit(data);
  };

  handleListingTypeSelect = listingType => {
    this.setState({ selectedListingType: listingType });
  };

  handleLocationChange = location => {
    if (location.selectedPlace) {
      const { search, selectedPlace } = location;
      const { origin, bounds } = selectedPlace;
      this.props.history.push(createResourceLocatorString(
        'RealEstateSearchPage',
        routeConfiguration(),
        {},
        {
          bounds,
          // origin,
          address: search,
        }
      ));
    }
  };

  render() {
    const {
      onSubmit,
      appConfig,
      isTransparentBg,
      isTopbarScrolled,
      onGetMakeModelPromise,
      shouldClearQuickSearch,
      intl,
      initialValues,
      desktopInputRoot,
      isMobile,
      history,
      ...restOfProps
    } = this.props;

    if (!isMobile) {
      return null;
    }

    const isKeywordsSearch = isMainSearchTypeKeywords(appConfig);
    const submit = isKeywordsSearch ? this.onSubmit : onSubmit;
    const searchPlaceholder = intl.formatMessage({ id: 'MobileTopbarSearchForm.placeholder' });
    const searchField = this.state.selectedListingType === LISTING_TYPE__AIRPLANES
      ? (
          <MobileSearchAsyncSelect
            placeholder={searchPlaceholder}
            initialValue={initialValues && initialValues.pub_keywords}
            shouldClearQuickSearch={shouldClearQuickSearch}
            onGetMakeModelPromise={onGetMakeModelPromise}
            onChange={this.handleSelectChange}
            isTransparentBg={isTransparentBg}
            isTopbarScrolled={isTopbarScrolled}
          />
        )
      : this.state.selectedListingType === LISTING_TYPE__REAL_ESTATE
        ? (
            <div className={css.reSearchFieldWrapper}>
              <LocationSearchField
                search={history.location.search}
                rootClassName={css.locationRootClassName}
                rootInputClassName={css.locationField}
                intl={intl}
                onLocationChange={this.handleLocationChange}
              />
              {/*<FieldTextInput*/}
              {/*  name='keywords'*/}
              {/*  id='keywords'*/}
              {/*  className={css.reSearchField}*/}
              {/*  placeholder={searchPlaceholder}*/}
              {/*/>*/}
            </div>
          )
        : null
    ;

    return (
      <div className={css.root}>
        <FinalForm
          {...restOfProps}
          initialValues={initialValues}
          onSubmit={submit}
          render={formRenderProps => {
            const {
              handleSubmit,
            } = formRenderProps;

            const preventFormSubmit = e => e.preventDefault();
            const submitFormFn = isKeywordsSearch ? handleSubmit : preventFormSubmit;

            return (
              <Form
                className={css.form}
                onSubmit={submitFormFn}
                enforcePagePreloadFor="AircraftSearchPage"
              >
                {searchField}
              </Form>
            );
          }}
        />
        <div className={css.switcherWrapper}>
          <div className={css.vertRule} />
          <ListingTypeSelector
            rootClassName={css.switcher}
            menuContentClassName={css.switcherContentClassName}
            intl={intl}
            selectedListingType={this.state.selectedListingType}
            onSelect={this.handleListingTypeSelect}
          />
        </div>
      </div>
    );
  }
}

MobileTopbarSearchForm.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  appConfig: config,
  isTransparentBg: false,
  isTopbarScrolled: false,
};

MobileTopbarSearchForm.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  appConfig: object,
  isTransparentBg: bool,
  isTopbarScrolled: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const TopbarSearchForm = injectIntl(MobileTopbarSearchForm);

export default TopbarSearchForm;
