import { BaseReducer } from 'redux-di-container';
import get from 'lodash/get';
import flatten from 'lodash/flatten';
import mean from 'lodash/mean';

import config from '../config';
import { addMarketplaceEntities, getListingsById } from '../ducks/marketplaceData.duck';
import { fetchAirplanesMetadata } from '../modules/airplanes/airplanes.creator';
import { createImageVariantConfig } from '../util/sdkLoader';
import { handleTransitAxiosResponse } from '../util/apiHelpers';

export class AircraftChildLandingService extends BaseReducer {
  constructor (opts) {
    super();
    this.restApiService = opts.restApiService;
    this.newsService = opts.newsService;
  }

  getInitialState() {
    return {
      urlParams: null,
      isEntryLoading: false,
      isEntryExists: false,
      entry: null,
      metadata: null,
      isListingsLoading: false,
      listingsIds: [],
      listingsMeta: null,
      priceChartData: {priceYear: [], priceTotalTime: [], priceSmoh: []},
      isPriceChartDataLoading: false,
      priceChartSelectedListingInfo: null,
      priceChartListingCardIsOpen: false,
      isPriceChartListingDataLoading: false,
      priceChartListing: {},
      priceChartListingMetadata: {},
      aircraftTypeOptions: [],
      aircraftTypeOptionsIsLoading: false,
      countResult: null,
    };
  }

  $urlParams(state) {
    return this.select(state).urlParams;
  }

  $isEntryLoading(state) {
    return this.select(state).isEntryLoading;
  }

  $entry(state) {
    return this.select(state).entry;
  }

  $entryFields(state) {
    return get(this.$entry(state), 'fields', null);
  }

  $metadata(state) {
    return this.select(state).metadata;
  }

  $listingsIds(state) {
    return this.select(state).listingsIds;
  }

  $listings(state) {
    const ids = this.$listingsIds(state);
    return getListingsById(state, ids);
  }

  $listingsMeta(state) {
    return this.select(state).listingsMeta;
  }

  $pagination(state) {
    const meta = this.$listingsMeta(state);
    const listingsIds = this.$listingsIds(state);
    return {
      total: meta ? meta.totalItems : 0,
      isButtonVisible: meta ? meta.totalItems > listingsIds.length : false,
      nextPage: meta ? meta.page + 1 : 1,
    };
  }

  $priceChartData(state) {
    return this.select(state).priceChartData;
  }

  $averagePrice(state) {
    const priceChartData = this.$priceChartData(state);
    const prices = priceChartData.priceYear
      ?.filter(i => !!i[1])
      .map(i => i[1])
    ;
    return mean(prices);
  }

  $isPriceChartDataLoading(state) {
    return this.select(state).isPriceChartDataLoading;
  }

  $priceChartListing(state) {
    return this.select(state).priceChartListing;
  }

  $priceChartListingMetadata(state) {
    return this.select(state).priceChartListingMetadata;
  }

  $priceChartListingData(state) {
    const info = this.$priceChartSelectedListingInfo(state);
    return {
      isLoading: this.select(state).isPriceChartListingDataLoading,
      listing: info && info.id && get(this.$priceChartListing(state), info.id, null),
      metadata: info && info.id && get(this.$priceChartListingMetadata(state), info.id, null),
    };
  }

  $priceChartSelectedListingInfo(state) {
    return this.select(state).priceChartSelectedListingInfo;
  }

  $priceChartListingCardIsOpen(state) {
    return this.select(state).priceChartListingCardIsOpen;
  }

  $aircraftTypeOptions(state) {
    return this.select(state).aircraftTypeOptions;
  }

  $aircraftTypeOptionsIsLoading(state) {
    return this.select(state).aircraftTypeOptionsIsLoading;
  }

  $countResult(state) {
    return this.select(state).countResult;
  }

  priceChartPointClick(info, isMobileLayout) {
    const stateInfo = this.$priceChartSelectedListingInfo(this.getState());
    const newInfo = stateInfo && info
      ? stateInfo.id === info.id && isMobileLayout
        ? null
        : info
      : info
    ;
    const newIsOpen = stateInfo && info && isMobileLayout
      ? stateInfo.id !== info.id
      : true
    ;
    this.dispatchAction(state => {
      state.priceChartSelectedListingInfo = newInfo;
      state.priceChartListingCardIsOpen = newIsOpen;
    }, 'priceChartPointClick');
  }

  fetchListings() {
    this.dispatchAction(state => {
      state.isListingsLoading = true;
    }, 'loadListingsPending');
    const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
    const aspectRatio = aspectHeight / aspectWidth;
    const allParams = {
      ...this.$urlParams(this.getState()),
      page: this.$pagination(this.getState()).nextPage,
      per_page: 8,
      include: ['author', 'images'],
      'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData.companyName'],
      'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      'limit.images': 1,
    };
    const searchParams = new URLSearchParams(allParams);
    return this.restApiService.publicInstance
      .getRaw(`/search/air?${searchParams.toString()}`)
      .then(handleTransitAxiosResponse)
      .then(response => {
        this.dispatch(addMarketplaceEntities(response));
        this.dispatch(fetchAirplanesMetadata(response.data.data));
        this.dispatchAction(state => {
          state.isListingsLoading = false;
          state.listingsIds = [ ...state.listingsIds, ...response.data.data.map(l => l.id)];
          state.listingsMeta = response.data.meta;
        }, 'loadListingsSuccess');
        return response;
      })
      .catch(e => {
        this.dispatchAction(state => {
          state.isListingsLoading = false;
        }, 'loadListingError');
        throw e;
      })
    ;
  }

  fetchPriceData() {
    this.dispatchAction(state => {
      state.priceChartData = {priceYear: [], priceTotalTime: [], priceSmoh: []};
      state.isPriceChartDataLoading = true;
    }, 'loadChartDataPending');
    const urlParams = this.$urlParams(this.getState());
    if (!urlParams.can_category) {
      return Promise.reject();
    }
    const search = new URLSearchParams(urlParams);
    return this.restApiService.publicInstance
      .get(`/child-landing/price-chart-data?${search.toString()}`)
      .then(chartData => {
        this.dispatchAction(state => {
          state.priceChartData = chartData;
          state.isPriceChartDataLoading = false;
        }, 'loadChartDataSuccess');
        return chartData;
      })
      .catch(e => {
        this.dispatchAction(state => {
          state.isPriceChartDataLoading = false;
        }, 'loadChartDataError');
        throw e;
      })
    ;
  }

  fetchPriceChartListingData() {
    const info = this.$priceChartSelectedListingInfo(this.getState());
    const id = info.id;
    if (!id) {
      return Promise.reject();
    }
    const stateListings = this.$priceChartListing(this.getState());
    const stateMetadata = this.$priceChartListingMetadata(this.getState());
    const stateListing = get(stateListings, id, null);
    if (stateListing) {
      return Promise.resolve();
    }
    this.dispatchAction(state => {
      state.isPriceChartDataLoading = true;
    }, 'fetchPriceChartListingDataPending');
    return this.restApiService.publicInstance
      .get(`/child-landing/price-chart-listing-data/${id}`)
      .then(data => {
        this.dispatchAction(state => {
          state.isPriceChartDataLoading = false;
          state.priceChartListing = {
            ...stateListings,
            [id]: data.listing,
          };
          state.priceChartListingMetadata = {
            ...stateMetadata,
            [id]: data.metadata,
          };
        }, 'fetchPriceChartListingDataSuccess');
        return data;
      })
      .catch(e => {
        this.dispatchAction(state => {
          state.isPriceChartDataLoading = false;
        }, 'fetchPriceChartListingDataError');
        throw e;
      })
    ;
  }

  fetchAircraftTypeOptions() {
    this.dispatchAction(state => {
      state.aircraftTypeOptionsIsLoading = true;
      state.aircraftTypeOptions = [];
    }, 'fetchAircraftTypeOptionsPending');
    this.restApiService.publicInstance
      .get('/airplanes/categories')
      .then(data => {
        this.dispatchAction(state => {
          state.aircraftTypeOptionsIsLoading = false;
          state.aircraftTypeOptions = flatten(data.data.map(i => i.items));
        }, 'fetchAircraftTypeOptionsSuccess');
        return data;
      })
      .catch(e => {
        this.dispatchAction(state => {
          state.aircraftTypeOptionsIsLoading = false;
        }, 'fetchAircraftTypeOptionsError');
        throw e;
      })
    ;
  }

  fetchOtherLinks(entry, metadata) {
    if (entry.recommendAircraftColumnLeft && entry.recommendAircraftColumnRight) {
      return ;
    }
    const search = new URLSearchParams({
      pub_category: metadata.categoryId,
      ...(metadata.makerId && { pub_make: metadata.makerId }),
    });
    this.restApiService.publicInstance
      .get(`/child-landing/other-links?${search.toString()}`)
      .then(response => {
        const entry = this.$entry(this.getState());
        this.dispatchAction(state => {
          state.entry = {
            fields: {
              ...entry.fields,
              recommendAircraftColumnLeft: response.recommendAircraftColumnLeft,
              recommendAircraftColumnRight: response.recommendAircraftColumnRight,
            },
          };
        }, 'fetchOtherLinksSuccess');
      })
      .catch(() => {})
    ;
  }

  loadData = params => async () => {
    const searchParams = new URLSearchParams(params);
    const countResult = await this.restApiService.publicInstance
      .get(`/child-landing/listings-count?${searchParams.toString()}`)
    ;

    if (countResult) {
      this.dispatchAction(state => {
        state.countResult = countResult;
      }, 'countResultSet');
      if (countResult.shouldRedirect === '404') {
        return Promise.resolve();
      }
    }

    const newParams = (countResult && countResult.canParams) || params;
    this.dispatchAction(state => {
      state.isEntryLoading = true;
      state.entry = null;
      state.metadata = null;
      state.urlParams = newParams;
    }, 'loadDataPending');

    try {
      const dataResponse = await this.restApiService.publicInstance
        .get(`/child-landing/data?${new URLSearchParams(newParams).toString()}`)
      ;
      const metadata = dataResponse.metadata;
      const entry = dataResponse.entry;
      this.dispatchAction(state => {
        state.isEntryLoading = false;
        state.entry = entry;
        state.metadata = metadata;
      }, 'fetchEntrySuccess');
      await this.fetchListings();
      // this.fetchOtherLinks(entry, metadata);
      // this.fetchPriceData();
      // this.fetchAircraftTypeOptions();
      // this.newsService.fetchNews(
      //   metadata.searchString,
      //   metadata.categoryName,
      //   metadata.makerName,
      //   metadata.modelFamilyName
      // );
      return Promise.resolve();
    } catch (e) {
      this.dispatchAction(state => {
        state.isEntryLoading = false;
      }, 'fetchEntryError');
      return Promise.reject();
    }
  }
}
