import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { composeValidators } from '../../util/validators';
import * as validators from '../../util/validators';
import { FormattedMessage } from '../../util/reactIntl';

import {
  Form,
  IconEnquiry,
  FieldTextInput,
  FieldCheckbox,
  PrimaryButton,
  NamedLink,
} from '../../components';
import FieldRecaptcha from '../../components/FieldRecaptcha/FieldRecaptcha';
import FieldPhoneInput2 from '../../components/FieldPhoneInput2/FieldPhoneInput2';

import css from './InquireForm.module.scss';

const InquireForm = (props) => {
  const {
    intl,
    sendEnquiryInProgress,
    sendEnquiryError,
    listingTitle,
    onSubmit,
    onCloseEnquiryModal,
  } = props;

  const initialValues = {
    message: 'Please provide pricing. Thanks.',
    isFinanceRequest: false,
  };

  return (
    <FinalForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={fieldRenderProps => {
        const {
          submitButtonWrapperClassName,
          handleSubmit,
          invalid,
        } = fieldRenderProps;

        const classes = classNames(css.root);
        const submitDisabled = sendEnquiryInProgress || invalid;

        const emailValidators = composeValidators(
          validators.requiredAndNonEmptyString(intl.formatMessage({ id: 'UnauthEnquiryForm.email.required' })),
          validators.emailFormatValid(intl.formatMessage({ id: 'UnauthEnquiryForm.email.invalid' })),
        );

        return (
          <Form
            className={classes}
            onSubmit={values => {
              handleSubmit(values)
                .then(() => {
                  FieldRecaptcha.reset();
                  onCloseEnquiryModal();
                })
                .catch(() => {})
              ;
            }}
            enforcePagePreloadFor="OrderDetailsPage"
          >
            <IconEnquiry className={css.icon} />
            <h2 className={css.heading}>
              <FormattedMessage id="EnquiryForm.heading" values={{ listingTitle }} />
            </h2>

            <FieldTextInput
              name="fname"
              id='fname'
              type='text'
              className={css.field}
              label={intl.formatMessage({ id: 'UnauthEnquiryForm.userName.label' })}
              placeholder={intl.formatMessage({ id: 'UnauthEnquiryForm.userName.placeholder' })}
              validate={validators.requiredAndNonEmptyString(
                intl.formatMessage({ id: 'UnauthEnquiryForm.userName.required' })
              )}
            />

            <FieldTextInput
              name="lname"
              id='lname'
              type='text'
              className={css.field}
              label={intl.formatMessage({ id: 'UnauthEnquiryForm.lastName.label' })}
              placeholder={intl.formatMessage({ id: 'UnauthEnquiryForm.lastName.placeholder' })}
              validate={validators.requiredAndNonEmptyString(
                intl.formatMessage({ id: 'UnauthEnquiryForm.userName.required' })
              )}
            />

            <FieldTextInput
              name="email"
              id='email'
              type='text'
              className={css.field}
              label={intl.formatMessage({ id: 'UnauthEnquiryForm.email.label' })}
              placeholder={intl.formatMessage({ id: 'UnauthEnquiryForm.email.placeholder' })}
              validate={emailValidators}
            />

            <div className={css.listingPhoneNumberField}>
              <FieldPhoneInput2
                intl={intl}
                name='phone'
                id='phone'
                label={intl.formatMessage({ id: 'UnauthEnquiryForm.phone.label' })}
              />
            </div>

            <FieldTextInput
              className={css.field}
              type="textarea"
              name="message"
              id='message'
              rows={5}
              label={intl.formatMessage({ id: 'UnauthEnquiryForm.message.label' })}
              placeholder={intl.formatMessage({ id: 'UnauthEnquiryForm.message.placeholder' })}
              validate={validators.requiredAndNonEmptyString(
                intl.formatMessage({ id: 'UnauthEnquiryForm.message.required' })
              )}
            />

            <div className={css.isAgreeToTermsOfServiceAndPrivacyPolicy}>
              <FieldCheckbox
                id="agreeToTermsOfServiceAndPrivacyPolicy"
                name="agreeToTermsOfServiceAndPrivacyPolicy"
                label={
                  <FormattedMessage
                    id="UnauthEnquiryForm.isTermsPrivacy.label"
                    values={{
                      terms: <NamedLink name="TermsOfServicePage" className={css.link}>Terms of Service</NamedLink>,
                      privacy: <NamedLink name="PrivacyPolicyPage" className={css.link}>Privacy Policy</NamedLink>,
                    }}
                    tagName='b'
                  />
                }
                validate={value => !value ? true : undefined}
              />
            </div>

            <div className={css.isFinanceRequestCheckbox}>
              <FieldCheckbox
                id="isFinanceRequest"
                name="isFinanceRequest"
                label={<FormattedMessage id='UnauthEnquiryForm.isFinanceRequest.label' tagName='b' />}
                defaultValue={true}
              />
              <div className={css.logoFlyingFinance}>
                <a target='_blank' href='https://flyingfinance.com/?utm_source=AFS&utm_medium=referral&utm_campaign=afs_inquirebox'><span /></a>
              </div>
            </div>

            <div className={css.recaptcha}>
              <FieldRecaptcha
                intl={intl}
                id='recaptcha'
                name='recaptcha'
              />
            </div>

            <div className={submitButtonWrapperClassName}>
              {sendEnquiryError && (
                <p className={css.error}>{sendEnquiryError}</p>
              )}

              <PrimaryButton
                type="submit"
                inProgress={sendEnquiryInProgress}
                disabled={submitDisabled}
              >
                <FormattedMessage id="EnquiryForm.submitButtonText" />
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
};

export default InquireForm;
