import React from 'react';

const EmptyAvatar = (props) => {
  const {
    color,
    className,
  } = props;

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="194.438"
      height="194.43"
      viewBox="0 0 194.438 194.43"
    >
      <g
        id="Group_11591"
        data-name="Group 11591"
        transform="translate(6 6)"
      >
        <g
          id="profile-user"
          transform="translate(0 0)"
        >
          <path
            id="Path_23422"
            data-name="Path 23422"
            d="M91.219,0a91.215,91.215,0,1,0,91.219,91.215A91.22,91.22,0,0,0,91.219,0Zm0,27.274A30.171,30.171,0,1,1,61.052,57.446,30.173,30.173,0,0,1,91.219,27.275ZM91.2,158.582a66.946,66.946,0,0,1-43.594-16.075,12.857,12.857,0,0,1-4.512-9.773,30.428,30.428,0,0,1,30.576-30.42h35.108a30.384,30.384,0,0,1,30.528,30.42A12.825,12.825,0,0,1,134.8,142.5,66.922,66.922,0,0,1,91.2,158.582Z"
            transform="translate(0 -0.001)"
            fill="none"
            stroke={color}
            strokeWidth="12"
          />
        </g>
      </g>
    </svg>
  );
};

EmptyAvatar.defaultProps = {
  color: 'red',
  className: '',
};

export default EmptyAvatar;
