import get from 'lodash/get';
import moment from 'moment';
import { sortByDate } from '../util/listing';

const getTheSameFields = (i) => {
  const date = moment(i.event_date, "MM-DD-YYYY A");

  return {
    date: date.isValid() ? date.format('l') : '',
    makeModel: `${i.make} ${i.model}`,
    registration: i.n_number,
    learnMoreText: i.description,
  }
}

export const accidents = subState => {
  const ntsbReports = get(subState, 'ntsb_reports_cleaned', []);
  const faaAccidents = get(subState, 'faa_accidents_cleaned', []);
  const servicesDifficultyReports = get(subState, 'service_difficulty_reports_cleaned', []);

  return sortByDate([
    ...ntsbReports.map(i => {
      return {
        source: 'NTSB',
        location: `${i.city}, ${i.state}`,
        type: i.damage_level,
        ...getTheSameFields(i)
      };

    }),
    ...faaAccidents.map(i => {
      return {
        source: 'FAA',
        location: `${i.city}, ${i.state}`,
        type: i.damage_level,
        ...getTheSameFields(i)
      };
    }),
    ...servicesDifficultyReports.map(i => {
      return {
        source: 'SDR',
        location: 'N/A',
        type: 'N/A',
        ...getTheSameFields(i)
      };
    }),
  ], 'MM-DD-YYYY A', 'event_date');
};
