import React from 'react';
import classnames from 'classnames';

import {
  LISTING_TYPE__AIRPLANES,
  LISTING_TYPE__REAL_ESTATE,
} from '../../../util/editListingHelpers';

import {
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
} from '../../../components';
import IconDropdownIndicator from '../../IconDropdownIndicator/IconDropdownIndicator';

import css from './TopbarSearchForm.module.scss';

const ListingTypeSelector = (props) => {
  const {
    menuContentClassName,
    rootClassName,
    intl,
    onSelect,
    selectedListingType,
  } = props;

  const [isOpen, setIsOpen] = React.useState(false);

  const handleChange = is => {
    setIsOpen(is);
  }

  const handleSelect = listingType => {
    onSelect(listingType);
    setIsOpen(false);
  };

  const handleAirClick = () => {
    handleSelect(LISTING_TYPE__AIRPLANES);
  }

  const handleReClick = () => {
    handleSelect(LISTING_TYPE__REAL_ESTATE);
  }

  const dropdownIndicatorClasses = classnames(css.dropdownIndicator, {
    [css.up]: isOpen,
  });

  const airLabel = intl.formatMessage({ id: 'TopbarDesktop.listingTypeSelector.air' });
  const reLabel = intl.formatMessage({ id: 'TopbarDesktop.listingTypeSelector.re' });
  const label = selectedListingType === LISTING_TYPE__AIRPLANES
    ? airLabel
    : selectedListingType === LISTING_TYPE__REAL_ESTATE
      ? reLabel
      : ''
  ;

  return (
    <Menu
      className={classnames(css.selector, rootClassName)}
      useArrow={false}
      onToggleActive={handleChange}
      isOpen={isOpen}
    >
      <MenuLabel>
        {label}
        <IconDropdownIndicator
          className={dropdownIndicatorClasses}
        />
      </MenuLabel>
      <MenuContent
        className={classnames(menuContentClassName, css.selectorContent)}
      >
        <MenuItem key='air' rootClassName={css.item}>
          <button onClick={handleAirClick}>
            {airLabel}
          </button>
        </MenuItem>
        <MenuItem key='re' rootClassName={css.item}>
          <button onClick={handleReClick}>
            {reLabel}
          </button>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
};

export default ListingTypeSelector;
