import * as Transitions from './transitions';
import * as States from './states';

export const stateDescription = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'flex-enquiry-custom-process/release-1',

  // This 'initial' state is a starting point for new transaction
  initial: States.STATE_INITIAL,

  // States
  states: {
    [States.STATE_INITIAL]: {
      on: {
        [Transitions.TRANSITION_ENQUIRE]: States.STATE_ENQUIRY,
      },
    },
    [States.STATE_ENQUIRY]: {
      on: {
        [Transitions.TRANSITION_COMPLETE_ENQUIRE]: States.STATE_COMPLETED,
      },
    },
    [States.STATE_COMPLETED]: {},
    [States.STATE_COMPLETED]: { type: 'final' },
  },
};
