import axios from 'axios';

import {
  apiEndpoint,
  handleAxiosResponse,
} from '../../util/apiHelpers';

export const fetchCategoriesApi = () => {
  return axios.get(`${apiEndpoint}/airplanes/categories`)
    .then(handleAxiosResponse);
};

export const fetchAirplaneMetadataApi = reqData => {
  const url = `${apiEndpoint}/airplanes/make-model-by-ids`;
  return axios.post(url, reqData);
};

export const fetchQuickSearchMakeModelApi = searchVal => {
  const url = `${apiEndpoint}/airplanes/quick-search-make-model?val=${searchVal}`;
  return axios.get(url);
};
