import {
  fetchCountsByCategories,
  fetchCategories,
  $countsByCategoriesState,
  $categoriesState,
} from '../../ducks/AirMetadata.duck';

import jetPng from './SectionCategories/images/jet.png';
import turbopropPng from './SectionCategories/images/turboprop.png';
import singleEnginePng from './SectionCategories/images/singleEngine.png';
import multiEnginePng from './SectionCategories/images/multiEngine.png';
import turboHelicopterPng from './SectionCategories/images/turboHelicopter.png';
import pistonHelicopterPng from './SectionCategories/images/pistonHelicopter.png';
import electricHelicopterPng from './SectionCategories/images/electricHelicopter.png';
import poweredParachutePng from './SectionCategories/images/poweredParachute.png';
import electricPng from './SectionCategories/images/electric.png';

const ids = [2, 9, 7, 4, 6, 5, 1, 8, 3];
const images = {
  1: electricHelicopterPng,
  2: jetPng,
  3: electricPng,
  4: multiEnginePng,
  5: pistonHelicopterPng,
  6: turboHelicopterPng,
  7: singleEnginePng,
  8: poweredParachutePng,
  9: turbopropPng
};

const initialState = {
};

export default function aircraftParentLandingPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    default:
      return state;
  }
}

export const $categoriesOptionsState = state => {
  const countsByCategoriesState = $countsByCategoriesState(state);
  const categoriesState = $categoriesState(state);
  return {
    isPending: countsByCategoriesState.isPending || categoriesState.isPending,
    error: countsByCategoriesState.error || categoriesState.error,
    options: categoriesState.categories.map(category => {
      const count = countsByCategoriesState.counts[category.id];
      if (ids.includes(category.id) && count !== 0) {
        return {
          id: category.id,
          can_category: category.can_category,
          category: category.name,
          count,
          img: images[category.id],
          order: ids.findIndex(id => id === category.id),
        };
      }
      return null;
    }).filter(i => !!i),
  };
};

export const loadData = () => dispatch => {
  return Promise.all([
    dispatch(fetchCountsByCategories()),
    dispatch(fetchCategories()),
  ]);
};
