import { BaseReducer } from 'redux-di-container';

export class AlertModalService extends BaseReducer {
  static INFO = 'info';
  static SUCCESS = 'success';
  static ERROR = 'error';
  static WARNING = 'warning';

  getInitialState() {
    return {
      isOpen: false,
      type: AlertModalService.INFO,
      header: '',
      message: '',
    };
  }

  $alertModalState = this.createSelector(
    state => this.select(state),
    thisState => thisState
  );

  openAlert(header, message, type = AlertModalService.INFO) {
    this.dispatchAction(state => {
      state.isOpen = true;
      state.header = header;
      state.message = message;
      state.type = type;
    }, 'openAlert');
  }

  openSuccessAlert(header, message) {
    this.openAlert(header, message, AlertModalService.SUCCESS);
  }

  openErrorAlert(header, message) {
    this.openAlert(header, message, AlertModalService.ERROR);
  }

  openWarningAlert(header, message) {
    this.openAlert(header, message, AlertModalService.WARNING);
  }

  openInfoAlert(header, message) {
    this.openAlert(header, message, AlertModalService.INFO);
  }

  closeModal() {
    this.resetState();
  }
}
