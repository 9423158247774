import React from 'react';
import { connect } from 'react-redux';

import { alertModalService } from '../../services/services';

import { Modal } from '../../components';

const AlertModal = (props) => {
  const {
    alertModalState,
  } = props;

  return (
    <Modal
      id="AlertModal"
      isOpen={alertModalState.isOpen}
      onClose={() => alertModalService.closeModal()}
      usePortal={false}
      onManageDisableScrolling={() => {}}
    >
      <h2>{alertModalState.header}</h2>
      <p>
        {alertModalState.message}
      </p>
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    alertModalState: alertModalService.$alertModalState(state),
  };
};

export default connect(
  mapStateToProps
)(AlertModal);
