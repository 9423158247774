import {
  restApiService,
} from '../../services/services';
import {
  handleAxiosResponse,
} from '../../util/apiHelpers';
import {
  axiosStorableError,
} from '../../util/errors';
import {
  priceSearchParams,
} from '../../util/currency';
import {
  LISTING_TYPE__AIRPLANES,
} from '../../util/editListingHelpers';

const FETCH_SAVED_SEARCHES = 'app/SavedSearchPage/FETCH_SAVED_SEARCHES';
const FETCH_SAVED_SEARCHES_SUCCESS = 'app/SavedSearchPage/FETCH_SAVED_SEARCHES_SUCCESS';
const FETCH_SAVED_SEARCHES_ERROR = 'app/SavedSearchPage/FETCH_SAVED_SEARCHES_ERROR';

const SAVE_SAVED_SEARCH = 'app/SavedSearchPage/SAVE_SAVED_SEARCH';
const SAVE_SAVED_SEARCH_SUCCESS = 'app/SavedSearchPage/SAVE_SAVED_SEARCH_SUCCESS';
const SAVE_SAVED_SEARCH_ERROR = 'app/SavedSearchPage/SAVE_SAVED_SEARCH_ERROR';

const REMOVE_SAVED_SEARCH = 'app/SavedSearchPage/REMOVE_SAVED_SEARCH';
const REMOVE_SAVED_SEARCH_SUCCESS = 'app/SavedSearchPage/REMOVE_SAVED_SEARCH_SUCCESS';
const REMOVE_SAVED_SEARCH_ERROR = 'app/SavedSearchPage/REMOVE_SAVED_SEARCH_ERROR';

const CLEAR_STATE = 'app/SavedSearchPage/CLEAR_STATE';

const initialState = {
  fetchSavedSearchInProgress: false,
  fetchSavedSearchError: null,
  savedSearches: [],
  saveSavedSearchInProgress: false,
  saveSavedSearchError: null,
  removeSavedSearchInProgress: false,
  removeSavedSearchError: null,
  removingItem: null,
};

export default function savedSearchReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_SAVED_SEARCHES:
      return {
        ...state,
        fetchSavedSearchInProgress: true,
        fetchSavedSearchError: null,
      };

    case FETCH_SAVED_SEARCHES_SUCCESS:
      return {
        ...state,
        fetchSavedSearchInProgress: false,
        savedSearches: payload,
      };

    case FETCH_SAVED_SEARCHES_ERROR:
      return {
        ...state,
        fetchSavedSearchInProgress: false,
        fetchSavedSearchError: payload,
      };

    case SAVE_SAVED_SEARCH:
      return {
        ...state,
        saveSavedSearchInProgress: true,
        saveSavedSearchError: null,
      };

    case SAVE_SAVED_SEARCH_SUCCESS:
      return {
        ...state,
        saveSavedSearchInProgress: false,
        ...(payload && {
          savedSearches: state.savedSearches.map(i => {
            if (i.id === payload.id) {
              return payload;
            }
            return i;
          }),
        }),
      };

    case SAVE_SAVED_SEARCH_ERROR:
      return {
        ...state,
        saveSavedSearchInProgress: false,
        saveSavedSearchError: payload,
      };

    case REMOVE_SAVED_SEARCH:
      return {
        ...state,
        removingItem: payload,
        removeSavedSearchInProgress: true,
        removeSavedSearchError: null,
      };

    case REMOVE_SAVED_SEARCH_SUCCESS:
      return {
        ...state,
        removeSavedSearchInProgress: false,
        savedSearches: state.savedSearches.filter(i => i.id !== state.removingItem.id),
      };

    case REMOVE_SAVED_SEARCH_ERROR:
      return {
        ...state,
        removeSavedSearchInProgress: false,
        removeSavedSearchError: payload,
      };

    case CLEAR_STATE:
      return initialState;

    default:
      return state;
  }
}

const fetchSavedSearches = () => ({
  type: FETCH_SAVED_SEARCHES,
});

const fetchSavedSearchesSuccess = savedSearches => ({
  type: FETCH_SAVED_SEARCHES_SUCCESS,
  payload: savedSearches,
});

const fetchSavedSearchesError = error => ({
  type: FETCH_SAVED_SEARCHES_ERROR,
  payload: error,
});

const saveSavedSearchAction = () => ({
  type: SAVE_SAVED_SEARCH,
});

const saveSavedSearchSuccess = item => ({
  type: SAVE_SAVED_SEARCH_SUCCESS,
  payload: item,
});

const saveSavedSearchError = error => ({
  type: SAVE_SAVED_SEARCH_ERROR,
  payload: error,
});

const removeSavedSearchAction = item => ({
  type: REMOVE_SAVED_SEARCH,
  payload: item,
});

const removeSavedSearchSuccess = () => ({
  type: REMOVE_SAVED_SEARCH_SUCCESS,
});

const removeSavedSearchError = error => ({
  type: REMOVE_SAVED_SEARCH_ERROR,
  payload: error,
});

const $savedSearchState = state => {
  return state.SavedSearchPage;
};

export const $getSavedSearchesState = state => {
  const subState = $savedSearchState(state);
  return {
    inProgress: subState.fetchSavedSearchInProgress,
    error: subState.fetchSavedSearchError,
    data: subState.savedSearches,
  };
};

export const $saveSavedSearchState = state => {
  const subState = $savedSearchState(state);
  return {
    inProgress: subState.saveSavedSearchInProgress,
    error: subState.saveSavedSearchError,
  };
};

export const $removeSavedSearchState = state => {
  const subState = $savedSearchState(state);
  return {
    inProgress: subState.removeSavedSearchInProgress,
    error: subState.removeSavedSearchError,
    removingItem: subState.removingItem,
  };
};

export const getSavedSearches = userId => dispatch => {
  dispatch(fetchSavedSearches());
  return restApiService.secureInstance
    .getRaw(`savedSearches/${userId}`)
    .then(handleAxiosResponse)
    .then(rows => {
      dispatch(fetchSavedSearchesSuccess(rows));
      return rows;
    })
    .catch(e => {
      dispatch(fetchSavedSearchesError(axiosStorableError(e).message));
      throw e;
    })
  ;
};

export const addSavedSearch = (listingType, userId, name, freq) => dispatch => {
  dispatch(saveSavedSearchAction());
  const search = window.location.search;
  const searchObj = Object.fromEntries(new URLSearchParams(search));
  return restApiService.secureInstance
    .postRaw('/savedSearches', {
      listingType,
      userId,
      name,
      freq,
      searchObj,
    })
    .then(handleAxiosResponse)
    .then(savedSearches => {
      dispatch(saveSavedSearchSuccess(savedSearches));
      return savedSearches;
    })
    .catch(e => {
      dispatch(saveSavedSearchError(axiosStorableError(e).message));
      throw e;
    })
  ;
};

export const updateSavedSearch = item => dispatch => {
  dispatch(saveSavedSearchAction());
  return restApiService.secureInstance
    .postRaw(`savedSearches/${item.id}`, {
      name: item.name,
      freq: item.freq,
    })
    .then(handleAxiosResponse)
    .then(item => {
      dispatch(saveSavedSearchSuccess(item));
      return item;
    })
    .catch(e => {
      dispatch(saveSavedSearchError(axiosStorableError(e).message));
      throw e;
    })
  ;
};

export const removeSavedSearch = item => dispatch => {
  dispatch(removeSavedSearchAction(item));
  return restApiService.secureInstance
    .deleteRaw(`savedSearches/${item.id}`)
    .then(handleAxiosResponse)
    .then(() => {
      dispatch(removeSavedSearchSuccess());
      return Promise.resolve();
    })
    .catch(e => {
      dispatch(removeSavedSearchError(axiosStorableError(e).message));
      throw e;
    })
  ;
};

export const getSearchCount = savedSearchObj => (dispatch, getState, sdk) => {
  return sdk.listings.query({
    ...{
      ...savedSearchObj,
      ...(savedSearchObj.price && priceSearchParams(savedSearchObj.price)),
    },
    pub_listingType: LISTING_TYPE__AIRPLANES,
    states: ['published'],
    'fields.listing': [ 'id' ],
  })
    .then(response => {
      return response.data.meta.totalItems;
    })
    .catch(e => {
      throw e;
    })
  ;
};

