import { types as sdkTypes } from '../util/sdkLoader';
import {
  addMarketplaceEntities,
  getMarketplaceEntities,
} from './marketplaceData.duck';
import { fetchAirplanesMetadata } from '../modules/airplanes/airplanes.creator';
import { restApiService } from '../services/services';
import { stringify } from '../util/urlHelpers';

const { UUID } = sdkTypes;

// ================ Action types ================ //

const GET_SIMILAR_LISTING_REQUEST = 'app/ListingPage/GET_SIMILAR_LISTING_REQUEST';
const GET_SIMILAR_LISTING_SUCCESS = 'app/ListingPage/GET_SIMILAR_LISTING_SUCCESS';
const GET_SIMILAR_LISTING_ERROR = 'app/ListingPage/GET_SIMILAR_LISTING_ERROR';

const RESET_STATE = 'app/ListingPage/RESET_STATE';

// ================ Reducer ================ //

const initialState = {
  inProgress: false,
  error: null,
  ids: [],
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SIMILAR_LISTING_REQUEST:
      return {
        ...state,
        inProgress: true,
        error: null
      };

    case GET_SIMILAR_LISTING_SUCCESS:
      return {
        ...state,
        inProgress: false,
        ids: payload
      };

    case GET_SIMILAR_LISTING_ERROR:
      return {
        ...state,
        inProgress: false,
        error: payload,
      };

    case RESET_STATE:
      return initialState;

    default:
      return state;
  }
};

// ================ Selectors ====================== //

export const $similarListingsState = state => {
  const subState = state.SimilarListings;
  const ids = subState.ids;
  const refs = ids.map(id => ({ type: 'listing', id: new UUID(id) }));
  const listings = getMarketplaceEntities(state, refs);

  return {
    data: listings,
    inProgress: subState.inProgress,
    error: subState.error,
  };
};

// ================ Action creators ================ //

const getSimilarListingsRequest = () => ({
  type: GET_SIMILAR_LISTING_REQUEST,
});

const getSimilarListingsSuccess = ids => ({
  type: GET_SIMILAR_LISTING_SUCCESS,
  payload: ids,
});

const getSimilarListingsError = errorMsg => ({
  type: GET_SIMILAR_LISTING_ERROR,
  payload: errorMsg,
});

const resetStateAction = () => ({
  type: RESET_STATE,
});

// ================ Thunks ================ //

export const fetchSimilarListings = params => dispatch => {
  dispatch(getSimilarListingsRequest());
  restApiService.publicInstance
    .getRaw(`/similar_listings?${stringify(params)}`)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      const ids = response.data.data.map(l => l.id.uuid);
      dispatch(getSimilarListingsSuccess(ids));
      dispatch(fetchAirplanesMetadata(response.data.data));
      return response;
    })
    .catch(e => {
      const errorMsg = e.message;
      dispatch(getSimilarListingsError(errorMsg));
    })
  ;
};

export const fetchSimilarListingsByReNo = (regNo, params) => dispatch => {
  dispatch(getSimilarListingsRequest());
  restApiService.publicInstance
    .getRaw(`/similar_listings/byRegNo/${regNo}?${stringify(params)}`)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      const ids = response.data.data.map(l => l.id.uuid);
      dispatch(getSimilarListingsSuccess(ids));
      dispatch(fetchAirplanesMetadata(response.data.data));
      return response;
    })
    .catch(e => {
      const errorMsg = e.message;
      dispatch(getSimilarListingsError(errorMsg));
    })
  ;
};

export const resetState = () => dispatch => {
  dispatch(resetStateAction());
};
