import get from 'lodash/get';
import config from '../config';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';

import {
  getExclusiveEndDate,
  parseDateFromISO8601,
} from './dates';
import {
  priceSearchParams,
} from './currency';
import {
  encodeLatLngBounds,
} from './urlHelpers';

/**
 * SelectMultipleFilter needs to parse values from format
 * "has_all:a,b,c,d" or "a,b,c,d"
 */
export const parseSelectFilterOptions = uriComponentValue => {
  const startsWithHasAll = uriComponentValue && uriComponentValue.indexOf('has_all:') === 0;
  const startsWithHasAny = uriComponentValue && uriComponentValue.indexOf('has_any:') === 0;

  if (startsWithHasAll) {
    return uriComponentValue.substring(8).split(',');
  } else if (startsWithHasAny) {
    return uriComponentValue.substring(8).split(',');
  } else {
    return uriComponentValue.split(',');
  }
};

/**
 * Check if any of the filters (defined by filterIds) have currently active query parameter in URL.
 */
export const isAnyFilterActive = (filterIds, urlQueryParams, filterConfigs) => {
  const getQueryParamKeysOfGivenFilters = (keys, config) => {
    const isFilterIncluded = filterIds.includes(config.id);
    const addedQueryParamNamesMaybe = isFilterIncluded ? config.queryParamNames : [];
    return [...keys, ...addedQueryParamNamesMaybe];
  };
  const queryParamKeysOfGivenFilters = filterConfigs.reduce(getQueryParamKeysOfGivenFilters, []);

  const paramEntries = Object.entries(urlQueryParams);
  const activeKey = paramEntries.find(entry => {
    const [key, value] = entry;
    return queryParamKeysOfGivenFilters.includes(key) && value != null;
  });
  return !!activeKey;
};

/**
 * Check if the filter is currently active.
 */
export const findOptionsForSelectFilter = (filterId, filters) => {
  const filter = filters.find(f => f.id === filterId);
  return filter && filter.config && filter.config.options ? filter.config.options : [];
};

/**
 * Return filter config
 */
export const findConfigForSelectFilter = (filterId, filters) => {
  const filter = filters.find(f => f.id === filterId);
  return filter && filter.config ? filter.config : null;
};

/**
 * Check if the main search type is 'keywords'
 */
export const isMainSearchTypeKeywords = config => config.mainSearchType === 'pub_keywords';

/**
 * Check if the origin parameter is currently active.
 */
export const isOriginInUse = config =>
  config.mainSearchType === 'location' && config.sortSearchByDistance;

/**
 * Check if the stock management is currently active.
 */
export const isStockInUse = config => config.listingManagementType === 'stock';

export const getSearchOptionLabelByKey = (searchOptions, key) => {
  return get(searchOptions.find(i => i.key === key), 'label', null);
};

export const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

export const prepareSearchParams = (urlParams, searchParams) => {
  const datesSearchParams = datesParam => {
    const values = datesParam ? datesParam.split(',') : [];
    const hasValues = datesParam && values.length === 2;
    const startDate = hasValues ? values[0] : null;
    const isNightlyBooking = config.lineItemUnitType === 'line-item/night';
    const endDate =
      hasValues && isNightlyBooking
        ? values[1]
        : hasValues
          ? getExclusiveEndDate(values[1], 'Etc/UTC')
          : null;

    return hasValues
      ? {
        start: parseDateFromISO8601(startDate, 'Etc/UTC'),
        end: parseDateFromISO8601(endDate, 'Etc/UTC'),
        availability: 'full',
      }
      : {};
  };

  const { perPage, price, dates, sort, ...rest } = searchParams;
  const priceMaybe = priceSearchParams(price);
  const datesMaybe = datesSearchParams(dates);
  const sortMaybe = sort === config.custom.sortConfig.relevanceKey ? {} : { sort };

  Object.keys(rest).forEach(key => {
    if (typeof rest[key] === 'boolean') {
      rest[key] = rest[key].toString();
    }
  });

  const params = {
    ...rest,
    ...priceMaybe,
    ...datesMaybe,
    ...sortMaybe,
    per_page: perPage,
  };

  return pickBy({
    ...urlParams,
    ...{
      ...params,
      ...(params.bounds && { bounds: encodeLatLngBounds(params.bounds) }),
    },
  }, identity);
};

export const hasSearchParamsInUrl = () => {
  const isWindowDefined = typeof window !== 'undefined';
  if (isWindowDefined) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('page');
    searchParams.delete('createdAtStart');
    searchParams.delete('sort');
    return Array.from(searchParams.keys()).length;
  }
  return false;
}
