import get from 'lodash/get';

export const airplaneMetadataRequestData = listings => {
  return (listings || [])
    .map(l => {
      const publicData = get(l, 'attributes.publicData', {});
      if (publicData.category && publicData.make && publicData.model) {
        return {
          lId: l.id.uuid,
          catId: publicData.category,
          makId: publicData.make,
          modId: publicData.model,
        };
      }
    })
    .filter(i => !!i)
    ;
};
