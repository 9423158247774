import classNames from 'classnames';
import React from 'react';
import numeral from 'numeral';

import {
  getPrice,
} from '../../../../../util/listing';

import IconArrow from '../../../../../components/IconArrowUp/IconArrow';
import { REAL_ESTATE_KEY_RENTAL } from '../../../../SearchPage/SearchPage.constants';

import css from './PriceDifferenceRealEstate.module.scss';

const PriceDifferenceRealEstate = (props) => {
  const {
    formattedPrice,
    lastItem,
  } = props;

  const defaultPrice = lastItem?.defaultPrice / 100;
  const formattedDefaultPrice = numeral(defaultPrice).format('$0,0');

  const price = numeral(formattedPrice).value();

  const {percents, priceChangeUp} = getPrice(defaultPrice, price);

  const priceChangeStyles = classNames(css.percentsDifference, {
    [css.percentsDifferenceUp]: priceChangeUp,
    [css.percentsDifferenceDown]: !priceChangeUp,
  })

  return (
    <div className={css.priceDifferenceWrapper}>
      {!!percents && <div className={css.firstPrice}>{formattedDefaultPrice}</div>}
      <div className={css.lastPrice}>
        {formattedPrice}
        {lastItem.realEstateType === REAL_ESTATE_KEY_RENTAL && ' per month'}
      </div>
      {!!percents && <div className={priceChangeStyles}>
        ({priceChangeUp && '+'}{percents}%)
        <IconArrow className={priceChangeStyles && classNames( {[css.rotateArrow]: !priceChangeUp})} />
      </div>}
    </div>
  )
}

export default PriceDifferenceRealEstate;
