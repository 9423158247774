import range from 'lodash/range';
import moment from 'moment';

const getYearRange = (from, yearCount) => {
  return range(from, new Date().getFullYear() + yearCount);
};

export const yearOptions = getYearRange(1903, 3)
  .map(year => ({ key: year, label: `${year}` }))
;

export const inspectExpiryYears = getYearRange(1903, 53)
  .map(year => ({ key: year, label: `${year}` }))
;

export const buildYearOptions = getYearRange(1600, 1)
  .map(year => ({ key: year, label: `${year}` }))
;

export const scoreOptions = count => {
  return Array.from(
    { length: count },
    (_, i) => {
      const key = i + 1;
      let label = key;
      if (key === 1) {
        label = `${key} - needs replacing`;
      } else if (key === 4) {
        label = `${key} - poor`;
      } else if (key === 7) {
        label = `${key} - good`;
      } else if (key === 10) {
        label = `${key} - new`;
      }
      return { key: key.toString(), label };
    }
  );
};

export const dateKey = (mount, unit) => {
  return moment().subtract(mount, unit).startOf('day').toDate().getTime().toString();
};

export const FILTER_KEY_MAKER = 'pub_make';
export const FILTER_KEY_MODEL_FAMILY = 'pub_model_family';
export const FILTER_KEY_MODEL = 'pub_model';
export const FILTER_KEY_CATEGORY = 'pub_category';
export const FILTER_KEY_PRICE = 'price';
export const FILTER_KEY_YEAR = 'pub_year';
export const FILTER_KEY_AIRFRAME_TIME = 'pub_airframe_time';
export const FILTER_KEY_ENGINE_HOURS_1 = 'pub_eng1_hrs';
export const FILTER_KEY_ENGINE_HOURS_2 = 'pub_eng2_hrs';
export const FILTER_KEY_ENGINE_HOURS_3 = 'pub_eng3_hrs';
export const FILTER_KEY_ENGINE_HOURS_4 = 'pub_eng4_hrs';
export const FILTER_KEY_SEATS = 'pub_seats';
export const FILTER_KEY_PRICE_CHANGED = 'pub_price_changed';
export const FILTER_KEY_PRICE_DECREASE = 'pub_price_decrease';
export const FILTER_KEY_PRICE_INCREASE = 'pub_price_increase';
export const FILTER_KEY_HOURS_FLOWN = 'pub_hours_flown';
export const FILTER_KEY_EXPIRY_YEAR = 'pub_inspect_expiry_year';
export const FILTER_KEY_ESTIMATE_EVALUATION_TEXT = 'pub_estimate_evaluation_text';
export const FILTER_KEY_ESTIMATE_PUBLISHABLE = 'pub_estimate_publishable';
export const FILTER_KEY_AIRCRAFT_LISTING_TYPE = 'pub_aircraft_listing_type';
export const FILTER_KEY_FEATURED = 'pub_featured';

export const FILTER_KEYWORD = 'KeywordFilter';

export const FILTER_KEY_REAL_ESTATE_LISTING_TYPE = 'pub_aircraft_listing_type';
export const FILTER_KEY_HOME_TYPE = 'pub_home_type';

export const FILTER_KEY_MONTHLY_HOA_DUES_TYPE = 'pub_monthly_HOA_Dues';
export const FILTER_KEY_HAS_ACCIDENT_HISTORY = 'pub_has_accident_history';

export const FILTER_KEY_ROOMS = 'pub_rooms';
export const FILTER_KEY_INDOOR_FEATURES = 'pub_indoor_features';
export const FILTER_KEY_BASEMENT = 'pub_basement';
export const FILTER_KEY_APPLIANCES = 'pub_appliances';
export const FILTER_KEY_FLOOR_COVERING = 'pub_floor_covering';
export const FILTER_KEY_ARCHITECTURAL_STYLE = 'pub_architectural_style';
export const FILTER_KEY_EXTERIOR = 'pub_exterior';
export const FILTER_KEY_PARKING = 'pub_parking';
export const FILTER_KEY_ROOF = 'pub_roof';
export const FILTER_KEY_COOLING = 'pub_cooling';
export const FILTER_KEY_HEATING = 'pub_heating';
export const FILTER_KEY_HEATING_FUEL = 'pub_heating_fuel';
export const FILTER_KEY_VIEW = 'pub_view';
export const FILTER_KEY_BUILDING_AMENITIES = 'pub_building_amenities';
export const FILTER_KEY_AVIATION_AMENITIES = 'pub_aviation_amenities';
export const FILTER_KEY_OUTDOOR_AMENITIES = 'pub_outdoor_amenities';
export const FILTER_KEY_YEAR_BUILT = 'pub_year_built';

export const REAL_ESTATE_KEY_FOR_SALE = 'For Sale';
export const REAL_ESTATE_KEY_RENTAL = 'Rental';

export const HOME_TYPE_KEY_SINGLE_FAMILY = 'Single family';
export const HOME_TYPE_KEY_CONDO = 'Condo';
export const HOME_TYPE_KEY_TOWNHOUSE = 'Townhouse';
export const HOME_TYPE_KEY_MULTI_FAMILY = 'Multi family';
export const HOME_TYPE_KEY_APARTMENT = 'Apartment';
export const HOME_TYPE_KEY_MOBILE = 'Mobile / Manufactured';
export const HOME_TYPE_KEY_COOP = 'Coop Unit';
export const HOME_TYPE_KEY_LAND = 'Land';
export const HOME_TYPE_KEY_HANGAR = 'Hangar';
export const HOME_TYPE_KEY_FARM = 'Farm';
export const HOME_TYPE_KEY_COMMERCIAL = 'Commercial';
export const HOME_TYPE_KEY_OTHER = 'Other';


export const SECONDARY_RANGE_FILTER_KEYS = [
  FILTER_KEY_PRICE_INCREASE,
  FILTER_KEY_PRICE_DECREASE,
  FILTER_KEY_SEATS,
  FILTER_KEY_EXPIRY_YEAR,
  FILTER_KEY_HOURS_FLOWN,
];

export const CUSTOM_FILTER_KEYS = [
  FILTER_KEY_MAKER,
  FILTER_KEY_MODEL_FAMILY,
  FILTER_KEY_MODEL,
  FILTER_KEY_CATEGORY,
  FILTER_KEY_PRICE,
  FILTER_KEY_PRICE,
  FILTER_KEY_YEAR,
  FILTER_KEY_AIRFRAME_TIME,
  FILTER_KEY_ENGINE_HOURS_1,
  FILTER_KEY_ENGINE_HOURS_2,
  FILTER_KEY_ENGINE_HOURS_3,
  FILTER_KEY_ENGINE_HOURS_4,
  FILTER_KEY_ESTIMATE_EVALUATION_TEXT,
  FILTER_KEY_ESTIMATE_PUBLISHABLE,
  FILTER_KEY_AIRCRAFT_LISTING_TYPE,
  FILTER_KEY_FEATURED,
  ...SECONDARY_RANGE_FILTER_KEYS,
  FILTER_KEY_HAS_ACCIDENT_HISTORY,
];

export const FILTER_KEYS_ENGINE_HOURS = [
  FILTER_KEY_ENGINE_HOURS_1,
  FILTER_KEY_ENGINE_HOURS_2,
  FILTER_KEY_ENGINE_HOURS_3,
  FILTER_KEY_ENGINE_HOURS_4,
];

export const REAL_ESTATE_CUSTOM_FILTER_KEYS = [
  FILTER_KEY_HOME_TYPE,
];

export const nullEngineHoursValues = FILTER_KEYS_ENGINE_HOURS
  .reduce((acc, curr) => ({ ...acc, [curr]: null }), {})
;
export const IS_SHOW_MAP_LOCAL_STORAGE_KEY = 'is_show_map';

export const FILTER_ENGINE_HOURS_MIN = 0;
export const FILTER_ENGINE_HOURS_MAX = 10000;
export const FILTER_ENGINE_HOURS_STEP = 1;

export const SHOW_FEATURED_SECTION_STORAGE_KEY = 'isShowFeaturedSection';

export const SHOW_CALL_FOR_PRICE_LISTINGS = 'isShowCallForPriceListings';
