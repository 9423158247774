import get from 'lodash/get';
import axios from 'axios';

import { apiBaseUrl } from '../util/api';
import { handleJsonFetch, handleAxiosResponse } from '../util/apiHelpers';
import { fetchQuickSearchMakeModelApi } from '../modules/airplanes/airplanes.api';
import config from '../config';

// ================ Action types ================ //

export const AIRPLANES_MAKE_MODEL_REQUEST = 'app/AirplanesData/AIRPLANES_MAKE_MODEL_REQUEST';
export const AIRPLANES_MAKE_MODEL_SUCCESS = 'app/AirplanesData/AIRPLANES_MAKE_MODEL_SUCCESS';
export const AIRPLANES_MAKE_MODEL_ERROR = 'app/AirplanesData/AIRPLANES_MAKE_MODEL_ERROR';

export const AIRPLANES_MAKE_MODEL_CATEGORY_REQUEST = 'app/AirplanesData/AIRPLANES_MAKE_MODEL_CATEGORY_REQUEST';
export const AIRPLANES_MAKE_MODEL_CATEGORY_SUCCESS = 'app/AirplanesData/AIRPLANES_MAKE_MODEL_CATEGORY_SUCCESS';
export const AIRPLANES_MAKE_MODEL_CATEGORY_ERROR = 'app/AirplanesData/AIRPLANES_MAKE_MODEL_CATEGORY_ERROR';

export const AIRPLANES_CLEAR_DATA = 'app/AirplanesData/AIRPLANES_CLEAR_DATA';

// ================ Reducer ================ //

const initialState = {
  requestMakeModelInProgress: false,
  requestMakeModelCategoryInProgress: false,
  requestMakeModelError: false,
  requestMakeModelCategoryError: false,
  makeModel: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case AIRPLANES_MAKE_MODEL_REQUEST:
      return airplanesMakeModelRequestCase();

    case AIRPLANES_MAKE_MODEL_SUCCESS:
      return airplanesMakeModelSuccessCase(state, payload);

    case AIRPLANES_MAKE_MODEL_ERROR:
      return airplanesMakeModelErrorCase(state, payload);


    case AIRPLANES_MAKE_MODEL_CATEGORY_REQUEST:
      return makeModelCategoryRequestCase();

    case AIRPLANES_MAKE_MODEL_CATEGORY_SUCCESS:
      return makeModelCategorySuccessCase(state);

    case AIRPLANES_MAKE_MODEL_CATEGORY_ERROR:
      return makeModelCategoryErrorCase(state, payload);

    case AIRPLANES_CLEAR_DATA:
      return airplanesClearDataCase(state, payload);

    default:
      return state;
  }
};

const airplanesMakeModelRequestCase = () => {
  return {
    ...initialState,
    requestMakeModelInProgress: true,
  };
};

const airplanesMakeModelSuccessCase = (state, payload) => {
  return {
    ...state,
    requestMakeModelInProgress: false,
    makeModel: payload,
  };
};

const airplanesMakeModelErrorCase = (state, payload) => {
  return {
    ...state,
    requestMakeModelInProgress: false,
    requestMakeModelError: payload,
  };
};

const makeModelCategoryRequestCase = () => {
  return {
    ...initialState,
    requestMakeModelCategoryInProgress: true,
  };
};

const makeModelCategorySuccessCase = state => {
  return {
    ...state,
    requestMakeModelCategoryInProgress: false,
  };
};

const makeModelCategoryErrorCase = (state, payload) => {
  return {
    ...state,
    requestMakeModelCategoryInProgress: false,
    requestMakeModelCategoryError: payload,
  };
};

const airplanesClearDataCase = () => {
  return {
    ...initialState,
  };
};

// ================ Selectors ================ //

export const $makeModelValue = state => {
  return state.AirplanesData.makeModel;
};

export const $requestMakeModelInProgress = state => {
  return state.AirplanesData.requestMakeModelInProgress;
};

export const $makeModelCategoryInProgress = state => {
  return state.AirplanesData.makeModelCategoryInProgress;
};

// ================ Action creators ================ //

export const airplanesMakeModelRequestAction = () => ({
  type: AIRPLANES_MAKE_MODEL_REQUEST,
});

export const airplanesMakeModelSuccessAction = value => ({
  type: AIRPLANES_MAKE_MODEL_SUCCESS,
  payload: value,
});

export const airplanesMakeModelErrorAction = error => ({
  type: AIRPLANES_MAKE_MODEL_ERROR,
  payload: error,
});

export const makeModelCategoryRequestAction = () => ({
  type: AIRPLANES_MAKE_MODEL_CATEGORY_REQUEST,
});

export const makeModelCategorySuccessAction = () => ({
  type: AIRPLANES_MAKE_MODEL_CATEGORY_SUCCESS,
});

export const makeModelCategoryErrorAction = error => ({
  type: AIRPLANES_MAKE_MODEL_CATEGORY_ERROR,
  payload: error,
});

export const airplanesClearDataAction = () => ({
  type: AIRPLANES_CLEAR_DATA,
});

// ================ Thunks ================ //

const isSearchValueValid = searchVal => {
  return searchVal && searchVal.length >= 3;
};

export const getMakeModelPromise = searchVal => dispatch => {
  if (!isSearchValueValid(searchVal)) {
    return Promise.resolve([]);
  }
  const url = new URL(`${apiBaseUrl()}/api/airplanes/make-model`);
  url.searchParams.append('val', searchVal);
  return window.fetch(url.toString())
    .then(handleJsonFetch)
    .then(res => res.data)
    .catch(e => console.log('MY ERROR: ', e))
  ;
};

export const getQuickSearchMakeModelPromise = searchVal => dispatch => {
  if (!isSearchValueValid(searchVal)) {
    return Promise.resolve([]);
  }
  return fetchQuickSearchMakeModelApi(searchVal)
    .then(handleAxiosResponse)
    .then(res => res.data)
    .catch(e => console.log('MY ERROR: ', e))
  ;
};

export const getMakersPromise = searchVal => {
  if (!isSearchValueValid(searchVal)) {
    return Promise.resolve([]);
  }
  const url = new URL(`${apiBaseUrl()}/api/airplanes/makers`);
  url.searchParams.append('val', searchVal);
  return window.fetch(url.toString())
    .then(handleJsonFetch)
    .then(res => res.data)
    .catch(e => console.log('MY ERROR: ', e))
  ;
};

export const getModelFamilyPromise = (searchVal, makerId, modelId) => {
  // if (!isSearchValueValid(searchVal) || !makerId) {
  //   return Promise.resolve([]);
  // }
  if (!makerId) {
    return Promise.resolve([]);
  }
  const url = new URL(`${apiBaseUrl()}/api/airplanes/model-family-by-maker`);
  url.searchParams.append('maker_id', makerId);
  // url.searchParams.append('val', searchVal);
  if (modelId) {
    url.searchParams.append('mi', modelId);
  }
  return window.fetch(url.toString())
    .then(handleJsonFetch)
    .then(res => res.data)
    .catch(e => console.log('MY ERROR: ', e))
  ;
};

export const getModelsPromise = (searchVal, makerId, modelFamily) => {
  // if (!isSearchValueValid(searchVal) || !makerId) {
  //   return Promise.resolve([]);
  // }
  if (!makerId) {
    return Promise.resolve([]);
  }
  const url = new URL(`${apiBaseUrl()}/api/airplanes/models-by-maker`);
  url.searchParams.append('maker_id', makerId);
  if (modelFamily) {
    url.searchParams.append('mf', modelFamily);
  }
  // url.searchParams.append('val', searchVal);
  return window.fetch(url.toString())
    .then(handleJsonFetch)
    .then(res => res.data)
    .catch(e => console.log('MY ERROR: ', e))
  ;
};

const fetchMakerAndModelAndCategoryByCanNames = (category, maker, modelFamily, model) => dispatch => {
  const url = new URL(`${config.apiEndpoint}/api/airplanes/maker-model-category-by-can-names`);
  if (maker) {
    url.searchParams.append('maker', maker);
  }
  if (model) {
    url.searchParams.append('model', model);
  }
  if (category) {
    url.searchParams.append('category', category);
  }
  if (modelFamily) {
    url.searchParams.append('model_family', modelFamily);
  }
  return axios.get(url).then(handleAxiosResponse);
};

const fetchMakerAndModelAndCategoryByIds = (categoryId, makerId, modelFamilyId, modelId) => dispatch => {
  const url = new URL(`${config.apiEndpoint}/api/airplanes/categories-maker-model-by-ids`);
  if (makerId) {
    url.searchParams.append('maker_id', makerId);
  }
  if (modelId) {
    url.searchParams.append('model_id', modelId);
  }
  if (categoryId) {
    url.searchParams.append('category_id', categoryId);
  }
  if (modelFamilyId) {
    url.searchParams.append('model_family_id', modelFamilyId);
  }
  return axios.get(url).then(handleAxiosResponse);
};

export const getMakerAndModelAndCategoryForSearchFormByIds = (categoryId, makerId, modelFamilyId, modelId) => dispatch => {
  if (!categoryId && !makerId && !modelFamilyId && !modelId) {
    return Promise.resolve();
  }
  dispatch(makeModelCategoryRequestAction());
  return dispatch(fetchMakerAndModelAndCategoryByIds(categoryId, makerId, modelFamilyId, modelId))
    .then(res => {
      dispatch(airplanesMakeModelSuccessAction());
      return get(res, 'data', null);
    })
    .catch(e => dispatch(makeModelCategoryErrorAction(e.message)))
    ;
};

export const getMakerAndModelAndCategoryForSearchFormByCanNames = (category, maker, modelFamily, model) => dispatch => {
  if (!category && !maker && !modelFamily && !model) {
    return Promise.resolve();
  }
  dispatch(makeModelCategoryRequestAction());
  return dispatch(fetchMakerAndModelAndCategoryByCanNames(category, maker, modelFamily, model))
    .then(res => {
      dispatch(airplanesMakeModelSuccessAction());
      return get(res, 'data', null);
    })
    .catch(e => dispatch(makeModelCategoryErrorAction(e.message)))
  ;
};

export const fetchMakeModelById = (listing) => {
  const makId = get(listing, 'attributes.publicData.make', null);
  const modId = get(listing, 'attributes.publicData.model', null);
  const catId = get(listing, 'attributes.publicData.category', null);
  if (!makId || !modId || !catId) {
    return Promise.reject(new Error('No provided data.'));
  }
  const url = new URL(`${apiBaseUrl()}/api/airplanes/make-model-by-id`);
  url.searchParams.append('modId', modId);
  url.searchParams.append('makId', makId);
  url.searchParams.append('catId', catId);
  return window.fetch(url.toString()).then(handleJsonFetch);
};

export const getMakeModelById = listing => dispatch => {
  dispatch(airplanesMakeModelRequestAction());
  fetchMakeModelById(listing)
    .then(res => dispatch(airplanesMakeModelSuccessAction(get(res, 'data[0]', null))))
    .catch(e => dispatch(airplanesMakeModelErrorAction(e.message)))
  ;
};

export const airplanesClearData = () => dispatch => {
  dispatch(airplanesClearDataAction());
};
