import { restApiService } from '../services/services';
import { storableError } from '../util/errors';

// ================ Action types ================ //

export const PUSH_SUBSCRIBE_REQUEST = 'app/Subscribe/PUSH_SUBSCRIBE_REQUEST';
export const PUSH_SUBSCRIBE_SUCCESS = 'app/Subscribe/PUSH_SUBSCRIBE_SUCCESS';
export const PUSH_SUBSCRIBE_ERROR = 'app/Subscribe/PUSH_SUBSCRIBE_ERROR';

// ================ Reducer ================ //

const initialState = {
  isPending: false,
  error: null,
};

export default function subscribeReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case PUSH_SUBSCRIBE_REQUEST:
      return {
        ...state,
        isPending: true,
        error: null,
      };

    case PUSH_SUBSCRIBE_SUCCESS:
      return {
        ...state,
        isPending: false,
      };

    case PUSH_SUBSCRIBE_ERROR:
      return {
        ...state,
        isPending: false,
        error: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const pushSubscribeRequest = () => ({
  type: PUSH_SUBSCRIBE_REQUEST,
});

const pushSubscribeSuccess = () => ({
  type: PUSH_SUBSCRIBE_SUCCESS,
});

const pushSubscribeError = error => ({
  type: PUSH_SUBSCRIBE_ERROR,
  payload: error,
});

// ================ Selectors ================ //

export const $subscribeState = state => {
  return state.Subscribe;
};

// ================ Thunks =================== //

export const pushSubscribe = email => dispatch => {
  dispatch(pushSubscribeRequest());
  return restApiService.publicInstance
    .postRaw('subscribe/push', { email })
    .then(resp => {
      dispatch(pushSubscribeSuccess());
      return resp;
    })
    .catch(e => {
      dispatch(pushSubscribeError(storableError(e)));
      throw e;
    })
  ;
};
