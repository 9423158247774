import get from 'lodash/get';

import { storableError } from '../../util/errors';
import { denormalisedResponseEntities } from '../../util/data';
import config from '../../config';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { fetchAirplanesMetadata } from '../../modules/airplanes/airplanes.creator';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages

// ================ Action types ================ //

export const FAVORITES_LISTINGS_REQUEST = 'app/FavoriteWritersPage/FAVORITES_LISTINGS_REQUEST';
export const FAVORITES_LISTINGS_SUCCESS = 'app/FavoriteWritersPage/FAVORITES_LISTINGS_SUCCESS';
export const FAVORITES_LISTINGS_ERROR = 'app/FavoriteWritersPage/FAVORITES_LISTINGS_ERROR';


// ================ Reducer ================ //

const initialState = {
  favoritesListings: null,
  favoritesListingsInProgress: true,
  favoritesListingsError: null,
  pagination: null,
};


const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FAVORITES_LISTINGS_REQUEST:
      return {
        ...state,
        favoritesListingsInProgress: true,
        favoritesListings: [],
        favoritesListingsError: null,
      };
    case FAVORITES_LISTINGS_SUCCESS:
      return {
        ...state,
        favoritesListings: payload?.listings || [],
        // pagination: payload.pagination,
        favoritesListingsInProgress: false,
      };
    case FAVORITES_LISTINGS_ERROR:
      console.error(payload);
      return {
        ...state,
        favoritesListingsInProgress: false,
        favoritesListingsError: payload
      };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const favoritesListingsRequest = () => ({
  type: FAVORITES_LISTINGS_REQUEST,
});

export const favoritesListingsSuccess = res => ({
  type: FAVORITES_LISTINGS_SUCCESS,
  payload: res,
});

export const favoritesListingsError = e => ({
  type: FAVORITES_LISTINGS_ERROR,
  error: true,
  payload: e,
});



// ================ Thunks ================ //


export const favoritesListings = () => (dispatch, getState, sdk) => {
  const { currentUser } = getState().user;
  const favoriteListings = get(currentUser, 'attributes.profile.protectedData.favoriteListings', [])
    .filter(i => !!i && i !== 'null')
  ;
  if (!favoriteListings) {
    dispatch(favoritesListingsSuccess(null));
    return;
  }
  dispatch(favoritesListingsRequest());
  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;
  const queryParams = {
    ids: favoriteListings,
    // page: page,
    page: 1,
    perPage: 100,
    include: ['author', 'images'],
    'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData.companyName'],
    'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
  };

  return sdk.listings.query(
    queryParams
  ).then(async res => {
    dispatch(fetchAirplanesMetadata(res.data.data));
    const paginationMeta = res?.data?.meta || {}
    const listings = denormalisedResponseEntities(res);

    const listingsPageData = {
      listings: listings,
      pagination: paginationMeta
    }

    dispatch(favoritesListingsSuccess(listingsPageData));
    return res;
  })
    .catch(e => {
      dispatch(favoritesListingsError(storableError(e)));
      throw e;
    });

};
