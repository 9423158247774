import pick from 'lodash/pick';
import get from 'lodash/get';
import TagManager from 'react-gtm-module';
import axios from 'axios';

import config from '../../config';
import { handleAxiosResponse } from '../../util/apiHelpers';
import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import { getStartOf, addTime } from '../../util/dates';
import { storableError } from '../../util/errors';
import {
  addMarketplaceEntities,
  getMarketplaceEntities,
  getOwnListingEntity,
  getListingEntity,
} from '../../ducks/marketplaceData.duck';
import { transactionLineItems, updateListingPageViewCount } from '../../util/api';
import * as log from '../../util/log';
import { denormalisedResponseEntities } from '../../util/data';
import { TRANSITION_ENQUIRE } from '../../util/transaction';
import * as enquiryProcessTransitions
  from '../../util/transactions/flex-enquiry-custom-process/transitions';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import { fetchUser } from '../../modules/user/user.creators';
import { fetchAirplanesMetadata } from '../../modules/airplanes/airplanes.creator';
import { newsService, localStorageService } from '../../services/services';
import { fetchListingsQueryParams } from '../../util/listing';
import { fetchSimilarListings } from '../../ducks/SimilarListings.duck';
import { addCounter } from '../../ducks/ListingCounters.duck';
import { isCrawler } from '../../util/userAgent';
// import { pricingAnalysisService } from '../../services/services';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_SUCCESS = 'app/ListingPage/SHOW_LISTING_SUCCESS';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';

export const FETCH_REVIEWS_REQUEST = 'app/ListingPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/ListingPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/ListingPage/FETCH_REVIEWS_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/ListingPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/ListingPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/ListingPage/FETCH_TIME_SLOTS_ERROR';

export const FETCH_LINE_ITEMS_REQUEST = 'app/ListingPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS = 'app/ListingPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/ListingPage/FETCH_LINE_ITEMS_ERROR';

export const SEND_ENQUIRY_REQUEST = 'app/ListingPage/SEND_ENQUIRY_REQUEST';
export const SEND_ENQUIRY_SUCCESS = 'app/ListingPage/SEND_ENQUIRY_SUCCESS';
export const SEND_ENQUIRY_ERROR = 'app/ListingPage/SEND_ENQUIRY_ERROR';

export const SEND_RE_ENQUIRY_REQUEST = 'app/ListingPage/SEND_RE_ENQUIRY_REQUEST';
export const SEND_RE_ENQUIRY_SUCCESS = 'app/ListingPage/SEND_RE_ENQUIRY_SUCCESS';
export const SEND_RE_ENQUIRY_ERROR = 'app/ListingPage/SEND_RE_ENQUIRY_ERROR';

const GET_MORE_LISTING_REQUEST = 'app/ListingPage/GET_MORE_LISTING_REQUEST';
const GET_MORE_LISTING_SUCCESS = 'app/ListingPage/GET_MORE_LISTING_SUCCESS';
const GET_MORE_LISTING_ERROR = 'app/ListingPage/GET_MORE_LISTING_ERROR';

const ZESTIMATE_LISTING_REQUEST = 'app/ListingPage/ZESTIMATE_LISTING_REQUEST';
const ZESTIMATE_LISTING_SUCCESS = 'app/ListingPage/ZESTIMATE_LISTING_SUCCESS';
const ZESTIMATE_LISTING_ERROR = 'app/ListingPage/ZESTIMATE_LISTING_ERROR';

// ================ Reducer ================ //

const initialState = {
  id: null,
  showListingInProgress: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  sendEnquiryInProgress: false,
  sendEnquiryError: null,
  enquiryModalOpenForListingId: null,
  sendReEnquiryInProgress: false,
  sendReEnquiryError: null,
  enquiryReModalOpenForListingId: null,
  getMoreListingsInProgress: false,
  getMoreListingsError: null,
  moreListingsIds: [],
  zestimateListingInProgress: false,
  zestimateListingError: null,
  zestimateListing: null,
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return { ...state, showListingInProgress: true, id: payload.id, showListingError: null };
    case SHOW_LISTING_SUCCESS:
      return { ...state, showListingInProgress: false };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingInProgress: false, showListingError: payload };

    case FETCH_REVIEWS_REQUEST:
      return { ...state, fetchReviewsError: null };
    case FETCH_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case FETCH_REVIEWS_ERROR:
      return { ...state, fetchReviewsError: payload };

    case FETCH_TIME_SLOTS_REQUEST:
      return { ...state, fetchTimeSlotsError: null };
    case FETCH_TIME_SLOTS_SUCCESS:
      return { ...state, timeSlots: payload };
    case FETCH_TIME_SLOTS_ERROR:
      return { ...state, fetchTimeSlotsError: payload };

    case FETCH_LINE_ITEMS_REQUEST:
      return { ...state, fetchLineItemsInProgress: true, fetchLineItemsError: null };
    case FETCH_LINE_ITEMS_SUCCESS:
      return { ...state, fetchLineItemsInProgress: false, lineItems: payload };
    case FETCH_LINE_ITEMS_ERROR:
      return { ...state, fetchLineItemsInProgress: false, fetchLineItemsError: payload };

    case SEND_ENQUIRY_REQUEST:
      return { ...state, sendEnquiryInProgress: true, sendEnquiryError: null };
    case SEND_ENQUIRY_SUCCESS:
      return { ...state, sendEnquiryInProgress: false };
    case SEND_ENQUIRY_ERROR:
      return { ...state, sendEnquiryInProgress: false, sendEnquiryError: payload };

    case SEND_RE_ENQUIRY_REQUEST:
      return { ...state, sendReEnquiryInProgress: true, sendReEnquiryError: null };
    case SEND_RE_ENQUIRY_SUCCESS:
      return { ...state, sendReEnquiryInProgress: false };
    case SEND_RE_ENQUIRY_ERROR:
      return { ...state, sendReEnquiryInProgress: false, sendReEnquiryError: payload };

    case GET_MORE_LISTING_REQUEST:
      return {
        ...state,
        getMoreListingsInProgress: true,
        getMoreListingsError: null
      };

    case GET_MORE_LISTING_SUCCESS:
      return {
        ...state,
        getMoreListingsInProgress: false,
        moreListingsIds: payload
      };

    case GET_MORE_LISTING_ERROR:
      return {
        ...state,
        getMoreListingsInProgress: false,
        getMoreListingsError: payload,
        moreListingsIds: []
      };

    case ZESTIMATE_LISTING_REQUEST:
      return {
        ...state,
        zestimateListingInProgress: true,
        zestimateListingError: null
      };

    case ZESTIMATE_LISTING_SUCCESS:
      return {
        ...state,
        zestimateListingInProgress: false,
        zestimateListing: payload
      };

    case ZESTIMATE_LISTING_ERROR:
      return {
        ...state,
        zestimateListingInProgress: false,
        zestimateListingError: payload,
        zestimateListing: null
      };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Selectors ====================== //

export const $listingId = state => {
  return state.ListingPage.id;
};

export const $moreListings = state => {
  const ids = state.ListingPage.moreListingsIds;
  const refs = ids.map(id => ({ type: 'listing', id: new UUID(id) }));
  return getMarketplaceEntities(state, refs);
};

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

const showListingSuccess = () => ({
  type: SHOW_LISTING_SUCCESS,
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
export const fetchReviewsSuccess = reviews => ({ type: FETCH_REVIEWS_SUCCESS, payload: reviews });
export const fetchReviewsError = error => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});

export const fetchTimeSlotsRequest = () => ({ type: FETCH_TIME_SLOTS_REQUEST });
export const fetchTimeSlotsSuccess = timeSlots => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: timeSlots,
});
export const fetchTimeSlotsError = error => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: error,
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});
export const fetchLineItemsError = error => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});

export const sendEnquiryRequest = () => ({ type: SEND_ENQUIRY_REQUEST });
export const sendEnquirySuccess = () => ({ type: SEND_ENQUIRY_SUCCESS });
export const sendEnquiryError = e => ({ type: SEND_ENQUIRY_ERROR, error: true, payload: e });

export const sendReEnquiryRequest = () => ({ type: SEND_RE_ENQUIRY_REQUEST });
export const sendReEnquirySuccess = () => ({ type: SEND_RE_ENQUIRY_SUCCESS });
export const sendReEnquiryError = e => ({ type: SEND_RE_ENQUIRY_ERROR, error: true, payload: e });

const getMoreListingsRequest = () => ({
  type: GET_MORE_LISTING_REQUEST,
});

const getMoreListingsSuccess = ids => ({
  type: GET_MORE_LISTING_SUCCESS,
  payload: ids,
});

const getMoreListingsError = errorMsg => ({
  type: GET_MORE_LISTING_ERROR,
  payload: errorMsg,
});

const getZestimateListingSuccess = payload => ({
  type: ZESTIMATE_LISTING_SUCCESS,
  payload: payload,
});

const getZestimateListingError = errorMsg => ({
  type: ZESTIMATE_LISTING_ERROR,
  payload: errorMsg,
});

const getZestimateListingRequest = () => ({
  type: ZESTIMATE_LISTING_REQUEST,
});

// ================ Thunks ================ //

const getViewedListingLocalStorageKey = lId => {
  return `viewedListing::${lId}`;
};

export const showListing = (listingId, isOwn = false) => async (dispatch, getState, sdk) => {
  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;

  dispatch(showListingRequest(listingId));
  dispatch(fetchCurrentUser());
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images', 'currentStock'],
    'fields.image': [
      // Scaled variants for large images
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Cropped variants for listing thumbnail images
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
      `variants.${variantPrefix}-4x`,
      `variants.${variantPrefix}-6x`,

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),
  };

  const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);
  return show
    .then(response => {
      const user = response.data.included.find(i => i.type === 'user' || i.type === 'currentUser');
      if (user) {
        dispatch(fetchUser(user.id));
      }
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchAirplanesMetadata([response.data.data]))
        .then(resp => {
          const metadata = get(resp, `data.data[${listingId.uuid}]`, null);
          if (metadata) {
            newsService.fetchNews(
              metadata.searchString,
              metadata.categoryName,
              metadata.makerName,
              metadata.modelFamilyName
            );
          }
        })
        .catch()
      ;
      dispatch(showListingSuccess());
      return response;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const fetchReviews = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchReviewsRequest());
  return sdk.reviews
    .query({
      listing_id: listingId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(fetchReviewsSuccess(reviews));
    })
    .catch(e => {
      dispatch(fetchReviewsError(storableError(e)));
    });
};

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then(response => {
    return denormalisedResponseEntities(response);
  });
};

export const fetchTimeSlots = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchTimeSlotsRequest);

  // Time slots can be fetched for 90 days at a time,
  // for at most 180 days from now. If max number of bookable
  // day exceeds 90, a second request is made.

  const maxTimeSlots = 90;
  // booking range: today + bookable days -1
  const bookingRange = config.dayCountAvailableForBooking - 1;
  const timeSlotsRange = Math.min(bookingRange, maxTimeSlots);

  const now = new Date();
  const start = getStartOf(now, 'day', 'Etc/UTC');
  const end = addTime(start, timeSlotsRange, 'days', 'Etc/UTC');
  const params = { listingId, start, end };

  return dispatch(timeSlotsRequest(params))
    .then(timeSlots => {
      const secondRequest = bookingRange > maxTimeSlots;

      if (secondRequest) {
        const secondRange = Math.min(maxTimeSlots, bookingRange - maxTimeSlots);
        const secondParams = {
          listingId,
          start: end,
          end: addTime(end, secondRange, 'days', 'Etc/UTC'),
        };

        return dispatch(timeSlotsRequest(secondParams)).then(secondBatch => {
          const combined = timeSlots.concat(secondBatch);
          dispatch(fetchTimeSlotsSuccess(combined));
        });
      } else {
        dispatch(fetchTimeSlotsSuccess(timeSlots));
      }
    })
    .catch(e => {
      dispatch(fetchTimeSlotsError(storableError(e)));
    });
};

export const sendEnquiry = (listingId, message) => (dispatch, getState, sdk) => {
  dispatch(sendEnquiryRequest());
  const bodyParams = {
    transition: TRANSITION_ENQUIRE,
    processAlias: config.transactionProcessAlias,
    params: { listingId },
  };
  return sdk.transactions
    .initiate(bodyParams)
    .then(response => {
      const transactionId = response.data.data.id;

      // Send the message to the created transaction
      return sdk.messages.send({ transactionId, content: message }).then(() => {
        dispatch(sendEnquirySuccess());
        dispatch(fetchCurrentUserHasOrdersSuccess(true));
        return transactionId;
      });
    })
    .catch(e => {
      dispatch(sendEnquiryError(storableError(e)));
      throw e;
    });
};

const sendToZohoEnquire = () => (dispatch, getState) => {
  const state = getState();
  const listingId = state.ListingPage.id;
  const currentListing = getOwnListingEntity(state)(listingId) || getListingEntity(state)(listingId);
  const currentUser = state.user.currentUser;
  const userId = get(currentUser, 'id.uuid', null);
  const lastName = get(currentUser, 'attributes.profile.lastName', '');
  const firstName = get(currentUser, 'attributes.profile.firstName', '');
  const email = get(currentUser, 'attributes.email', '');
  const phone = get(currentUser, 'attributes.profile.protectedData.phoneNumber', '');
  const accountType = get(currentUser, 'attributes.profile.publicData.userRole', null);

  const leads = [
    {
      Lead_Source: 'aircraftforsale.com',
      Last_Name: lastName,
      First_Name: firstName,
      Email: email,
      Phone: phone,
      Equip_Price: get(currentListing, 'attributes.price.amount', 0) / 100,
      New_Used: get(currentListing, 'attributes.publicData.condition', ''),
      Equip_Year: get(currentListing, 'attributes.publicData.year', '').toString(),
      Equip_Make: get(currentListing, 'attributes.publicData.maker_name', ''),
      Equip_Model: get(currentListing, 'attributes.publicData.model_name', ''),
      Equip_Type_Descript: get(currentListing, 'attributes.publicData.summary_desc', ''),
      Equipment_Location: get(currentListing, 'attributes.publicData.aircraft_loc.selectedPlace.address', ''),
      FAA_Registration_No: get(currentListing, 'attributes.publicData.reg_num', ''),
      Equip_Serial_No: get(currentListing, 'attributes.publicData.serial_num', ''),
      Equipment_Delivery: get(window, 'location.href', ''),
    },
  ];

  TagManager.dataLayer({
    dataLayer: {
      event: 'generate_lead',
      user_id: userId,
      user_logged_in: true,
      account_type: accountType,
      country_code: get(window, 'navigator.languages[0]', null),
      inquiry_type: 'email',
    },
  });

  return axios.post(`${config.apiEndpoint}/api/zoho/push-zoho-leads`, { leads })
};

export const sendReEnquiry = (listingId, message, isFinanceRequest, listingName) => (dispatch, getState, sdk) => {
  dispatch(sendEnquiryRequest());
  const bodyParams = {
    transition: enquiryProcessTransitions.TRANSITION_ENQUIRE,
    processAlias: config.transactionProcessAliasEnquiry,
    params: { listingId },
  };
  return sdk.transactions
    .initiate(bodyParams)
    .then(response => {
      if (isFinanceRequest) {
        dispatch(sendToZohoEnquire());
      }
      const transactionId = response.data.data.id;

      // Send the message to the created transaction
      return sdk.messages.send({ transactionId, content: message }).then(() => {
        dispatch(sendReEnquirySuccess());
        // dispatch(fetchCurrentUserHasOrdersSuccess(true));
        return transactionId;
      });
    })
    .catch(e => {
      dispatch(sendReEnquiryError(storableError(e)));
      throw e;
    })
  ;
};

export const fetchTransactionLineItems = ({ orderData, listingId, isOwnListing }) => dispatch => {
  dispatch(fetchLineItemsRequest());
  transactionLineItems({ orderData, listingId, isOwnListing })
    .then(response => {
      const lineItems = response.data;
      dispatch(fetchLineItemsSuccess(lineItems));
    })
    .catch(e => {
      dispatch(fetchLineItemsError(storableError(e)));
      log.error(e, 'fetching-line-items-failed', {
        listingId: listingId.uuid,
        orderData,
      });
    });
};

export const loadData = (params, search) => dispatch => {
  const listingId = new UUID(params.id);
  // pricingAnalysisService.getEstimateData(params.id);
  // Clear old line-items
  dispatch(setInitialValues({ lineItems: null }));

  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];
  if (ownListingVariants.includes(params.variant)) {
    return dispatch(showListing(listingId, true));
  }

  if (config.listingManagementType === 'availability') {
    return Promise.all([
      dispatch(showListing(listingId)),
      dispatch(fetchTimeSlots(listingId)),
      dispatch(fetchReviews(listingId)),
  ]);
  } else {
    return Promise.all([
      dispatch(showListing(listingId)),
      dispatch(fetchReviews(listingId)),
    ])
      .then(responses => {
        dispatch(getSimilarListings());
        // dispatch(fetchZestimateListing());


        const authorId = get(responses, '[0].data.data.relationships.author.data.id.uuid', null);
        if (authorId) {
          dispatch(getMoreListings(authorId));
        }
      })
    ;
  }
};

export const getMoreListings = authorId => (dispatch, getState, sdk) => {
  dispatch(getMoreListingsRequest());
  sdk.listings.query({
    authorId,
    page: 1,
    perPage: 4,
    ...fetchListingsQueryParams(),
  })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      const ids = response.data.data.map(l => l.id.uuid);
      dispatch(getMoreListingsSuccess(ids));
      dispatch(fetchAirplanesMetadata(response.data.data));
      return response;
    })
    .catch(e => {
      const errorMsg = e.message;
      dispatch(getMoreListingsError(errorMsg));
    })
  ;
};

export const getSimilarListings = () => (dispatch, getState, sdk) => {
  const state = getState();
  const listingId = state.ListingPage.id;

  const currentListing = getOwnListingEntity(state)(listingId)
    || getListingEntity(state)(listingId);

  const category = get(currentListing, 'attributes.publicData.category', null);
  const priceListing = get(currentListing, 'attributes.price.amount', null);
  const make = get(currentListing, 'attributes.publicData.make', null);
  const model = get(currentListing, 'attributes.publicData.model', null);
  const params = {
    l_i: listingId.uuid,
    pub_category: category,
    pub_make: make,
    pub_model: model,
    price: priceListing ?
      `${Math.round(priceListing * 0.7)},${Math.round(priceListing * 1.3)}`
      : null,
    ...fetchListingsQueryParams(),
  };
  return dispatch(fetchSimilarListings(params));
};

export const updateListingPageView = (lType, lId, authorId) => dispatch => {
  if (!isCrawler()) {
    const key = getViewedListingLocalStorageKey(lId);
    const isViewed = localStorageService.getItem(key);
    if (isViewed) {
      return ;
    }
    localStorageService.setItem(key, 'true');
    dispatch(addCounter(lType, 'views', lId, authorId));
  }
};

export const fetchZestimateListing = () => (dispatch, getState, sdk) => {
  dispatch(getZestimateListingRequest());

  const state = getState();
  const listingId = state.ListingPage.id;

  const currentListing = getOwnListingEntity(state)(listingId)
    || getListingEntity(state)(listingId);

  const address = get(currentListing, 'attributes.publicData.address_loc.selectedPlace.address', '');

  const url = `${config.zestimateEndpoint}&address=${'689 Diamond Falls Blvd Franklin, NC 28734'}`;

  return axios.get(url)
    .then(handleAxiosResponse)
    .then(zestimateListingResponse => {
      const res = zestimateListingResponse.bundle[0];
      dispatch(getZestimateListingSuccess(res));
    })
    .catch(e => {
      dispatch(getZestimateListingError(storableError(e)));
    });
};
