import React from 'react';

const GreatDealIcon = (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        width="18"
        height="18"
        fill="#2E9C2C"
        {...props}
      />
      <path
        d="M10.4999 13L7.49992 13L7.49992 9.00002L5.07992 9.00002L8.99992 5.08002L12.9199 9.00002L10.4999 9.00002L10.4999 13Z"
        fill={props.symbol || "white"}
      />
    </svg>
  );
};

export default GreatDealIcon;
