import {
  bool,
  func,
  shape,
} from 'prop-types';
import React, {
  useEffect,
  useState,
} from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import css from './SignUpDot.scss';

const SignUpDotComponent = (props) => {
  const {isAuthenticated, history, className} = props;

  const [visibleSignUpDot, setVisibleSignUpDot] = useState(null);

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.sessionStorage.setItem('visibleSignUpDot', true);
    });

    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    setVisibleSignUpDot(window.sessionStorage.getItem('visibleSignUpDot'));
  }, [])

  return !isAuthenticated && visibleSignUpDot ?
    <div className={classnames(className, {[css.signUpDot]: !className})} />
    : null;
}

SignUpDotComponent.propTypes = {
  isAuthenticated: bool.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  return { isAuthenticated };
};

const SignUpDot = compose(
  withRouter,
  connect(
    mapStateToProps
  )
)(SignUpDotComponent);

export default SignUpDot;
