import React from 'react';
import { connect } from 'react-redux';
import TagManager from 'react-gtm-module';
import config from '../../config/config';

import {
  getChatNotificationsCount,
  getCommentNotificationsCount,
} from '../../modules/notifications/notifications.creator';

export const useEffects = props => {
  const {
    currentUser,
    onGetChatNotificationsCount,
    onGetCommentNotificationsCount,
  } = props;

  React.useEffect(() => {
    if (currentUser && currentUser.id.uuid) {
      onGetChatNotificationsCount(currentUser.id.uuid).catch(() => {});
      // onGetCommentNotificationsCount(currentUser.id.uuid)
    }
  }, [currentUser]);

  React.useEffect(() => {
    if (window && window.document && config.enabledGTMTracking && config.gtmKey) {
      const tagManagerArgs = {
        gtmId: config.gtmKey,
      };
      TagManager.initialize(tagManagerArgs);
    }
  }, []);
};

const mapStateToProps = state => {
  return {
    currentUser: state.user.currentUser,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetChatNotificationsCount: userId => dispatch(getChatNotificationsCount(userId)),
    // onGetCommentNotificationsCount: userId => dispatch(getCommentNotificationsCount(userId)),
  };
};

export const connector = connect(
  mapStateToProps,
  mapDispatchToProps,
);
