import axios from 'axios';

import {
  apiEndpoint,
  handleAxiosResponse,
} from '../../util/apiHelpers';

export const fetchUserApi = id => {
  return axios.get(`${apiEndpoint}/users/${id}`)
    .then(handleAxiosResponse);
};
