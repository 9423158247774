import React, { Component } from 'react';
import { compose } from 'redux';
import get from 'lodash/get';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { getAircraftListingRoute } from '../../routing/routeHelpers';
import { LISTING_TYPE__AIRPLANES } from '../../util/editListingHelpers';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';

import {
  AspectRatioWrapper,
  NamedLink,
  ResponsiveImage,
  AddFavorites,
  IconClose,
} from '../../components';
import PriceDifferenceVariant from '../PriceDifferenceVariant/PriceDifferenceVariant';
import DealBlock from '../DealBlock/DealBlock';
import { isAircraftListingType, isRealEstateListingType } from '../../util/listing';

import css from './ListingCard.module.scss';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

const ListingCardComponent = props => {
  const {
    airplaneMetadata,
    className,
    rootClassName,
    rootClassNameForImage,
    rootClassNameForAspectWrapper,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    isPremium,
    currentUser,
    deleteFromFavorites,
    showDeleteIcon,
    onManageDisableScrolling,
    onCloseCardClick,
    priceChanged,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const companyName = author?.attributes?.profile?.publicData?.companyName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const priceHistory = publicData.price_history;

  const { aspectWidth = 1.5, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const priceDataValue = priceData(price, intl);
  const { priceTitle } = priceDataValue;
  const formattedPrice = priceDataValue.formattedPrice && priceDataValue.formattedPrice.split('.')[0];

  const unitType = config.lineItemUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;


  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
      ? 'ListingCard.perDay'
      : 'ListingCard.perUnit';

  const setActivePropsMaybe = setActiveListing
    ? {
      onMouseEnter: () => setActiveListing(currentListing.id),
      onMouseLeave: () => setActiveListing(null),
    }
    : null;

  const year = publicData?.year;
  const category = get(airplaneMetadata, 'categoryName', '');
  let namedLinkName;
  let namedLinkParams;
  if (isAircraftListingType(currentListing)) {
    const aircraftRoute = getAircraftListingRoute(airplaneMetadata, listing.id.uuid);
    namedLinkName = aircraftRoute.namedLinkName;
    namedLinkParams = aircraftRoute.namedLinkParams;
  }
  if (isRealEstateListingType(currentListing)) {
    namedLinkName = 'RealEstateListingPage';
    namedLinkParams = { id: listing.id.uuid };
  }

  const isZeroPrice = price?.amount === 0;

  const handleCloseClick = () => {
    if (onCloseCardClick) {
      onCloseCardClick();
    }
  };
  const closeIcon = onCloseCardClick ? (
    <div className={css.closeIcon} onClick={handleCloseClick}>
      <IconClose />
    </div>
  ) : null;

  const estimate_publishable = get(publicData, 'estimate_data.estimate_publishable', null);
  const estimate_evaluation_text = get(publicData, 'estimate_data.estimate_evaluation_text')
  const aircraftListingType = get(publicData, 'aircraft_listing_type', null);
  // TODO: Hide PlanePrice label
  const showDealBlock = false; // estimate_publishable === true && aircraftListingType === 'For Sale';
  const listingType = get(publicData, 'listingType', LISTING_TYPE__AIRPLANES);
  const isFeatured = get(currentListing, 'attributes.publicData.featured', false) === true;

  return (
    <div className={classNames(classes, {
      [css.isFeatured]: isFeatured,
    })}>
      {isFeatured && (
        <div className={css.featureLabel}>
          Featured
        </div>
      )}
      {closeIcon}

      <AddFavorites
        listing={currentListing}
        className={css.addToFavorites}
        deleteFromFavorites={deleteFromFavorites}
        showDeleteIcon={showDeleteIcon}
        onManageDisableScrolling={onManageDisableScrolling}
      />

      <NamedLink
        className={css.rootLink}
        name={namedLinkName}
        params={namedLinkParams}
      >
        {isPremium && (
          <span className={css.premiumBadge}>
            <FormattedMessage id="ListingCard.premium" />
          </span>
        )}
        <AspectRatioWrapper
          className={classNames(css.aspectRatioWrapper, rootClassNameForAspectWrapper)}
          width={aspectWidth}
          height={aspectHeight}
          {...setActivePropsMaybe}
        >
          <LazyImage
            rootClassName={classNames(css.rootForImage, rootClassNameForImage)}
            alt={title}
            image={firstImage}
            variants={variants}
            sizes={renderSizes}
          />
        </AspectRatioWrapper>
        <div className={css.info}>
          <div className={css.mainInfo}>
            {category && (
              <div className={css.category}>
                {category}
              </div>
            )}

            <div className={css.title}>
              {!!year && year} {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </div>

            {/* <div className={css.company}>
              {companyName || authorName}
            </div> */}
          </div>

          <div className={css.price}>
            <PriceDifferenceVariant
              priceHistory={priceHistory}
              formattedPrice={formattedPrice}
              listingType={listingType}
              isZeroPrice={isZeroPrice}
              priceChanged={priceChanged}
              priceTitle={priceTitle}
            />
            {!isZeroPrice && config.listing.showUnitTypeTranslations ? (
              <div className={css.perUnit}>
                <FormattedMessage id={unitTranslationKey} />
              </div>
            ) : null}
          </div>

          {showDealBlock && (
            <DealBlock
              rootClassName={css.dealBlock}
              estimate_evaluation_text={estimate_evaluation_text}
            />
          )}
        </div>
      </NamedLink>
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

const ListingCard = compose(
  injectIntl
)(ListingCardComponent);

export default ListingCard;
