import {
  txLastTransition,
  getTransitionsToState,
  getAllTransitions,
} from '../helpers';
import * as States from './states';
import { stateDescription } from './stateDescription';

export const txIsEnquire = tx =>
  getTransitionsToState(stateDescription)(States.STATE_ENQUIRY).includes(txLastTransition(tx));

export const txIsCompletedEnquire = tx =>
  getTransitionsToState(stateDescription)(States.STATE_COMPLETED).includes(txLastTransition(tx));

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantTransition = transition => {
  return [
    States.STATE_ENQUIRY,
    States.STATE_COMPLETED,
  ].includes(transition);
};

export const TRANSITIONS = getAllTransitions(stateDescription);
