import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';

import { signModalService } from '../../services/services';
import { setFavoriteListingIdToUserProfile } from '../../ducks/user.duck';
import { LISTING_TYPE__AIRPLANES } from '../../util/editListingHelpers';

import AddFavoritesButton from './AddFavoritesButton';

const AddFavoritesComponent = props => {
  const {
    listing,
    isAuthenticated,
    currentUser,
    onUpdateProfile,
    deleteFromFavorites,
    favoritesListingsInProgress,
    className,
    showText,
  } = props;

  const listingId = listing?.id?.uuid;
  const favoriteListings = currentUser?.attributes?.profile?.protectedData?.favoriteListings || [];
  const isFavorite = favoriteListings.includes(listingId);
  const listingType = get(listing, 'attributes.publicData.listingType', LISTING_TYPE__AIRPLANES);
  const authorId = get(listing, 'author.id.uuid', null);

  const setListingIdToProfile = () => {
    if (favoritesListingsInProgress) {
      return;
    }
    onUpdateProfile(listingType, authorId, listingId);
  };

  const handleClick = () => {
    if (!isAuthenticated) {
      signModalService.openSignContent({
        signModalParamsAddToFavorites: listingId,
        signModalParamsListingType: listingType,
        signModalParamsAuthorId: authorId,
      });
    } else {
      if (deleteFromFavorites) {
        deleteFromFavorites();
      } else {
        setListingIdToProfile();
      }
    }
  };

  return (
    <AddFavoritesButton
      isFavorite={isFavorite}
      showText={showText}
      className={className}
      onClick={handleClick}
    />
  );

};

const mapStateToProps = state => {
  const {
    currentUser,
    favoritesListingsInProgress
  } = state.user;

  return {
    isAuthenticated: state.Auth.isAuthenticated,
    currentUser,
    favoritesListingsInProgress
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateProfile: (lType, authId, lId) => dispatch(setFavoriteListingIdToUserProfile(lType, authId, lId)),
});

const AddFavorites = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AddFavoritesComponent);

export default AddFavorites;
