import React from 'react';

import css from './ProgressDots.module.scss';

const ProgressDots = () => {
  return (
    <div className={css['dot-flashing']} />
  );
};

export default ProgressDots;
