import { BaseReducer } from 'redux-di-container';

export class PlanePriceLandingPageService extends BaseReducer {
  constructor (opts) {
    super();
    this.greatGoodDealsService = opts.greatGoodDealsService;

  }

  getInitialState() {
    return {}
  }



  loadData = () => async () => {
    this.dispatch(this.greatGoodDealsService.fetchDealsCount());
    this.dispatch(this.greatGoodDealsService.fetchGreatGoodDealListings());
  }
}
