import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.module.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      {/* <p className={css.lastUpdated}>Last updated: July, 1st 2021</p> */}

      <p>
        Flying Media Group and its affiliates (collectively, the “Company”) provide service to you subject to the following 
        Terms of Service, which may be updated by the Company from time to time without prior notice to you. Your use of 
        the FLYING Sites (as defined below) constitutes your agreement to these Terms of Service. The right to use the FLYING Sites, 
        or any product or service offered by the Company, is personal to you and is not transferable to any other person or entity. 
        The Company may, in its sole discretion, modify or revise these Terms of Service and policies at any time, and you agree 
        to be bound by such modifications or revisions. Nothing in these Terms of Service shall be deemed to confer any third-party 
        rights or benefits.
      </p>

      <h2>
        1. Definitions.
      </h2>
      <p>
        The “FLYING Sites” shall mean all areas and any subscription or other paid products and services offered or operated by 
        the Company on the World Wide Web or via any other electronic delivery mechanism. The FLYING Sites consist of information 
        services and content provided by the Company, affiliates of the Company, and third parties. The term “Community Areas” 
        means the bulletin boards, chat rooms, content sharing, and other user participatory areas on the FLYING Sites, and areas 
        dedicated to FLYING products and services on third-party sites, such as Facebook and Twitter. Your use of FLYING areas on 
        third-party sites, while subject to these Terms, is also subject to any terms and conditions imposed by those sites.
      </p>

      <h2>
        2. General.
      </h2>
      <p>
        The Company shall have the right at any time to change or discontinue any aspect or feature of the Company Sites 
        including, but not limited to, the Community Areas, content, hours of availability, and equipment needed for 
        access or use, without prior notice to you.
      </p>


      <h2>
        3. Use of the FLYING Sites and the Community Areas.
      </h2>
      <p>
        a. The Community Areas may be used for lawful purposes only. No material shall be posted on or transmitted through 
        the Community Areas which violates or infringes in any way upon the rights of others; which is unlawful, threatening, 
        abusive, defamatory, invasive of privacy or publicity rights, vulgar, obscene, profane, indecent or otherwise 
        objectionable; or which encourages conduct that would constitute a criminal offense, gives rise to civil liability or 
        otherwise violates any law. You may not upload, post or otherwise make available on the Community Areas any material 
        protected by copyright, trademark, or other proprietary rights without the express permission of the owner of the 
        copyright, trademark, or other proprietary rights. You are responsible for determining that any material you upload 
        or contribute is not protected by copyright, trademark, or other proprietary rights. You shall be solely liable for 
        any damages resulting from any infringement of copyright, trademark, or other proprietary rights, or any other harm 
        resulting from any uploading, posting, or submission. No user may engage in conduct that, in the Company’s judgment, restricts or 
        inhibits any other user from using or enjoying the Community Areas. Advertising or commercial solicitation may not be posted on or 
        transmitted through the Community Areas without the Company’s express written approval.
      </p>
      <p>
        b. The FLYING Sites and the Community Areas contain copyrighted material, trademarks, and other proprietary information 
        including text, software, photos, video, graphics, music, and sound, and the entire contents of the FLYING Sites are 
        copyrighted as a collective work under the United States copyright laws. The Company is the owner of the copyright in 
        all the FLYING Sites. The Company owns a copyright in the selection, coordination, arrangement, and enhancement of such 
        content, as well as in the content original to it. Each third-party content provider owns the copyright in content original 
        to it. Except as otherwise expressly permitted under copyright law, you may not copy, redistribute, modify, adapt, publish, 
        display or commercially exploit any material from the FLYING Sites without the express permission of the Company and the 
        copyright owner. In the event of any permitted copying, redistribution, or publication of material from the FLYING Sites, 
        no changes in or deletion of author attribution, trademark, legend, or copyright notice are permitted.
      </p>
      <p>
        c. You hereby grant to the Company and its affiliates the worldwide, royalty-free, perpetual, irrevocable, non-exclusive 
        right and license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, perform 
        and display any message posted in the Community Areas and/or any e-mail sent by you to the Company (in whole or in part), 
        and/or to incorporate them in other works in any form, media or technology now known or later developed.
      </p>
      <p>
        d. You may not (i) select or use a member name or e-mail address of another person with the intention of impersonating 
        that person; (ii) use a member name or e-mail address of anyone else without authorization; (iii) use a member name in 
        violation of the intellectual property rights of any person; or (iv) use a member name that the Company considers to be offensive.
      </p>
      <p>
        e. You shall provide the Company with accurate, complete, and updated information at the time of registration.
      </p>
      <p>
        f. You shall not engage in any activity which is contrary to or would adversely affect the purpose or intention of the 
        FLYING Sites, including but not limited to, actually or attempting to manipulate, corrupt or otherwise affect the outcome 
        of the Sites’ services, in whole or in part, by, among other methods, registering multiple accounts under the same or different names.
      </p>
      <p>
        g. The FLYING Sites contain links to other websites, resources, and advertisers. The Company is not responsible for 
        the availability of these external sites nor does it endorse or is it responsible for the content, advertising, 
        products, or other materials made available on or through such external sites. Under no circumstances shall the 
        Company be held responsible or liable, directly or indirectly, for any loss or damage caused or alleged to have 
        been caused to a user in connection with the use of or reliance on any content, goods, or services available on 
        such external site. You should direct any concerns to such an external site’s administrator or webmaster.
      </p>
      <p>
        h. You shall be responsible for obtaining and maintaining all telecommunications, computer hardware, and other 
        equipment needed for access to and use of the FLYING Sites and Community Areas, and all charges related thereto.
      </p>
      <p>
        i. The foregoing provisions of this Section are for the benefit of the Company, its affiliates, third-party content 
        providers/licensors, and each shall have the right to assert and enforce such provisions directly on its own behalf.
      </p>
      <p>
        j. The Company has carefully designed the FLYING Sites with the purpose of delivering certain content to users in a 
        particular format and with a particular appearance. No third party shall have the right to utilize the content of the 
        FLYING Sites in any way that interferes with that purpose. In particular, the Company prohibits any party from displaying 
        the content on the Company Sites in any format where third party advertising or other materials which the Company did not 
        authorize are viewed or viewable together with the Company.s proprietary content.
      </p>


      <h2>
        4. Monitoring.
      </h2>
      <p>
        The Company shall have the right, but not the obligation, to monitor the content of the Community Areas to determine 
        compliance with this Agreement and any other operating rules that may be established by the Company from time to time. 
        The Company shall have the right in its sole discretion to edit, refuse to post, or remove any material submitted to or 
        posted on the Community Areas that the Company, in its sole discretion, finds to be in violation of the provisions hereof, 
        otherwise objectionable or stale. Notwithstanding this right of the Company, users shall remain solely responsible for the 
        content of their messages. You acknowledge and agree that neither the Company nor any of its affiliates shall assume or have 
        any liability for any action or inaction by the Company with respect to any conduct within the Community Areas or any 
        communication or posting on the Community Areas.
      </p>


      <h2>
        5. Disclaimer of Warranty; Limitation of Liability.
      </h2>
      <p>
        a. YOU EXPRESSLY AGREE THAT USE OF THE COMMUNITY AREAS AND THE COMPANY SITES IS AT YOUR SOLE RISK. NEITHER THE COMPANY, ITS 
        AFFILIATES NOR ANY OF THEIR RESPECTIVE EMPLOYEES, AGENTS, THIRD-PARTY CONTENT PROVIDERS, OR LICENSORS WARRANT THAT THE COMPANY 
        SITES WILL BE UNINTERRUPTED OR ERROR-FREE; NOR DO THEY MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE 
        COMPANY SITES OR AS TO THE ACCURACY, RELIABILITY OR CONTENT OF ANY INFORMATION, SERVICE OR PRODUCTS PROVIDED THROUGH THE SITES 
        OR THE COMMUNITY AREAS.
      </p>
      <p>
        b. THE COMPANY SITES AND THE COMMUNITY AREAS ARE PROVIDED ON AN “AS IS,” “AS AVAILABLE” BASIS WITHOUT WARRANTIES OF ANY KIND, 
        EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF TITLE OR IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS 
        FOR A PARTICULAR PURPOSE, OTHER THAN THOSE WARRANTIES WHICH ARE IMPLIED BY AND INCAPABLE OF EXCLUSION, RESTRICTION OR MODIFICATION 
        UNDER THE LAWS APPLICABLE TO THIS AGREEMENT.
      </p>
      <p>
        c. THE DISCLAIMERS OF LIABILITY CONTAINED IN THIS SECTION APPLY TO ANY DAMAGES OR INJURY CAUSED BY ANY FAILURE OF PERFORMANCE, 
        ERROR, OMISSION, INTERRUPTION, DELETION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS, COMMUNICATION LINE FAILURE, 
        THEFT OR DESTRUCTION OR UNAUTHORIZED ACCESS TO, ALTERATION OF, OR USE OF RECORD, WHETHER FOR BREACH OF CONTRACT, TORTIOUS BEHAVIOR, 
        NEGLIGENCE, OR UNDER ANY OTHER CAUSE OF ACTION. YOU SPECIFICALLY ACKNOWLEDGE THAT THE COMPANY IS NOT LIABLE FOR THE DEFAMATORY, 
        OFFENSIVE, OR ILLEGAL CONDUCT OF OTHER USERS OR THIRD PARTIES AND THAT THE RISK OF INJURY FROM THE FOREGOING RESTS ENTIRELY WITH YOU.
      </p>
      <p>
        d. IN NO EVENT WILL THE COMPANY OR ANY PERSON OR ENTITY INVOLVED IN CREATING, PRODUCING, OR DISTRIBUTING THE COMPANY SITES BE 
        LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF THE USE OF OR INABILITY TO USE 
        THE COMPANY SITES OR OUT OF THE BREACH OF ANY WARRANTY. YOU HEREBY ACKNOWLEDGE THAT THE PROVISIONS OF THIS SECTION 5 SHALL 
        APPLY TO ALL CONTENT ON THE COMPANY SITES AND/OR THE COMMUNITY AREAS. THE COMPANY.S LIABILITY TO USERS, IF ANY, SHALL IN NO 
        EVENT EXCEED THE TOTAL AMOUNT PAID TO THE COMPANY.
      </p>
      <p>
        e. THE COMPANY NEITHER ENDORSES NOR IS RESPONSIBLE FOR THE ACCURACY OR RELIABILITY OF ANY OPINION, ADVICE, OR STATEMENT 
        ON THE COMPANY SITES NOR FOR ANY OFFENSIVE, DEFAMATORY OR OBSCENE POSTING MADE ON THE COMMUNITY AREAS BY ANYONE OTHER 
        THAN AUTHORIZED THE COMPANY EMPLOYEE SPOKESPERSONS WHILE ACTING IN THEIR OFFICIAL CAPACITIES. UNDER NO CIRCUMSTANCES 
        WILL THE COMPANY BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY YOUR RELIANCE ON INFORMATION OBTAINED THROUGH THE CONTENT ON 
        THE COMPANY SITES AND/OR ANY POSTINGS ON THE COMMUNITY AREAS. IT IS YOUR RESPONSIBILITY TO EVALUATE THE ACCURACY, COMPLETENESS, 
        OR USEFULNESS OF ANY INFORMATION, OPINION, ADVICE, OR OTHER CONTENT AVAILABLE THROUGH THE COMPANY SITES AND/OR THE COMMUNITY 
        AREAS. PLEASE SEEK THE ADVICE OF PROFESSIONALS, AS APPROPRIATE, REGARDING THE EVALUATION OF ANY SPECIFIC INFORMATION, OPINION, 
        ADVICE, OR OTHER CONTENT, INCLUDING BUT NOT LIMITED TO FINANCIAL, HEALTH, OR LIFESTYLE INFORMATION, OPINION, ADVICE, OR OTHER CONTENT.
      </p>
      <p>
        f. THE COMPANY DOES NOT ENDORSE, WARRANT, OR GUARANTEE ANY PRODUCTS OR SERVICES OFFERED THROUGH THE COMPANY SITES AND 
        WILL NOT BE A PARTY TO OR IN ANY WAY MONITOR ANY TRANSACTION BETWEEN USERS AND THIRD-PARTY PROVIDERS OF PRODUCTS OR 
        SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR 
        BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE. THE COMPANY MAKES PRODUCTS OR SERVICES AVAILABLE ON THE COMPANY 
        SITES WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF TITLE OR 
        IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, OTHER THAN THOSE WARRANTIES WHICH ARE IMPLIED 
        BY AND INCAPABLE OF EXCLUSION, RESTRICTION OR MODIFICATION UNDER THE LAWS APPLICABLE TO THIS AGREEMENT.
      </p>
      <p>
        g. PRIOR TO THE EXECUTION OF A PURCHASE OR SALE OF ANY SECURITY OR INVESTMENT, YOU ARE ADVISED TO CONSULT WITH YOUR 
        BROKER OR OTHER FINANCIAL ADVISOR TO VERIFY PRICING AND OTHER INFORMATION. NEITHER THE COMPANY NOR ITS THIRD-PARTY 
        CONTENT PROVIDERS SHALL HAVE ANY LIABILITY FOR INVESTMENT DECISIONS BASED UPON, OR THE RESULTS OBTAINED FROM, THE 
        CONTENT PROVIDED HEREIN. NEITHER THE COMPANY NOR ITS THIRD-PARTY CONTENT PROVIDERS GUARANTEE OR WARRANT THE TIMELINESS, 
        SEQUENCE, ACCURACY, OR COMPLETENESS OF ANY SUCH INFORMATION. NOTHING CONTAINED IN THE COMPANY SITES SHALL BE CONSTRUED 
        AS INVESTMENT ADVICE. THE COMPANY IS NOT A REGISTERED BROKER-DEALER OR INVESTMENT ADVISOR AND DOES NOT GIVE INVESTMENT 
        ADVICE OR RECOMMEND ONE PRODUCT OVER ANOTHER.
      </p>


      <h2>
        6. Indemnification.
      </h2>
      <p>
        You agree to defend, indemnify and hold harmless The Company, its affiliates, and their respective directors, officers, 
        employees, and agents from and against all claims and expenses, including attorneys. fees, arising out of the use by 
        you of the FLYING Sites and/or the Community Areas.
      </p>

      <h2>
        7. Termination.
      </h2>
      <p>
        The Company shall have the right to terminate your right to use the FLYING Sites or the Community Areas if the 
        Company, in its sole discretion, considers you to be engaged in unacceptable conduct, or in the event of your breach of this Agreement.
      </p>

      <h2>
        8. Trademarks.
      </h2>
      <p>
        All trademarks appearing on the FLYING Sites are the property of their respective owners, including 
        trademarks owned by the Company.
      </p>


      <h2>
        9. Subscription Services.
      </h2>
      <p>
        The Company makes available to users certain online subscription services and other paid services and products. 
        The following terms and conditions shall apply in the event that you subscribe to any paid subscription service 
        or services offered by the Company on the FLYING Sites (the “Subscription”):
      </p>
      <p>
        a. Subscription Terms. The Subscription will continue until the Company receives the notification of termination 
        from you as described below. You authorize the Company to charge to the credit card account designated during 
        the registration process the current fees and charges for each term according to the subscription plan chosen 
        by you. If you accepted an offer that included a free trial period, your credit card account will not be 
        charged until after the end of the free trial period. If you ordered in response to a free trial period offer, 
        you may cancel the subscription process and avoid a charge to your credit card account by following the directions 
        accompanying the trial offer. If you subscribed for a term of one (1) year or more, you will be notified by the 
        Company before the account designated by you is charged after the first term.
      </p>
      <p>
        b. Modification of Terms. The Company shall have the right at any time to impose, change or modify its fees 
        and billing methods, or other terms and conditions applicable to your use of the Subscription or to impose new 
        terms and conditions. Such changes, modifications, additions, or deletions shall be effective thirty (30) days 
        after notice thereof, which may be given by means including, but not limited to, posting on the FLYING Sites a 
        revised version of this Agreement or notification by electronic or conventional mail. If any such change is 
        unacceptable to you, you may terminate your Subscription as provided in Subsection (c) below. Any use of the 
        Subscription by you after the change in terms is effective shall conclusively be deemed to constitute acceptance 
        by you of such changes, modifications, additions, or deletions. You agree to review the terms and conditions 
        periodically to be aware of such revisions. You may also be subject to additional terms and conditions imposed 
        by third-party content providers in connection with third-party content, software, or services.
      </p>
      <p>
        c. Termination. Either you or the Company may terminate the Subscription Agreement at any time. Your only remedy 
        with respect to any dissatisfaction with the terms of the Subscription, the content available through the Subscription, 
        or the amount or type of fees or billing methods, is to terminate this Agreement by sending a notice to the Company 
        at the customer service email address found under “Contact Us” and “For questions regarding your subscription.” Notice of 
        termination will be effective upon receipt by the Company. In the event that your account is terminated or canceled, 
        no refund of any fees for services already provided, including a monthly membership fee, will be granted.
      </p>


      <h2>
        10. Miscellaneous.
      </h2>
      <p>
        This Agreement and any operating rules for the FLYING Sites and the Community Areas established by the Company 
        constitute the entire agreement of the parties with respect to the subject matter hereof and supersede all 
        previous written or oral agreements between the parties with respect to such subject matter. This Agreement 
        shall be construed in accordance with the laws of the State of Tennessee, without regard to its conflict of 
        laws rules. No waiver by either party of any breach or default hereunder shall be deemed to be a waiver of any 
        preceding or subsequent breach or default. The section headings used herein are for convenience only and shall 
        not be given any legal import.
      </p>
      <p>
        ACCOUNT-HOLDERS WHO POST MATERIAL DEPICTING VIOLENCE, PORNOGRAPHY, CRUELTY, OR CRIMINAL CONDUCT WILL HAVE THEIR 
        ACCOUNTS TERMINATED IMMEDIATELY, WITHOUT NOTICE. IF ANY OF THE MATERIAL VIOLATES ANY STATE OR FEDERAL LAWS, THE 
        ACCOUNT HOLDER WILL BE REPORTED TO THE PROPER AUTHORITIES.
      </p>
      <p>
        If you believe that any material posted on any FLYING Sites violates your copyright, trademark, or other intellectual 
        property rights, please e-mail <a href="mailto:editor@flying.media">editor@flying.media</a>
      </p>
    </div>

  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
