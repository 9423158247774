import axios from 'axios';

import config from '../../../config';
import { handleAxiosResponse } from '../../../util/apiHelpers';
import { PER_PAGE } from './usersPage.constants';

export const fetchUsersApi = (page = 1, perPage = PER_PAGE) => {
  const url = new URL(`${config.apiEndpoint}/api/users`);
  url.searchParams.append('page', page.toString());
  url.searchParams.append('perPage', perPage.toString());

  return axios.get(url).then(handleAxiosResponse);
};
