import omit from 'lodash/omit';

import {
  addMarketplaceEntities,
} from '../../ducks/marketplaceData.duck.js';
import { storableError } from '../../util/errors';
import { denormalisedResponseEntities } from '../../util/data';
import { fetchAirplanesMetadata } from '../../modules/airplanes/airplanes.creator';
import {
  FILTER_KEY_PRICE_CHANGED,
} from '../SearchPage/SearchPage.constants.js';
import {
  getImagesParams,
} from '../../util/listing';
import {
  featuredListingsForLandingPage,
} from '../../ducks/FeaturedListings.ducs';
import {
  LISTING_TYPE__REAL_ESTATE,
} from '../../util/editListingHelpers';
import { restApiService } from '../../services/services';

const RESULT_PAGE_SIZE = 4;

// ================ Action types ================ //

export const FETCH_CHANGE_PRICE_LISTINGS_REQUEST = 'app/LandingPage/FETCH_CHANGE_PRICE_LISTINGS_REQUEST';
export const FETCH_CHANGE_PRICE_LISTINGS_SUCCESS = 'app/LandingPage/FETCH_CHANGE_PRICE_LISTINGS_SUCCESS';
export const FETCH_CHANGE_PRICE_LISTINGS_ERROR = 'app/LandingPage/FETCH_CHANGE_PRICE_LISTINGS_ERROR';

export const FETCH_NEWEST_RE_LISTINGS = 'app/LandingPage/FETCH_NEWEST_RE_LISTINGS';
export const FETCH_NEWEST_RE_LISTINGS_SUCCESS = 'app/LandingPage/FETCH_NEWEST_RE_LISTINGS_SUCCESS';
export const FETCH_NEWEST_RE_LISTINGS_ERROR = 'app/LandingPage/FETCH_NEWEST_RE_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchChangePriceListingsInProgress: false,
  fetchChangePriceListingsError: null,
  changePriceListingsIds: [],
  newestREListingIds: [],
  newestREIsPending: false,
  newestREError: null,
};

export default function landingPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_CHANGE_PRICE_LISTINGS_REQUEST:
      return {
        ...state,
        fetchChangePriceListingsInProgress: true,
        fetchChangePriceListingsError: null,
      };

    case FETCH_CHANGE_PRICE_LISTINGS_SUCCESS: {
      return {
        ...state,
        fetchChangePriceListingsInProgress: false,
        changePriceListingsIds: payload,
      };
    }

    case FETCH_CHANGE_PRICE_LISTINGS_ERROR:
      return {
        ...state,
        fetchChangePriceListingsInProgress: false,
        fetchChangePriceListingsError: payload,
      };

    case FETCH_NEWEST_RE_LISTINGS:
      return {
        ...state,
        newestREIsPending: true,
        newestREError: null,
      };

    case FETCH_NEWEST_RE_LISTINGS_SUCCESS: {
      return {
        ...state,
        newestREIsPending: false,
        newestREListingIds: payload,
      };
    }

    case FETCH_NEWEST_RE_LISTINGS_ERROR:
      return {
        ...state,
        newestREIsPending: false,
        newestREError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchChangePriceListingsRequest = () => ({
  type: FETCH_CHANGE_PRICE_LISTINGS_REQUEST,
});

const fetchChangePriceListingsSuccess = response => ({
  type: FETCH_CHANGE_PRICE_LISTINGS_SUCCESS,
  payload: response,
});

const fetchChangePriceListingsError = e => ({
  type: FETCH_CHANGE_PRICE_LISTINGS_ERROR,
  payload: e,
});

const fetchNewestREListingsRequest = () => ({
  type: FETCH_NEWEST_RE_LISTINGS,
});

const fetchNewestREListingsSuccess = ids => ({
  type: FETCH_NEWEST_RE_LISTINGS_SUCCESS,
  payload: ids,
});

const fetchNewestREListingsError = error => ({
  type: FETCH_NEWEST_RE_LISTINGS_ERROR,
  payload: error,
});

// ================ Selectors =============== //



// ================ Thunks ================ //

export const fetchChangePriceListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(fetchChangePriceListingsRequest());

  const params = {
    ...searchParams,
    per_page: RESULT_PAGE_SIZE,
    [FILTER_KEY_PRICE_CHANGED]: ['increase', 'drops'],
    price: '1,',
    sort: 'pub_price_changed_date',
  };

  return sdk.listings
    .query(params)
    .then(async response => {
      const listings = denormalisedResponseEntities(response);
      const listingsIds = listings.map(listing => listing.id)
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchAirplanesMetadata(response.data.data));
      dispatch(fetchChangePriceListingsSuccess(listingsIds));
      return response;
    })
    .catch(e => {
      dispatch(fetchChangePriceListingsError(storableError(e)));
      throw e;
    });
};

const fetchNewestREListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(fetchNewestREListingsRequest());

  const params = {
    ...searchParams,
    pub_listingType: LISTING_TYPE__REAL_ESTATE,
    per_page: RESULT_PAGE_SIZE,
    page: 1,
  };

  return sdk.listings.query(params)
    .then(async response => {
      dispatch(addMarketplaceEntities(response));
      const listingsIds = response.data.data.map(listing => listing.id);
      dispatch(fetchNewestREListingsSuccess(listingsIds));
      return response;
    })
    .catch(e => {
      dispatch(fetchNewestREListingsError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search) => dispatch => {
  return Promise.all([
    dispatch(fetchChangePriceListings({
      include: ['images'],
      ...getImagesParams(),
    })),
    dispatch(featuredListingsForLandingPage()),
    dispatch(fetchNewestREListings({
      include: ['images'],
      ...getImagesParams(),
    })),
  ])
};
