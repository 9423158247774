import moment from 'moment';
import get from 'lodash/get';

import { signModalService } from '../services/services';
import config from '../config';
import { createImageVariantConfig } from './sdkLoader';
import { findConfigForSelectFilter } from './search.js';
import { LISTING_TYPE__AIRPLANES, LISTING_TYPE__REAL_ESTATE } from './editListingHelpers';

export const DATE_TIMEZONE = "America/New_York";
export const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const NUMBER_FORMAT = '0,0';
const LISTING_VIEW_SESSION_COUNT = 'view_listing_count';
const LISTING_VIEW_SHOULD_OPEN = 'view_listing_should_open';

const getListingViewSessionCount = () => {
  return window.sessionStorage.getItem(LISTING_VIEW_SESSION_COUNT) || 0;
};

const setListingViewSessionCount = count => {
  window.sessionStorage.setItem(LISTING_VIEW_SESSION_COUNT, count.toString());
};

export const opeViewCountSignTab = () => {
  const shouldOpen = window.sessionStorage.getItem(LISTING_VIEW_SHOULD_OPEN) || 'true';
  if (shouldOpen === 'false') {
    return ;
  }
  const viewCount = getListingViewSessionCount();
  const newViewCount = Number(viewCount) + 1;
  setListingViewSessionCount(newViewCount);
  if (newViewCount > 1) {
    signModalService.openSignTab();
  }
};

export const setShouldOpenToSession = () => {
  window.sessionStorage.setItem(LISTING_VIEW_SHOULD_OPEN, 'false');
};

export const removeListingViewSessionCount = () => {
  window.sessionStorage.removeItem(LISTING_VIEW_SESSION_COUNT);
};

export const percentageDifference = (firstNum, secondNum) => Math.round(
  firstNum === 0
    ? secondNum
    : ((secondNum - firstNum) / (firstNum)) * 100);

export const percentageDifferenceToFixed = (firstNum, secondNum) => Number((
  firstNum === 0
    ? secondNum
    : ((secondNum - firstNum) / (firstNum)) * 100
).toFixed(1));

export const percentageDifferenceToFixedCondition = (firstNum, secondNum) => Number((
  firstNum === 0
    ? secondNum
    : ((secondNum - firstNum) / (firstNum)) * 100
).toFixed(3));

export const getPrice = (firstPrice, lastPrice) => {
  if(!firstPrice || !lastPrice) {
    return {
      percents: null,
      priceChangeUp: false
    }
  }

  const percentsDifferenceCondition = percentageDifferenceToFixedCondition(firstPrice, lastPrice);

  const percentsDifference =
    ((percentsDifferenceCondition > 0 && percentsDifferenceCondition <= 0.1) && 0.1)
    || ((percentsDifferenceCondition >= -0.1 && percentsDifferenceCondition < 0) && -0.1)
    || percentageDifferenceToFixed(firstPrice, lastPrice);

  const positivePercentsDifference = percentsDifference < 0
    ? percentsDifference * -1
    : percentsDifference;

  const percents = positivePercentsDifference < 1
    ? percentsDifference
    : percentageDifference(firstPrice, lastPrice);

  const priceChangeUp = percentsDifferenceCondition > 0;

  return {
    percents,
    priceChangeUp
  }
}

export const getHistoryPriceDate = () => {
  return `${moment().tz("America/New_York").format(DATE_FORMAT)}`;
};

export const getImagesParams = () => {
  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;

  return {
    'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
  }
};

export const getEmailingDate = () => {
  return moment().add(3, 'M').valueOf();
};

export const isUUID = value => {
  return !!value.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i);
};

export const getFieldMultiOptionsSchemaType = (filterId, filterConfig) => {
  const config = findConfigForSelectFilter(filterId, filterConfig);
  const schemaType = config ? config.schemaType : null;
  const options = config && config.options
    ? config.options.map(i => ({ value: i.key, label: i.label }))
    : []
  ;

  return {
    options,
    schemaType,
  }
}

export const getFieldOptionsSchemaType = (filterId, filterConfig) => {
  const config = findConfigForSelectFilter(filterId, filterConfig);
  const schemaType = config ? config.schemaType : null;
  const options = config.options ? config.options : [];

  return {
    options,
    schemaType,
  }
}

const getListingType = listing => get(listing, 'attributes.publicData.listingType', null);

export const isAircraftListingType = listing => getListingType(listing) === LISTING_TYPE__AIRPLANES;

export const isRealEstateListingType = listing => getListingType(listing) === LISTING_TYPE__REAL_ESTATE;

export const sortByDate = (arr, format = 'l', field = 'date') => {
  return arr.sort((a, b) => {
    const aDate = moment(a[field], format).valueOf();
    const bDate = moment(b[field], format).valueOf();
    return bDate - aDate;
  });
}

export const fetchListingsQueryParams = () => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card'
  } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;

  return {
    pub_show_in_search: 'yes',
    include: ['author', 'images'],
    'fields.listing': [
      'title',
      'geolocation',
      'price',
      'publicData',
    ],
    'fields.user': [
      'profile.displayName',
      'profile.abbreviatedName',
      'profile.publicData',
      'profile.metadata',
    ],
    'fields.image': [
      // Scaled variants for large images
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Cropped variants for listing thumbnail images
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
      `variants.${variantPrefix}-4x`,
      `variants.${variantPrefix}-6x`,
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
  };
};

export const  getEstimatedPayment = (purchasePrice, downPaymentAmount, term, interestRate) => {
  const amount = purchasePrice - downPaymentAmount;
  const months = term * 12;
  const monthRate = interestRate / (100 * 12);
  return (amount * monthRate * Math.pow(1 + monthRate, months))
    / (Math.pow(1 + monthRate, months) -1);
}

export const getIsPistonEngine = metadata => {
  const canCategory = get(metadata, 'can_category', null);
  return ['single-engine-piston', 'multi-engine-piston'].includes(canCategory);
};

export const getEngHrsObjs = publicData => {
  const engHrsKeys = Object.keys(publicData)
    .map(key => {
      if (key.startsWith('eng') && key.endsWith('_hrs')) {
        return key.split('_')[0];
      }
      return null;
    })
    .filter(i => !!i)
  ;
  return engHrsKeys.map(i => {
    const since = publicData[`${i}_since`];
    return {
      hrs: publicData[`${i}_hrs`],
      since,
    };
  }).filter(i => !!i);
};
