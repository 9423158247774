import React from 'react';
import PriceDifferenceRealEstate
  from '../../containers/ListingPage/RealEstateListingPage/RealEstateListingPageContent/PriceDifferenceRealEstate/PriceDifferenceRealEstate';
import { LISTING_TYPE__REAL_ESTATE } from '../../util/editListingHelpers';
import PriceDifference from '../ListingCard/PriceDifference';

const PriceDifferenceVariant = (props) => {
  const {
    listingType,
    priceHistory,
    formattedPrice,
    priceTitle,
  } = props;

  const lastItem = priceHistory[priceHistory.length - 1];

  return listingType === LISTING_TYPE__REAL_ESTATE
    ? <PriceDifferenceRealEstate
        formattedPrice={formattedPrice}
        lastItem={lastItem}
      />
    : <PriceDifference
        formattedPrice={formattedPrice}
        priceTitle={priceTitle}
        lastItem={lastItem}
      />
}

export default PriceDifferenceVariant;
