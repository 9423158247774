import React from 'react';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';

import usePrevious from '../../../hooks/usePrevious';
import IconSearchDesktop from './IconSearchDesktop';

const SingleValue = (props) => {
  const {
    data,
    isFocused,
  } = props;
  if (isFocused) {
    return <React.Fragment></React.Fragment>;
  }

  return (
    <components.SingleValue
      {...props}
    >
      {data.rawLabel}
    </components.SingleValue>
  );
};

const Option = (props) => {
  const {
    data,
  } = props;
  const label = data.label;
  return (
    <components.Option
      {...props}
    >
      {data.group ? (
        <span
          dangerouslySetInnerHTML={{ __html: label }}
        />
      ) : (
        <span
          style={{
            paddingLeft: '20px',
          }}
          dangerouslySetInnerHTML={{ __html: label }}
        />
      )}
    </components.Option>
  );
};

const Menu = (props) => {
  if (!props.options.length) {
    return <React.Fragment></React.Fragment>;
  }
  return (
    <components.Menu
      {...props}
    >
      {props.children}
    </components.Menu>
  );
};

const SearchAsyncSelect = (props) => {
  const {
    applyFocusStyles,
    isHeaderSearch,
    isShowIcon,
    isMobile,
    shouldClearQuickSearch,
    keywordSearchWrapperClasses,
    iconClassName,
    iconColorClassName,
    initialValue,
    onGetMakeModelPromise,
    onChange,
    isTransparentBg,
    isTopbarScrolled,
    placeholder,
    menuStyles,
  } = props;

  const [isFocused, setIsFocused] = React.useState(false);
  const ref = React.useRef();
  const prevShouldClearQuickSearch = usePrevious(shouldClearQuickSearch);
  React.useEffect(() => {
    if (!prevShouldClearQuickSearch && shouldClearQuickSearch && ref.current) {
      ref.current.clearValue();
    }
  }, [shouldClearQuickSearch]);

  const promiseOptions = val => {
    if (val.length < 3) {
      return Promise.resolve([]);
    }
    return onGetMakeModelPromise(val);
  };

  const handleChange = data => {
    if (onChange) {
      onChange(data);
    }
  };

  const handleFocus = data => {
    setIsFocused(true);
  };

  const handleBlur = data => {
    setIsFocused(false);
  };

  return (
    <div
      className={keywordSearchWrapperClasses}
      style={{
        // marginRight: '48px',
        ...(isFocused && applyFocusStyles && { border: '1px solid #E32922' }),
      }}
    >
      {isShowIcon && (
        <IconSearchDesktop
          className={iconClassName}
          colorClassName={iconColorClassName}
          isTransparentBg={isTransparentBg}
          isTopbarScrolled={isTopbarScrolled}
        />
      )}
      <AsyncSelect
        ref={ref}
        onChange={handleChange}
        placeholder={placeholder}
        loadOptions={promiseOptions}
        // defaultInputValue={initialValue}
        blurInputOnSelect
        onFocus={handleFocus}
        onBlur={handleBlur}
        styles={{
          ...(isHeaderSearch ? {
            container: () => ({
              display: 'flex',
              width: '90%',
              ...(isMobile && {
                flexGrow: 1,
                height: 'var(--TopbarSearchForm_inputHeight)',
                lineHeight: 'inherit',
                margin: '0 24px 0 24px',
                padding: '4px 13px 10px',
                border: '1px solid var(--marketplaceColorLight)',
                borderRadius: 0,
                borderBottomWidth: 'var(--borderRadiusMobileSearch)',
              }),
            }),
          } : {
            container: (base) => ({
              ...base,
              ...(!isMobile && {
                top: '-22px',
                left: '20px',
              }),
            }),
          }),
          control: () => ({
            display: 'flex',
            border: 'none',
            width: '99%',
          }),
          placeholder: () => ({
            gridArea: '1/1/2/3',
            color: '#b6b6b6',
            marginLeft: '3px',
            marginRight: '2px',
            boxSizing: 'border-box',
            fontWeight: '400',
          }),
          ...(!isMobile && {
            menu: (base) => ({
              ...base,
              ...(menuStyles ? menuStyles : {
                width: 'calc(100% - 50px)',
                left: '25px',
              }),

            })
          }),
        }}
        components={{
          SingleValue: props => (
            <SingleValue
              {...props}
              isFocused={isFocused}
            />
          ),
          Option,
          DropdownIndicator: null,
          Menu,
        }}
      />
    </div>
  );
};

SearchAsyncSelect.defaultProps = {
  isShowIcon: true,
  isHeaderSearch: true,
  applyFocusStyles: false,
};

export default SearchAsyncSelect;
