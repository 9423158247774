import get from 'lodash/get';

import { createResourceLocatorString } from './routes';
import { routeConfiguration } from '../index';

export const LISTING_TYPE__AIRPLANES = 'aircraft';
export const LISTING_TYPE__REAL_ESTATE = 'real';
export const LISTING_TYPE__PRODUCTS = 'prod';
export const LISTING_TYPE_URL__AIRPLANES = 'aircraft';
export const LISTING_TYPE_URL__REAL_ESTATE = 'real_estate';
export const LISTING_TYPES = [
  LISTING_TYPE__AIRPLANES,
  LISTING_TYPE__REAL_ESTATE,
  LISTING_TYPE__PRODUCTS,
];

export const DRAFT_ID = '00000000-0000-0000-0000-000000000000';
export const DRAFT_SLUG = 'draft';

export const AIRPLANES_TAB__SUMMARY = `${LISTING_TYPE__AIRPLANES}_summary`;
export const AIRPLANES_TAB__AIRFRAME_PROPULSION = `${LISTING_TYPE__AIRPLANES}_airframe_propulsion`;
export const AIRPLANES_TAB__AVIONICS = `${LISTING_TYPE__AIRPLANES}_avionics`;
export const AIRPLANES_TAB__INTERIOR_EXTERIOR = `${LISTING_TYPE__AIRPLANES}_interior_exterior`;
export const AIRPLANES_TAB__MAINTENANCE = `${LISTING_TYPE__AIRPLANES}_maintenance`;
export const AIRPLANES_TAB__PHOTOS_VIDEOS = `${LISTING_TYPE__AIRPLANES}_photos_videos`;
export const AIRPLANES_TAB__PREVIEW = `${LISTING_TYPE__AIRPLANES}_preview`;

export const REAL_ESTATE_TAB__SUMMARY = `${LISTING_TYPE__REAL_ESTATE}_summary`;
export const REAL_ESTATE_TAB__ROOM_DETAILS = `${LISTING_TYPE__REAL_ESTATE}_room_details`;
export const REAL_ESTATE_TAB__BUILDING_DETAILS =  `${LISTING_TYPE__REAL_ESTATE}_building_details`;
export const REAL_ESTATE_TAB__AMENITIES =  `${LISTING_TYPE__REAL_ESTATE}_amenities`;
export const REAL_ESTATE_TAB__PHOTOS_VIDEOS =  `${LISTING_TYPE__REAL_ESTATE}_photos_videos`;


export const PRODUCTS_TAB__SUMMARY = `${LISTING_TYPE__PRODUCTS}_summary`;

export const TABS = [
  AIRPLANES_TAB__SUMMARY,
  AIRPLANES_TAB__AIRFRAME_PROPULSION,
  AIRPLANES_TAB__AVIONICS,
  AIRPLANES_TAB__INTERIOR_EXTERIOR,
  AIRPLANES_TAB__MAINTENANCE,
  AIRPLANES_TAB__PHOTOS_VIDEOS,
  AIRPLANES_TAB__PREVIEW,
  REAL_ESTATE_TAB__SUMMARY,
  REAL_ESTATE_TAB__ROOM_DETAILS,
  REAL_ESTATE_TAB__BUILDING_DETAILS,
  PRODUCTS_TAB__SUMMARY,
  REAL_ESTATE_TAB__AMENITIES,
  REAL_ESTATE_TAB__PHOTOS_VIDEOS,
];

export const TABS_OBJ = {
  [LISTING_TYPE__AIRPLANES]: [
    AIRPLANES_TAB__SUMMARY,
    AIRPLANES_TAB__AIRFRAME_PROPULSION,
    AIRPLANES_TAB__AVIONICS,
    AIRPLANES_TAB__INTERIOR_EXTERIOR,
    AIRPLANES_TAB__MAINTENANCE,
    AIRPLANES_TAB__PHOTOS_VIDEOS,
    AIRPLANES_TAB__PREVIEW,
  ],
  [LISTING_TYPE__REAL_ESTATE]: [
    REAL_ESTATE_TAB__SUMMARY,
    REAL_ESTATE_TAB__ROOM_DETAILS,
    REAL_ESTATE_TAB__BUILDING_DETAILS,
    REAL_ESTATE_TAB__AMENITIES,
    REAL_ESTATE_TAB__PHOTOS_VIDEOS,
  ],
  [LISTING_TYPE__PRODUCTS]: [
    PRODUCTS_TAB__SUMMARY,
  ],
};

export const CURRENCY_OPTIONS = [
  { key: 'usd', label: 'USD' },
  { key: 'eu', label: 'EU' },
];

export const ENGINE_SINCE_OPTIONS = [
  { key: 'SFOH', label: 'SFOH' },
  { key: 'SFRM', label: 'SFRM' },
  { key: 'SHS', label: 'SHS' },
  { key: 'SMOH', label: 'SMOH' },
  { key: 'SNEW', label: 'SNEW' },
];

export const DEFAULT_CURRENCY = CURRENCY_OPTIONS[0].key;

export const DEFAULT_ENGINE_SINCE = ENGINE_SINCE_OPTIONS[0].key;

export const FORM_DATE_FORMAT = 'LL';
export const INSPECT_EXPIRY_YEAR = 'YYYY';

export const ACCEPT_IMAGES = 'image/*';

export const LOT_SIZE_UNIT_DIMENSIONS_OPTIONS = [
  { key: 'sqFt', label: 'Sq Ft' },
  { key: 'acres', label: 'Acres' },
]

export const MONTHLY_HOA_DUES_TERM_OPTIONS = [
  { key: 'monthly', label: 'Monthly' },
  { key: 'quarterly', label: 'Quarterly' },
  { key: 'yearly', label: 'Yearly' },
]

export const getCreateTypedListingPath = (type, tab) => {
  return createResourceLocatorString(
    'EditListingPage',
    routeConfiguration(),
    {
      slug: DRAFT_SLUG,
      id: DRAFT_ID,
      type: 'new',
      tab,
      listingType: type,
    }
  );
};

export const getListingType = listing => {
  const type = get(listing, 'attributes.publicData.listingType', LISTING_TYPE__AIRPLANES);
  if (type.startsWith('air')) {
    return LISTING_TYPE__AIRPLANES;
  } else if(type.startsWith('real')){
    return LISTING_TYPE__REAL_ESTATE;
  } else {
    return type;
  }
};

export const getFirstTabByListingType = listingType => {
  return (TABS_OBJ[listingType] || [])[0];
};

export const getLastTabByListingType = listingType => {
  const tabs = TABS_OBJ[listingType];
  return tabs[tabs.length - 1];
};

export const lotSizeAcresInSqFt = (lotSize) => lotSize ? lotSize / 43560 : null ;

export const lotSizeSqFtInAcres = (lotSize) => lotSize ? lotSize * 43560 : null;

export const titleTransform = str => str.replace(/(^\w|\s\w)(\S*)/g, (_,m1,m2) => m1.toUpperCase()+m2.toLowerCase());
