import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaYoutube.module.css';

const IconSocialMediaYoutube = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="14"
      fill="none"
      viewBox="0 0 20 14"
    >
      <path
        fill="#E32922"
        d="M19.162 2.362A2.413 2.413 0 0017.461.658C15.958.254 9.938.254 9.938.254s-6.02 0-7.522.402A2.409 2.409 0 00.716 2.359C.314 3.863.314 7 .314 7s0 3.137.402 4.639c.22.829.874 1.482 1.701 1.703 1.502.404 7.522.404 7.522.404s6.02 0 7.521-.404a2.409 2.409 0 001.702-1.704c.402-1.501.402-4.638.402-4.638s0-3.137-.402-4.638zM8.027 9.879V4.12L13.01 6.98l-4.984 2.9z"
      ></path>
    </svg>
  );
};

IconSocialMediaYoutube.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaYoutube.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaYoutube;
