import { fieldsClose, fieldSet, fieldsOpen } from 'simple-redux-field';

import {
  FIELD__USERS_PAGE__USERS,
  INITIAL_USERS,
  NAMESPACE, PER_PAGE,
} from './usersPage.constants';
import { fetchUsersApi } from './usersPage.api';

export const openFields = () => dispatch => {
  dispatch(fieldsOpen([
    { key: FIELD__USERS_PAGE__USERS, value: INITIAL_USERS },
  ], NAMESPACE));
};

export const closeFields = () => dispatch => {
  dispatch(fieldsClose([
    FIELD__USERS_PAGE__USERS,
  ]));
};

export const loadData = (params, search) => dispatch => {
  const searchParams = new URLSearchParams(search);
  const page = searchParams.get('page') || 1;
  const perPage = searchParams.get('perPage') || PER_PAGE;
  dispatch(openFields());
  dispatch(fieldSet(FIELD__USERS_PAGE__USERS, { inProgress: true, error: false }, NAMESPACE));
  return fetchUsersApi(page, perPage)
    .then(response => {
      dispatch(fieldSet(
        FIELD__USERS_PAGE__USERS,
        { inProgress: false, users: response.users, pagination: response.meta },
        NAMESPACE
      ));
        return response;
    })
    .catch(e => {
      dispatch(fieldSet(
        FIELD__USERS_PAGE__USERS,
        { inProgress: false, error: e.message },
        NAMESPACE
      ));
      throw e;
    })
  ;
};
