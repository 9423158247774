import get from 'lodash/get';

/**
 * Get listing route.
 * @param metadata
 * @param listingId
 * @returns {{namedLinkParams: {modelFamily, maker, model, id, category}, namedLinkName: string}}
 */
export const getAircraftListingRoute = (metadata, listingId) => {
  const canCategory = get(metadata, 'can_category', null);
  const canMaker = get(metadata, 'can_maker', null);
  const canModelFamily = get(metadata, 'can_model_family', null);
  const canModel = get(metadata, 'can_model', null);
  let namedLinkParams;
  let namedLinkName;
  if (canCategory && canMaker && canModelFamily && canModel) {
    namedLinkName = 'AircraftListingPageCanonical';
    namedLinkParams = {
      category: canCategory,
      maker: canMaker,
      modelFamily: canModelFamily,
      model: canModel,
      id: listingId,
    };
  } else {
    namedLinkName = 'AircraftListingPage';
    namedLinkParams = {
      id: listingId,
    };
  }
  return {
    namedLinkName,
    namedLinkParams,
  };
};
