import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import pickBy from 'lodash/pickBy';
import classNames from 'classnames';

import config from '../../config';
import SignUpDot from '../../containers/SignUpDot/SignUpDot';
import routeConfiguration from '../../routing/routeConfiguration';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { isMainSearchTypeKeywords, isOriginInUse } from '../../util/search';
import { withViewport } from '../../util/contextHelpers';
import { parse, stringify } from '../../util/urlHelpers';
import { createResourceLocatorString } from '../../util/routes';
import { propTypes } from '../../util/types';
import {
  Button,
  LimitedAccessBanner,
  Logo,
  Modal,
  // ModalMissingInformation,
  NamedLink,
} from '../../components';

import MenuIcon from './MenuIcon';
import SearchIcon from './SearchIcon';
import MobileTopbarSearchForm from './TopbarSearchForm/MobileTopbarSearchForm';
import TopbarMobileMenu from './TopbarMobileMenu/TopbarMobileMenu';
import TopbarDesktop from './TopbarDesktop/TopbarDesktop';

import css from './Topbar.module.scss';

const MAX_MOBILE_SCREEN_WIDTH = 768;

const redirectToURLWithModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const searchString = `?${stringify({ [modalStateParam]: 'open', ...parse(search) })}`;
  history.push(`${pathname}${searchString}`, state);
};

const redirectToURLWithoutModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const queryParams = pickBy(parse(search), (v, k) => {
    return k !== modalStateParam;
  });
  const stringified = stringify(queryParams);
  const searchString = stringified ? `?${stringified}` : '';
  history.push(`${pathname}${searchString}`, state);
};

const GenericError = props => {
  const { show } = props;
  const classes = classNames(css.genericError, {
    [css.genericErrorVisible]: show,
  });
  return (
    <div className={classes}>
      <div className={css.genericErrorContent}>
        <p className={css.genericErrorText}>
          <FormattedMessage id="Topbar.genericError" />
        </p>
      </div>
    </div>
  );
};

const { bool } = PropTypes;

GenericError.propTypes = {
  show: bool.isRequired,
};

class TopbarComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isTopbarScrolled: false,
      isTopbarChangedAfterLoad: false,
    };


    this.handleMobileMenuOpen = this.handleMobileMenuOpen.bind(this);
    this.handleMobileMenuClose = this.handleMobileMenuClose.bind(this);
    this.handleMobileSearchOpen = this.handleMobileSearchOpen.bind(this);
    this.handleMobileSearchClose = this.handleMobileSearchClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.someScrollFromTop = this.someScrollFromTop.bind(this);
  }

  someScrollFromTop() {
    // 83 - current topbar height
    if (!!window && window?.pageYOffset > 83) {
      this.setState({ isTopbarScrolled: true });
      this.setState({ isTopbarChangedAfterLoad: true });
    } else {
      this.setState({ isTopbarScrolled: false });
    }
  }

  componentDidMount() {
    document.addEventListener('scroll', () => this.someScrollFromTop());
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', () => this.someScrollFromTop());
  }

  handleMobileMenuOpen() {
    redirectToURLWithModalState(this.props, 'mobilemenu');
  }

  handleMobileMenuClose() {
    redirectToURLWithoutModalState(this.props, 'mobilemenu');
  }

  handleMobileSearchOpen() {
    // redirectToURLWithModalState(this.props, 'mobilesearch');
    this.props.onToggleMobileSearchForm();
  }

  handleMobileSearchClose() {
    redirectToURLWithoutModalState(this.props, 'mobilesearch');
  }

  handleSubmit(values) {
    const { currentSearchParams } = this.props;
    const { history } = this.props;

    const topbarSearchParams = () => {
      if (isMainSearchTypeKeywords(config)) {
        return values;
      }
      // topbar search defaults to 'location' search
      const { search, selectedPlace } = values?.location;
      const { origin, bounds } = selectedPlace;
      const originMaybe = isOriginInUse(config) ? { origin } : {};

      return {
        ...originMaybe,
        address: search,
        bounds,
      };
    };
    const searchParams = {
      // ...currentSearchParams,
      ...topbarSearchParams(),
    };
    history.push(createResourceLocatorString(
      'AircraftSearchPage',
      routeConfiguration(),
      {},
      searchParams
    ));
  }

  handleLogout() {
    const { onLogout } = this.props;
    onLogout().then(() => {
      if (typeof window !== 'undefined') {
        window.location.reload();
      }
      console.log('logged out'); // eslint-disable-line
    });
  }

  render() {
    const {
      isMobileSearchFormVisible,
      searchFormIconClassName,
      isVisibleSearchForm,
      shouldClearQuickSearch,
      onGetMakeModelPromise,
      className,
      rootClassName,
      desktopClassName,
      mobileRootClassName,
      mobileClassName,
      isAuthenticated,
      authScopes,
      authInProgress,
      currentUser,
      currentUserHasListings,
      currentUserHasAirListings,
      currentUserHasOrders,
      currentPage,
      notificationCount,
      viewport,
      intl,
      location,
      onManageDisableScrolling,
      onResendVerificationEmail,
      sendVerificationEmailInProgress,
      sendVerificationEmailError,
      showGenericError,
      transparentBg,
      commentNotificationsCountField,
    } = this.props;

    const {
      mobilemenu,
      mobilesearch,
      pub_keywords,
      keywords,
      address,
      origin,
      bounds,
    } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    const isMobileMenuOpen = isMobileLayout && mobilemenu === 'open';
    const isMobileSearchOpen = isMobileLayout && mobilesearch === 'open';

    const mobileMenu = (
      <TopbarMobileMenu
        isAuthenticated={isAuthenticated}
        currentUserHasListings={currentUserHasListings}
        currentUser={currentUser}
        onLogout={this.handleLogout}
        notificationCount={notificationCount}
        currentPage={currentPage}
        commentNotificationsCountField={commentNotificationsCountField}
      />
    );

    const topbarSearcInitialValues = () => {
      if (isMainSearchTypeKeywords(config)) {
        return {
          pub_keywords,
          keywords,
        };
      }

      // Only render current search if full place object is available in the URL params
      const locationFieldsPresent = isOriginInUse(config)
        ? address && origin && bounds
        : address && bounds;
      return {
        location: locationFieldsPresent
          ? {
            search: address,
            selectedPlace: { address, origin, bounds },
          }
          : null,
      };
    };
    const initialSearchFormValues = topbarSearcInitialValues();

    const classes = classNames(
      rootClassName || css.root,
      className,
      { [css.rootScrolled]: this.state.isTopbarScrolled },
      { [css.rootChangedAfterLoad]: this.state.isTopbarChangedAfterLoad },
      { [css.rootTransparent]: transparentBg }
    );

    const url = <NamedLink
      name='PlanePriceLandingPage'
    >
      {intl.formatMessage({
        id: isMobileLayout ? 'Topbar.new.mobileLink' : 'Topbar.new.desktopLink',
      })}
    </NamedLink>
    // TODO: Hide PlanePrice label
    const newContent = isMobileLayout
      ? url
      : intl.formatMessage({ id: 'Topbar.new.desktopText' }, { url })
    ;

    return (
      <div className={classes}>
        {/*<div className={css.new}>*/}
        {/*  &#127881;{' '}*/}
        {/*  {newContent}*/}
        {/*  {' '}&#127881;*/}
        {/*</div>*/}
        <LimitedAccessBanner
          isAuthenticated={isAuthenticated}
          authScopes={authScopes}
          currentUser={currentUser}
          onLogout={this.handleLogout}
          currentPage={currentPage}
        />
        <div
          className={classNames(mobileRootClassName || css.container, mobileClassName)}
        >
          <div className={css.buttonsWrapper}>
            <Button
              rootClassName={css.searchMenu}
              onClick={this.handleMobileSearchOpen}
              title={intl.formatMessage({ id: 'Topbar.searchIcon' })}
              style={{ visibility: isVisibleSearchForm ? 'visible' : 'hidden' }}
            >
              <SearchIcon
                className={classNames(css.searchMenuIcon, {
                  [css.searchMenuIconMain]: currentPage === "LandingPage",
                  [css.searchMenuIconActive]: isMobileSearchFormVisible,
                })}
              />
            </Button>
            <NamedLink
              className={css.home}
              name="LandingPage"
              title={intl.formatMessage({ id: 'Topbar.logoIcon' })}
            >
              <Logo
                className={css.logo}
                format="mobile"
                isTopbarScrolled={this.state.isTopbarScrolled}
                isTransparentBg={transparentBg}
              />
            </NamedLink>
            <Button
              rootClassName={css.menu}
              onClick={this.handleMobileMenuOpen}
              title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
            >
              <MenuIcon className={css.menuIcon} />
              {notificationDot}
              <SignUpDot className={css.notificationDot} />
            </Button>
          </div>
          <div className={classNames(css.mobileSearchFormWrapper, {
            [css.mobileSearchFormHide]: !isMobileSearchFormVisible,
          })}>
            <div className={css.mobileSearchFormDivider} />
            <div className={css.mobileSearchForm}>
              <MobileTopbarSearchForm
                isMobile={isMobileLayout}
                history={this.props.history}
                shouldClearQuickSearch={shouldClearQuickSearch}
                onGetMakeModelPromise={onGetMakeModelPromise}
                onSubmit={this.handleSubmit}
                initialValues={initialSearchFormValues}
                appConfig={config}
                isTransparentBg={transparentBg}
                isTopbarScrolled={this.state.isTopbarScrolled}
              />
            </div>
          </div>
        </div>
        <div className={css.desktop}>
          <TopbarDesktop
            searchFormIconClassName={searchFormIconClassName}
            isVisibleSearchForm={isVisibleSearchForm}
            shouldClearQuickSearch={shouldClearQuickSearch}
            onGetMakeModelPromise={onGetMakeModelPromise}
            className={desktopClassName}
            currentUserHasListings={currentUserHasListings}
            currentUser={currentUser}
            currentPage={currentPage}
            initialSearchFormValues={initialSearchFormValues}
            intl={intl}
            isAuthenticated={isAuthenticated}
            notificationCount={notificationCount}
            onLogout={this.handleLogout}
            onSearchSubmit={this.handleSubmit}
            appConfig={config}
            transparentBg={transparentBg}
            commentNotificationsCountField={commentNotificationsCountField}
            history={this.props.history}
          />
        </div>
        <Modal
          id="TopbarMobileMenu"
          isOpen={isMobileMenuOpen}
          onClose={this.handleMobileMenuClose}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          {authInProgress ? null : mobileMenu}
        </Modal>
        {/*{isVisibleSearchForm && (*/}
        {/*  <Modal*/}
        {/*    id="TopbarMobileSearch"*/}
        {/*    containerClassName={css.modalContainer}*/}
        {/*    isOpen={isMobileSearchOpen}*/}
        {/*    onClose={this.handleMobileSearchClose}*/}
        {/*    usePortal*/}
        {/*    onManageDisableScrolling={onManageDisableScrolling}*/}
        {/*  >*/}
        {/*    <div className={css.searchContainer}>*/}
        {/*      <TopbarSearchForm*/}
        {/*        searchFormIconClassName={searchFormIconClassName}*/}
        {/*        history={this.props.history}*/}
        {/*        shouldClearQuickSearch={shouldClearQuickSearch}*/}
        {/*        onGetMakeModelPromise={onGetMakeModelPromise}*/}
        {/*        onSubmit={this.handleSubmit}*/}
        {/*        initialValues={initialSearchFormValues}*/}
        {/*        isMobile*/}
        {/*        appConfig={config}*/}
        {/*        isTransparentBg={transparentBg}*/}
        {/*        isTopbarScrolled={this.state.isTopbarScrolled}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </Modal>*/}
        {/*)}*/}
        {/*{!isRealEstateListing &&*/}
        {/*  <ModalMissingInformation*/}
        {/*    id="MissingInformationReminder"*/}
        {/*    containerClassName={css.missingInformationModal}*/}
        {/*    currentUser={currentUser}*/}
        {/*    currentUserHasListings={currentUserHasListings}*/}
        {/*    currentUserHasAirListings={currentUserHasAirListings}*/}
        {/*    currentUserHasOrders={currentUserHasOrders}*/}
        {/*    location={location}*/}
        {/*    onManageDisableScrolling={onManageDisableScrolling}*/}
        {/*    onResendVerificationEmail={onResendVerificationEmail}*/}
        {/*    sendVerificationEmailInProgress={sendVerificationEmailInProgress}*/}
        {/*    sendVerificationEmailError={sendVerificationEmailError}*/}
        {/*  />*/}
        {/*}*/}

        <GenericError show={showGenericError} />
      </div>
    );
  }
}

TopbarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  desktopClassName: null,
  mobileRootClassName: null,
  mobileClassName: null,
  notificationCount: 0,
  currentUser: null,
  currentUserHasOrders: null,
  currentPage: null,
  sendVerificationEmailError: null,
  authScopes: [],
  isVisibleSearchForm: true,
};

const { array, func, number, shape, string } = PropTypes;

TopbarComponent.propTypes = {
  className: string,
  rootClassName: string,
  desktopClassName: string,
  mobileRootClassName: string,
  mobileClassName: string,
  isAuthenticated: bool.isRequired,
  authScopes: array,
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasAirListings: bool,
  currentUserHasOrders: bool,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onResendVerificationEmail: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  showGenericError: bool.isRequired,
  isVisibleSearchForm: bool,

  // These are passed from Page to keep Topbar rendering aware of location changes
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const Topbar = compose(
  withViewport,
  injectIntl
)(TopbarComponent);

Topbar.displayName = 'Topbar';

export default Topbar;
