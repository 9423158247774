import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconDownload.module.css';

const IconDownload = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      viewBox="0 0 512 448.72"
    >
      <path
        fillRule="nonzero"
        d="M359.34 124.65c-13.57 6.71-25.7 15.37-37.42 24.9l-20.12-23.02c14.8-13.74 32.08-24.5 50.64-32.4-37.56-66.53-128.4-83.94-188.3-37.41-21.74 16.84-38.26 42.03-44.61 75.42l-2.01 10.43-10.39 1.83c-10.18 1.79-19.29 4.25-27.27 7.37-49.8 19.27-63.78 74.69-33.31 116.79 13.04 17.9 29.66 26.98 51.47 30.01h31.88c-1.13 6.81-1.72 13.81-1.72 20.93 0 3.26.12 6.48.36 9.67H97.53l-1.92-.16c-30.54-3.89-55.62-17.43-73.75-42.54C-20.41 228.26.4 149.82 68.92 123.24c7.15-2.79 14.81-5.11 22.86-6.98 9.15-36.34 28.65-64.33 53.73-83.74C222.7-27.29 338.57-1.8 382.79 86.07c6.66-1.04 13.3-1.58 19.92-1.5 98.9.73 138.01 127.21 86.69 195.34-20.58 27.28-52.13 41.31-84.61 48.85l-3.42.41h-18.36c.24-3.19.37-6.41.37-9.67 0-7.12-.6-14.12-1.73-20.93h18.02c24.16-5.81 49.92-16.49 65.23-36.96 36.47-48.56 10.59-146.06-62.4-146.52-14.26-.12-29.11 3.33-43.16 9.56zM233.31 251.22h44.92c10.5 0 19.11 8.6 19.11 19.11v66.01h32.78c6.9.29 11.8 2.59 14.63 6.89 7.68 11.52-2.79 22.89-10.08 30.9-20.63 22.65-55.48 56.2-65.67 68.18-7.72 8.54-18.7 8.54-26.42 0-10.52-12.29-48.04-48.31-67.66-70.35-6.82-7.67-15.23-18.11-8.14-28.73 2.9-4.3 7.75-6.6 14.65-6.89h32.77v-66.01c0-10.45 8.61-19.11 19.11-19.11z"
      ></path>
    </svg>
  );
};

IconDownload.defaultProps = {
  rootClassName: null,
  className: null,
  fillColor: null,
};

IconDownload.propTypes = {
  rootClassName: string,
  className: string,
  fillColor: string,
};

export default IconDownload;
