import { fieldSet } from 'simple-redux-field';

import {
  ADD_NOTIFICATION_FIELD,
  COMMENT_NOTIFICATIONS_COUNT_FIELD,
  CHAT_NOTIFICATIONS_COUNT_FIELD,
  INITIATORS,
} from './notifications.constants';
import {
  fetchNewCommentsCount,
  fetchAddNotification,
  fetchNewChatCount,
} from './notifications.api';

export const getCommentNotificationsCount = userId => dispatch => {
  dispatch(fieldSet(COMMENT_NOTIFICATIONS_COUNT_FIELD, {
    inProgress: true,
    error: null,
  }));
  return fetchNewCommentsCount({
    userId,
    initiator: INITIATORS.COMMENT,
  })
    .then(data => {
      dispatch(fieldSet(COMMENT_NOTIFICATIONS_COUNT_FIELD, {
        inProgress: false,
        count: data.data,
      }));
      return data;
    })
    .catch(e => {
      dispatch(fieldSet(COMMENT_NOTIFICATIONS_COUNT_FIELD, {
        inProgress: false,
        error: e.message,
      }));
      throw e;
    })
  ;
};

export const getChatNotificationsCount = userId => dispatch => {
  dispatch(fieldSet(CHAT_NOTIFICATIONS_COUNT_FIELD, { inProgress: true, error: null }));
  return fetchNewChatCount({
    userId,
    initiator: INITIATORS.MESSAGE,
  })
    .then(data => {
      dispatch(fieldSet(CHAT_NOTIFICATIONS_COUNT_FIELD, {
        inProgress: false,
        counts: data.data,
      }));
      return data;
    })
    .catch(e => {
      dispatch(fieldSet(CHAT_NOTIFICATIONS_COUNT_FIELD, {
        inProgress: false,
        error: e.message,
      }));
      throw e;
    })
  ;
};

export const addNotification = (initiator, userId, context) => dispatch => {
  dispatch(fieldSet(ADD_NOTIFICATION_FIELD, { inProgress: true, error: null }));
  const params = {
    i: initiator,
    u_id: userId,
    c: context,
    i_at: new Date(),
  };
  return fetchAddNotification(params)
    .then(() => {
      dispatch(fieldSet(ADD_NOTIFICATION_FIELD, { inProgress: false }));
      return Promise.resolve();
    })
    .catch(e => {
      dispatch(fieldSet(ADD_NOTIFICATION_FIELD, { inProgress: false, error: e.message }));
      throw e;
    })
  ;
};
