import { BaseReducer } from 'redux-di-container';

import { storableError } from '../util/errors';

export class NewsService extends BaseReducer {
  constructor(opts) {
    super();
    this.restApiService = opts.restApiService;
  }

  getInitialState() {
    return {
      isNewsPending: false,
      newsRequestError: null,
      news: [],
    };
  }

  $newsState(state) {
    return this.select(state);
  }

  fetchNews(searchString, catName, makerName, modelFamilyName) {
    this.dispatchAction(state => {
      state.isNewsPending = true;
      state.newsRequestError = null;
    }, 'fetchNews');
    const search = new URLSearchParams();
    if (searchString) {
      search.append('search_string', searchString);
    }
    if (catName) {
      search.append('category_name', catName);
    }
    if (makerName) {
      search.append('maker_name', makerName);
    }
    if (modelFamilyName) {
      search.append('model_family_name', modelFamilyName);
    }

    this.restApiService.publicInstance
      .get(`/news?${search.toString()}`)
      .then(data => {
        this.dispatchAction(state => {
          state.isNewsPending = false;
          state.news = data;
        }, 'fetchNewsSuccess');
      })
      .catch(e => {
        this.dispatchAction(state => {
          state.isNewsPending = false;
          state.newsRequestError = storableError(e);
        }, 'fetchNewsError');
      })
    ;
  }
}
