import get from 'lodash/get';

import {
  restApiService,
  newsService,
  localStorageService,
} from '../../services/services';
import {
  axiosStorableError,
  storableError,
} from '../../util/errors';
import {
  handleAxiosResponse,
} from '../../util/apiHelpers';
import {
  accidents,
} from '../../selectors/registrations.selectors';
import {
  fetchPhotoApi,
} from '../RegistrationPhotosPage/RegistrationPhotosPage.duck';
import {
  fetchSimilarListingsByReNo,
} from '../../ducks/SimilarListings.duck';
import {
  fetchListingsQueryParams, sortByDate,
} from '../../util/listing';
import {
  addMarketplaceEntities,
  getListingsEntities,
} from '../../ducks/marketplaceData.duck';

// ================ Action types ================ //

const DATA_FETCH = 'app/AircraftRegistrationPage/DATA_FETCH';
const DATA_FETCH_SUCCESS = 'app/AircraftRegistrationPage/DATA_FETCH_SUCCESS';
const DATA_FETCH_ERROR = 'app/AircraftRegistrationPage/DATA_FETCH_ERROR';

const UPLOAD_PHOTOS_REQUEST = 'app/AircraftRegistrationPage/UPLOAD_PHOTOS_REQUEST';
const UPLOAD_PHOTOS_REQUEST_SUCCESS = 'app/AircraftRegistrationPage/UPLOAD_PHOTOS_REQUEST_SUCCESS';
const UPLOAD_PHOTOS_REQUEST_ERROR = 'app/AircraftRegistrationPage/UPLOAD_PHOTOS_REQUEST_ERROR';

const FETCH_PHOTO_REQUEST = 'app/AircraftRegistrationPage/FETCH_PHOTO_REQUEST';
const FETCH_PHOTO_SUCCESS = 'app/AircraftRegistrationPage/FETCH_PHOTO_SUCCESS';
const FETCH_PHOTO_ERROR = 'app/AircraftRegistrationPage/FETCH_PHOTO_ERROR';

const RELATED_LISTINGS_REQUEST = 'app/AircraftRegistrationPage/RELATED_LISTINGS_REQUEST';
const RELATED_LISTINGS_SUCCESS = 'app/AircraftRegistrationPage/RELATED_LISTINGS_SUCCESS';
const RELATED_LISTINGS_ERROR = 'app/AircraftRegistrationPage/RELATED_LISTINGS_ERROR';

const META_IMAGE_REQUEST = 'app/AircraftRegistrationPage/META_IMAGE_REQUEST';
const META_IMAGE_SUCCESS = 'app/AircraftRegistrationPage/META_IMAGE_SUCCESS';
const META_IMAGE_ERROR = 'app/AircraftRegistrationPage/META_IMAGE_ERROR';

const RESET_STATE = 'app/AircraftRegistrationPage/RESET_STATE';

// ================ Reducer ================ //

const initialState = {
  dataFetchIsPending: false,
  dataFetchError: null,
  data: null,
  metadata: null,
  uploadedPhoto: null,
  uploadPhotosIsPending: false,
  uploadPhotosError: null,
  photo: [],
  photoInProgress: true,
  photoError: null,
  relatedListingsIsPending: false,
  relatedListingsError: null,
  relatedListingsIds: [],
  metaImageIsPending: false,
  metaImageUrl: null,
  metaImageError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case DATA_FETCH:
      return {
        ...state,
        dataFetchIsPending: true,
        dataFetchError: null,
      };

    case DATA_FETCH_SUCCESS:
      return {
        ...state,
        dataFetchIsPending: false,
        data: payload.data,
        metadata: payload.metadata,
      };

    case DATA_FETCH_ERROR:
      return {
        ...state,
        dataFetchIsPending: false,
        dataFetchError: payload,
      };

    case UPLOAD_PHOTOS_REQUEST:
      return {
        ...state,
        uploadPhotosIsPending: true,
        uploadPhotosError: null,
      };

    case UPLOAD_PHOTOS_REQUEST_SUCCESS:
      return {
        ...state,
        uploadPhotosIsPending: false,
        uploadedPhoto: payload,
      };

    case UPLOAD_PHOTOS_REQUEST_ERROR:
      return {
        ...state,
        uploadPhotosIsPending: false,
        uploadPhotosError: payload,
      };

    case FETCH_PHOTO_REQUEST:
      return {
        ...state,
        photoInProgress: true,
        photoError: null,
      };

    case FETCH_PHOTO_SUCCESS:
      return {
        ...state,
        photoInProgress: false,
        photo: payload,
      };

    case FETCH_PHOTO_ERROR:
      return {
        ...state,
        photoInProgress: false,
        photoError: payload,
      };

    case RELATED_LISTINGS_REQUEST:
      return {
        ...state,
        relatedListingsIsPending: true,
        relatedListingsError: null,
      };

    case RELATED_LISTINGS_SUCCESS:
      return {
        ...state,
        relatedListingsIsPending: false,
        relatedListingsIds: payload,
      };

    case RELATED_LISTINGS_ERROR:
      return {
        ...state,
        relatedListingsIsPending: false,
        relatedListingsError: payload,
      };

    case META_IMAGE_REQUEST:
      return {
        ...state,
        metaImageIsPending: true,
        metaImageError: null,
      };

    case META_IMAGE_SUCCESS:
      return {
        ...state,
        metaImageIsPending: false,
        metaImageUrl: payload,
      };

    case META_IMAGE_ERROR:
      return {
        ...state,
        metaImageIsPending: false,
        metaImageError: payload,
      };

    case RESET_STATE:
      return initialState;

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const dataFetch = () => ({
  type: DATA_FETCH,
});

export const dataFetchSuccess = (payload) => ({
  type: DATA_FETCH_SUCCESS,
  payload,
});

export const dataFetchError = (payload) => ({
  type: DATA_FETCH_ERROR,
  payload,
});

export const uploadPhotosRequest = () => ({
  type: UPLOAD_PHOTOS_REQUEST,
});

export const uploadPhotosRequestSuccess = (payload) => ({
  type: UPLOAD_PHOTOS_REQUEST_SUCCESS,
  payload,
});

export const uploadPhotosRequestError = (payload) => ({
  type: UPLOAD_PHOTOS_REQUEST_ERROR,
  payload,
});

export const fetchPhotoRequest = () => ({
  type: FETCH_PHOTO_REQUEST,
});

export const fetchPhotoSuccess = photo => ({
  type: FETCH_PHOTO_SUCCESS,
  payload: photo,
});

export const fetchPhotoError = error => ({
  type: FETCH_PHOTO_ERROR,
  payload: error,
});

export const relatedListingsRequest = () => ({
  type: RELATED_LISTINGS_REQUEST,
});

export const relatedListingsSuccess = ids => ({
  type: RELATED_LISTINGS_SUCCESS,
  payload: ids,
});

export const relatedListingsError = error => ({
  type: RELATED_LISTINGS_ERROR,
  payload: error,
});

export const metaImageRequest = () => ({
  type: META_IMAGE_REQUEST,
});

export const metaImageSuccess = url => ({
  type: META_IMAGE_SUCCESS,
  payload: url,
});

export const metaImageError = error => ({
  type: META_IMAGE_ERROR,
  payload: error,
});

const resetStataAction = () => ({
  type: RESET_STATE,
});

// ================ Selectors ================ //

const $subState = state => {
  return state.AircraftRegistrationPage;
};

export const $aircraftRegistrationData = state => {
  return get($subState(state), 'data.canonical_aircraft', null);
};

export const $fetchDataError = state => {
  return $subState(state).dataFetchError;
};

export const $dataFetchIsPending = state => {
  return $subState(state).dataFetchIsPending;
};

export const $registrations = state => {
  const regs = get($subState(state), 'data.faa_registrations_cleaned', []);
  return sortByDate(regs, 'MM-DD-YYYY', 'last_action_date');
};

export const $accidents = state => {
  return accidents(get($subState(state), 'data', null));
};

export const $makeModel = state => {
  return get($subState(state), 'data.canonical_aircraft', null);
};

export const $regNo = state => {
  return get($subState(state), 'data.reg_no', null);
};

export const $metadata = state => {
  return get($subState(state), 'metadata', null);
};

export const $lastAirborneAt = state => {
  return get($subState(state), 'data.last_airborne_at', null);
};

export const $lastLocation = state => {
  return get($subState(state), 'data.last_location', null);
};

export const $uploadedPhotoState = state => {
  const subState = $subState(state);
  return {
    photo: subState.uploadedPhoto,
    isPending: subState.uploadPhotosIsPending,
    error: subState.uploadPhotosError,
  };
};

export const $photoState = state => {
  const subState = $subState(state);
  return {
    photo: subState.photo,
    photoInProgress: subState.photoInProgress,
    photoError: subState.photoError,
  };
};

export const $relatedListingsState = state => {
  const subState = $subState(state);
  return {
    data: getListingsEntities(state)(subState.relatedListingsIds),
    isPending: subState.relatedListingsIsPending,
    error: subState.relatedListingsError,
  };
};

export const $metaImageUrl = state => {
  return $subState(state).metaImageUrl;
};

// ================ Thunk ================ //

export const resetState = () => dispatch => {
  dispatch(resetStataAction());
};

export const fetchPhoto = regNo => dispatch => {
  dispatch(fetchPhotoRequest());
  return dispatch(fetchPhotoApi(regNo))
    .then(resp => {
      dispatch(fetchPhotoSuccess(resp));
      return resp;
    })
    .catch((e) => {
      dispatch(fetchPhotoError(storableError(e)));
      throw e;
    })
  ;
};

export const getIsLoadedRegistrationPhoto = (userId, regNo) => {
  return `isLoadedRegistrationPhoto::${userId}::${regNo.toLowerCase()}`;
};

export const uploadPhotos = (regNo, files) => (dispatch, getState) => {
  if (!files.length) {
    return Promise.resolve();
  }
  const currentUser = get(getState(), 'user.currentUser', null);
  dispatch(uploadPhotosRequest());
  const currentUserId = get(currentUser, 'id.uuid', null);
  let uploadedCount = localStorageService.getItem(
    getIsLoadedRegistrationPhoto(currentUserId, regNo),
    0
  );

  const allowedFormats = ['.JPG', '.PNG'];

  const filesNames = Array.from(files).map(file => file.name.toUpperCase());

  const hasInvalidFormat = filesNames.some(fileName => {
    return !allowedFormats.some(format => fileName.endsWith(format));
  });

  if (hasInvalidFormat) {
    dispatch(uploadPhotosRequestError(`This format isn't supported. Please upload .JPG or .PNG`));
    return Promise.reject();
  }

  if (uploadedCount + files.length > 5) {
    const errorMsg1 = 'You can upload only 5 files.';
    const errorMsg2 = uploadedCount > 0 ? ` You already have uploaded ${uploadedCount} files.` : '';
    dispatch(uploadPhotosRequestError(`${errorMsg1}${errorMsg2}`));
    return Promise.reject();
  }

  const makeModel = $makeModel(getState());
  const username = get(currentUser, 'attributes.profile.publicData.username', null);
  const companyName = get(currentUser, 'attributes.profile.publicData.companyName', null);
  const displayName = get(currentUser, 'attributes.profile.displayName', null);
  const formData = new FormData();
  Array.from(files).forEach(f => formData.append(f.name, f));
  formData.append('regNo', regNo);
  formData.append('make', makeModel.make);
  formData.append('model', makeModel.model);
  formData.append('ui', currentUserId);
  if (username) {
    formData.append('un', username);
  }
  if (companyName) {
    formData.append('cn', companyName);
  }
  if (displayName) {
    formData.append('dn', displayName);
  }
  return restApiService.secureInstance
    .postRaw('/files/upload-registration-photos', formData)
    .then(handleAxiosResponse)
    .then(resp => {
      dispatch(uploadPhotosRequestSuccess(resp));
      localStorageService.setItem(
        getIsLoadedRegistrationPhoto(currentUserId, regNo),
        uploadedCount + files.length
      );
      return resp;
    })
    .catch(e => {
      dispatch(uploadPhotosRequestError(storableError(e)));
      throw e;
    })
  ;
};

const getSimilarListings = regNo => dispatch => {
  if (!regNo) {
    return Promise.resolve();
  }
  const params = {
    ...fetchListingsQueryParams(),
  };
  return dispatch(fetchSimilarListingsByReNo(regNo, params));
};

export const getRelatedListings = regNo => (dispatch, getState, sdk) => {
  dispatch(relatedListingsRequest());

  const params = {
    pub_keywords: regNo.toLowerCase(),
    ...fetchListingsQueryParams(),
  };

  return restApiService.publicInstance
    .getRaw('/registrations/related-listings', { params })
    .then(handleAxiosResponse)
    .then(resp => {
      const ids = resp.data.data.map(i => i.id.uuid);
      dispatch(addMarketplaceEntities(resp));
      dispatch(relatedListingsSuccess(ids));
      return resp;
    })
    .catch(e => {
      dispatch(relatedListingsError(storableError(e)));
      throw e;
    })
  ;
};

const fetchMetaImage = regNo => dispatch => {
  dispatch(metaImageRequest());
  return restApiService.publicInstance
    .getRaw(`/registrations/featured-photo/${regNo}`)
    .then(resp => {
      const url = get(resp, 'data.href', null);
      dispatch(metaImageSuccess(url));
      return resp;
    })
    .catch(e => {
      dispatch(metaImageError(storableError(e)));
      throw e;
    })
  ;
};

export const loadData = params => async (dispatch, getState) => {
  let regNo = get(params, 'reg_no', null);
  regNo = regNo && regNo.toUpperCase();
  dispatch(dataFetch());
  return restApiService.publicInstance
    .getRaw('/registrations', {
      params: {
        reg_no: regNo,
      },
    })
    .then(handleAxiosResponse)
    .then(async response => {
      await dispatch(fetchMetaImage(regNo));
      dispatch(getSimilarListings(regNo));
      dispatch(dataFetchSuccess(response));
      const makeModel = $makeModel(getState());
      if (makeModel) {
        newsService.fetchNews(
          null,
          null,
          makeModel.make,
          null,
        );
      }
      return response;
    })
    .catch(e => {
      dispatch(dataFetchError(axiosStorableError(e)));
    })
  ;
};
