import React from 'react';

const OverpricedIcon = (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        width="18"
        height="18"
        fill="#E32922"
        {...props}
      />
      <path
        d="M7.50008 4.99998L10.5001 4.99998L10.5001 8.99998L12.9201 8.99998L9.00008 12.92L5.08008 8.99998L7.50008 8.99998L7.50008 4.99998Z"
        fill={props.symbol || "white"}
      />
    </svg>
  );
};

export default OverpricedIcon;
