import { BaseReducer } from 'redux-di-container';
import TagManager from 'react-gtm-module';
import get from 'lodash/get';

import { $listingId } from '../ListingPage/ListingPage.duck';
import { getListingEntity, getOwnListingEntity } from '../../ducks/marketplaceData.duck';
import { axiosStorableError } from '../../util/errors';

export class FinanceModalService extends BaseReducer {
  constructor (opts) {
    super();
    this.restApiService = opts.restApiService;
  }

  getInitialState() {
    return {
      isOpen: false,
      submitIsPending: false,
      submitError: null,
    };
  }

  $isOpen(state) {
    return this.select(state).isOpen;
  }

  $submitState = this.createSelector(
    state => this.select(state),
    thisState => {
      return {
        isPending: thisState.submitIsPending,
        error: thisState.submitError,
      };
    }
  );

  openModal() {
    this.dispatchAction(state => {
      state.isOpen = true;
    }, 'openModal');
  }

  closeModal() {
    this.dispatchAction(state => {
      state.isOpen = false;
    }, 'closeModal');
  }

  submitForm(values, params) {
    this.dispatchAction(state => {
      state.submitIsPending = true;
    }, 'SubmitFormPending');
    const state = this.getState();
    const listingIdStr = $listingId(state);
    const currentListing = getOwnListingEntity(state)(listingIdStr) || getListingEntity(state)(listingIdStr);
    const price = get(currentListing, 'attributes.price.amount', 0) / 100;
    const makerName = get(currentListing, 'attributes.publicData.maker_name', '');
    const modelName = get(currentListing, 'attributes.publicData.model_name', '');
    const leads = [
      {
        Lead_Source: 'aircraftforsale.com',
        Last_Name: values.lastName,
        First_Name: values.firstName,
        Email: values.email,
        State: values.state,
        Phone: values.phone.value,
        Aircraft_Use: values.use.map(i => i.value).join(', '),
        Equip_Price: price,
        New_Used: get(currentListing, 'attributes.publicData.condition', ''),
        Equip_Year: get(currentListing, 'attributes.publicData.year', '').toString(),
        Equip_Make: makerName,
        Equip_Model: modelName,
        Equip_Type_Descript: get(currentListing, 'attributes.publicData.summary_desc', ''),
        Equipment_Location: get(currentListing, 'attributes.publicData.aircraft_loc.selectedPlace.address', ''),
        FAA_Registration_No: get(currentListing, 'attributes.publicData.reg_num', ''),
        Equip_Serial_No: get(currentListing, 'attributes.publicData.serial_num', ''),
        Equipment_Delivery: get(window, 'location.href', ''),
      },
    ];
    return this.restApiService.publicInstance
      .postRaw('/zoho/push-zoho-leads', { leads })
      .then(response => {
        TagManager.dataLayer({
          dataLayer: {
            event: 'prequalify_submit',
            category: get(currentListing, 'attributes.publicData.category_name', ''),
            make: makerName,
            model: modelName,
            price,
          },
        });
        this.dispatchAction(state => {
          state.submitIsPending = false;
          state.isOpen = false;
        }, 'SubmitFormSuccess');
        return response;
      })
      .catch(error => {
        this.dispatchAction(state => {
          state.submitIsPending = false;
          state.submitError = axiosStorableError(error).message;
        }, 'SubmitFormError');
        throw error;
      })
    ;
  }
}
