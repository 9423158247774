import { BaseReducer } from 'redux-di-container';
import get from 'lodash/get';

import { handleTransitAxiosResponse } from '../util/apiHelpers';
import { addMarketplaceEntities } from '../ducks/marketplaceData.duck';
import { getCurrentUser } from '../ducks/user.duck';

import config from '../config/config';

export class PricingAnalysisService extends BaseReducer {
  constructor (opts) {
    super();
    this.restApiService = opts.restApiService;
  }

  getInitialState() {
    return {
      estimateData: null,
      estimateDataPending: false,
      estimateDataError: null,
      updateVotePending: false,
      updateVoteError: null,
      updateUserPending: false,
      updateUserError: null,
    };
  }

  $estimateData(state) {
    return get(this.select(state).estimateData, 'estimate_data');
  }

  getEstimateData(lId) {
    this.dispatchAction(state => {
      state.estimateDataPending = true;
      state.estimateDataError = null;
    });

    if (config.env !== 'production') {
      lId = '653fcd58-f6ed-414a-a226-00e35c1a06bd';
    }

    return this.restApiService.publicInstance
      .getRaw(`/pricing-analysis/estimate-data?id=${lId}`)
      .then(response => {
        const data = response.data;
        this.dispatchAction(state => {
          state.estimateDataPending = false;
          state.estimateData = data;
        });
        return data;
      })
      .catch(e => {
        this.dispatchAction(state => {
          state.estimateDataPending = false;
          state.estimateDataError = get(e, 'message', '');
        });
      })
    ;
  }

  updateVotes = (listing, isLiked) => dispatch => {
    this.dispatchAction(state => {
      state.updateVotePending = true;
      state.updateVoteError = null;
    }, 'updateVotePending');

    let newPricingAnalysisLikes = 0;
    let newPricingAnalysisDislikes = 0;
    const currentPricingAnalysisLikes = get(
      listing,
      'attributes.publicData.pricingAnalysisLikes',
      0
    );
    const currentPricingAnalysisDislikes = get(
      listing,
      'attributes.publicData.pricingAnalysisDislikes',
      0
    );

    const currentUser = get(this.getState(), 'user.currentUser', null);
    const userProfileListingsVote = get(
      currentUser,
      `attributes.profile.publicData.pricingAnalysisVotes`,
      {}
    );
    const currentUserProfileListingVote = get(userProfileListingsVote, `[${listing.id.uuid}]`, null);
    newPricingAnalysisLikes = isLiked
      ? currentPricingAnalysisLikes + 1
      : !isLiked && currentUserProfileListingVote === true
        ? currentPricingAnalysisLikes - 1
        : currentPricingAnalysisLikes
    ;
    newPricingAnalysisDislikes = !isLiked
      ? currentPricingAnalysisDislikes + 1
      : isLiked && currentUserProfileListingVote === false
        ? currentPricingAnalysisDislikes - 1
        : currentPricingAnalysisDislikes
    ;

    return this.restApiService.secureInstance
      .postRaw('/listing/update', {
        l_id: listing.id.uuid,
        d: {
          publicData: {
            pricingAnalysisLikes: newPricingAnalysisLikes ? newPricingAnalysisLikes : null,
            pricingAnalysisDislikes: newPricingAnalysisDislikes ? newPricingAnalysisDislikes : null,
          },
        },
      })
      .then(handleTransitAxiosResponse)
      .then(updateListingResponse => {
        return updateListingResponse;
        // dispatch(addMarketplaceEntities(response));
        // return response;
      })
      .then(async updateListingResponse => {
        await this.restApiService.secureInstance
          .patchRaw(`/userProfile/${currentUser.id.uuid}`, {
            d: {
              publicData: {
                pricingAnalysisVotes: {
                  ...userProfileListingsVote,
                  [listing.id.uuid]: !!isLiked,
                },
              },
            },
          })
        ;
        return updateListingResponse;
      })
      .then(async updateListingResponse => {
        await dispatch(getCurrentUser());
        return updateListingResponse;
      })
      .then(updateListingResponse => {
        dispatch(addMarketplaceEntities(updateListingResponse));
        this.dispatchAction(state => {
          state.updateVotePending = false;
        });
      })
      .catch(e => {
        this.dispatchAction(state => {
          state.updateVotePending = false;
          state.updateVoteError = get(e, 'message', null);
        });
        throw e;
      })
    ;
  }
}
