import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconViewPhotos.module.css';

const IconViewPhotos = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g fill="#fff" clipPath="url(#clip0_391_757)">
        <path d="M4.502 9a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"></path>
        <path d="M14.002 13a2 2 0 01-2 2h-10a2 2 0 01-2-2V5A2 2 0 012 3a2 2 0 012-2h10a2 2 0 012 2v8a2 2 0 01-1.998 2zM14 2H4a1 1 0 00-1 1h9.002a2 2 0 012 2v7A1 1 0 0015 11V3a1 1 0 00-1-1zM2.002 4a1 1 0 00-1 1v8l2.646-2.354a.5.5 0 01.63-.062l2.66 1.773 3.71-3.71a.5.5 0 01.577-.094l1.777 1.947V5a1 1 0 00-1-1h-10z"></path>
      </g>
      <defs>
        <clipPath id="clip0_391_757">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

IconViewPhotos.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconViewPhotos.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconViewPhotos;
