import {
  TX_TRANSITION_ACTOR_CUSTOMER,
  TX_TRANSITION_ACTOR_PROVIDER,
} from '../../util/transactions/helpers';

export const COMMENT_NOTIFICATIONS_COUNT_FIELD = 'Notifications__comment_notifications_count';
export const ADD_NOTIFICATION_FIELD = 'Notifications__add_notification';
export const CHAT_NOTIFICATIONS_COUNT_FIELD = 'Notifications__chat_notifications_count';

export const INITIAL_COMMENT_NOTIFICATIONS_COUNT_FIELD = {
  inProgress: false,
  error: null,
  count: 0,
};

export const INITIAL_ADD_NOTIFICATION_FIELD = {
  inProgress: false,
  error: null,
};

export const INITIAL_CHAT_NOTIFICATIONS_COUNT_FIELD = {
  inProgress: false,
  error: null,
  counts: {
    [TX_TRANSITION_ACTOR_CUSTOMER]: 0,
    [TX_TRANSITION_ACTOR_PROVIDER]: 0,
  },
};

export const INITIATORS = {
  COMMENT: 1,
  MESSAGE: 2,
};

export const DEFAULT_APP_FIELDS = {
  [COMMENT_NOTIFICATIONS_COUNT_FIELD]: INITIAL_COMMENT_NOTIFICATIONS_COUNT_FIELD,
  [CHAT_NOTIFICATIONS_COUNT_FIELD]: INITIAL_CHAT_NOTIFICATIONS_COUNT_FIELD,
  [ADD_NOTIFICATION_FIELD]: INITIAL_ADD_NOTIFICATION_FIELD,
};
