import { BaseReducer } from 'redux-di-container';
import TagManager from 'react-gtm-module';
import get from 'lodash/get';

import config from '../config';
import {
  TRANSITION_ENQUIRE,
} from '../util/transactions/flex-enquiry-custom-process/transitions';
import { fetchUnauthEnquiry } from '../containers/ListingPage/modules/listingPage.api';
import { getListingEntity } from '../ducks/marketplaceData.duck';

export class SignModalService extends BaseReducer {
  static CONTENT_SIGN = 'sign';
  static CONTENT_INQUIRE = 'inquire';
  static TAB_LOGIN = 'login';
  static TAB_SIGNUP = 'signup';
  static TAB_CONFIRM = 'confirm';

  static DEFAULT_PARAMS = {
    signModalParamsAddToFavorites: null,
    signModalParamsListingType: null,
    signModalParamsAuthorId: null,
    signModalParamsListingTitle: null,
    signModalParamsListingId: null,
    signModalParamsEmail: null,
    signModalParamsName: null,
    signModalParamsLastName: null,
    signModalParamsPhone: null,
    signModalParamsMessage: null,
    isListingViewerSignupFunctionality: false,
    isPricingAnalysisFunctionality: false,
  };

  constructor (opts) {
    super();
    this.restApiService = opts.restApiService;
  }

  getInitialState() {
    return {
      isOpen: false,
      content: SignModalService.CONTENT_SIGN,
      tab: SignModalService.TAB_LOGIN,
      modalParams: SignModalService.DEFAULT_PARAMS,
      sendInquireInProgress: false,
      sendInquireError: null,
    };
  }

  $signModalState = this.createSelector(
    state => this.select(state),
    thisState => {
      return {
        isOpen: thisState.isOpen,
        tab: thisState.tab,
        modalParams: thisState.modalParams,
        isSignContent: thisState.content === SignModalService.CONTENT_SIGN,
        isInquireContent: thisState.content === SignModalService.CONTENT_INQUIRE,
        sendInquireInProgress: thisState.sendInquireInProgress,
        sendInquireError: thisState.sendInquireError,
        isAddToFavoritesFunctionality: !!thisState.modalParams.signModalParamsAddToFavorites,
        isInquireFunctionality: !!thisState.modalParams.signModalParamsMessage,
        isListingViewerSignupFunctionality: thisState.isListingViewerSignupFunctionality,
        isPricingAnalysisFunctionality: thisState.modalParams.isPricingAnalysisFunctionality,
      };
    }
  );

  setTab(tab) {
    this.dispatchAction(state => {
      state.tab = tab;
    }, 'SetTab');
  }

  openInquireContent(modalParams) {
    this.openModal(SignModalService.CONTENT_INQUIRE, modalParams);
  }

  openSignContent(modalParams) {
    this.openModal(SignModalService.CONTENT_SIGN, modalParams);
  }

  openSignTab() {
    this.dispatchAction(state => {
      state.isOpen = true;
      state.content = SignModalService.CONTENT_SIGN;
      state.tab = SignModalService.TAB_SIGNUP;
      state.isListingViewerSignupFunctionality = true;
    }, 'openSignTab');
  }

  openModal(content = SignModalService.CONTENT_SIGN, modalParams = SignModalService.DEFAULT_PARAMS) {
    const stateParams = this.$signModalState(this.getState()).modalParams;
    this.dispatchAction(state => {
      state.isOpen = true;
      state.content = content;
      state.modalParams = {
        ...stateParams,
        ...modalParams,
      };
    }, 'OpenModal');
  }

  closeModal() {
    this.resetState();
  }

  onSubmitInquireForm = (values, params) => {
    this.dispatchAction(state => {
      state.sendInquireInProgress = true;
    }, 'SendInquire');
    if (values.phone && values.phone.value === values.phone.dialCode) {
      values.phone = null;
    }
    const phone = values.phone;
    const phoneNumber = !phone || (phone && phone.value === phone.dialCode)
      ? null
      : phone.value
    ;
    const modalParams = this.$signModalState(this.getState()).modalParams;
    let currentListing;
    let leads;
    if (params.id) {
      const state = this.getState();
      currentListing = getListingEntity(state)(params.id);
    }
    if (currentListing) {
      leads = [
        {
          Lead_Source: 'aircraftforsale.com',
          Last_Name: values.lname,
          First_Name: values.fname,
          Email: values.email,
          Phone: phoneNumber,
          Equip_Price: get(currentListing, 'attributes.price.amount', 0) / 100,
          New_Used: get(currentListing, 'attributes.publicData.condition', ''),
          Equip_Year: get(currentListing, 'attributes.publicData.year', '').toString(),
          Equip_Make: get(currentListing, 'attributes.publicData.maker_name', ''),
          Equip_Model: get(currentListing, 'attributes.publicData.model_name', ''),
          Equip_Type_Descript: get(currentListing, 'attributes.publicData.summary_desc', ''),
          Equipment_Location: get(currentListing, 'attributes.publicData.aircraft_loc.selectedPlace.address', ''),
          FAA_Registration_No: get(currentListing, 'attributes.publicData.reg_num', ''),
          Equip_Serial_No: get(currentListing, 'attributes.publicData.serial_num', ''),
          Equipment_Delivery: get(window, 'location.href', ''),
        },
      ];
    }

    return fetchUnauthEnquiry(
      modalParams.signModalParamsAuthorId,
      modalParams.signModalParamsListingTitle,
      values.fname,
      values.lname,
      values.email,
      values.message,
      phoneNumber,
      values.isFinanceRequest,
      leads
    )
      .then(() => {
        TagManager.dataLayer({
          dataLayer: {
            event: 'generate_lead',
            user_logged_in: false,
            country_code: get(window, 'navigator.languages[0]', null),
            inquiry_type: 'email',
          },
        });
        if (values.isFinanceRequest) {
          TagManager.dataLayer({ dataLayer: { event: 'finance_request_info' } });
        }
        this.dispatchAction(state => {
          state.sendInquireInProgress = false;
          state.content = SignModalService.CONTENT_SIGN;
          state.tab = SignModalService.TAB_SIGNUP;
          state.modalParams = {
            ...modalParams,
            signModalParamsEmail: values.email,
            signModalParamsName: values.fname,
            signModalParamsLastName: values.lname,
            signModalParamsPhone: phoneNumber,
            signModalParamsMessage: values.message,
          };
        }, 'SendInquireSuccess');
      })
      .catch(e => {
        this.dispatchAction(state => {
          state.sendInquireInProgress = false;
          state.sendInquireError = e.message;
        }, 'SendInquireError');
      })
    ;
  }

  createTransactionAfterSignup = signModalParams => (dispatch, getState, sdk) => {
    const bodyParams = {
      transition: TRANSITION_ENQUIRE,
      processAlias: config.transactionProcessAliasEnquiry,
      params: { listingId: signModalParams.signModalParamsListingId },
    };
    return sdk.transactions.initiate(bodyParams)
      .then(response => {
        const transactionId = response.data.data.id;
        const message = signModalParams.signModalParamsMessage;
        return sdk.messages.send({ transactionId, content: message })
          .then(response => response)
        ;
      })
      .catch(e => {
        throw e;
      })
    ;
  }

  saveVisitors(modalParams) {
    return this.restApiService.publicInstance
      .post(`/userProfile/${modalParams.authorId}/add-visitors`, {
        e: modalParams.signModalParamsEmail,
        n: `${modalParams.signModalParamsName} ${modalParams.signModalParamsLastName}`,
        p: modalParams.signModalParamsPhone,
        m: modalParams.signModalParamsMessage,
        a_id: modalParams.signModalParamsAuthorId,
      })
      .then(response => response)
      .catch(e => {
        throw e;
      })
    ;
  };
}
