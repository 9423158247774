import get from 'lodash/get';

import { updatedEntities, denormalisedEntities } from '../util/data';
import { types as sdkTypes } from '../util/sdkLoader';

const { UUID } = sdkTypes;
const ENTITY_TYPE__LISTING = 'listing';

// ================ Action types ================ //

export const ADD_MARKETPLACE_ENTITIES = 'app/marketplaceData/ADD_MARKETPLACE_ENTITIES';

// ================ Reducer ================ //

const initialState = {
  // Database of all the fetched entities.
  entities: {},
};

const merge = (state, sdkResponse) => {
  const apiResponse = sdkResponse.data;
  return {
    ...state,
    entities: updatedEntities({ ...state.entities }, apiResponse),
  };
};

export default function marketplaceDataReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case ADD_MARKETPLACE_ENTITIES:
      return merge(state, payload);

    default:
      return state;
  }
}

// ================ Selectors ================ //

/**
 * Get the denormalised listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getListingsById = (state, listingIds) => {
  const { entities } = state.marketplaceData;
  const resources = listingIds.map(id => ({
    id,
    type: 'listing',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(entities, resources, throwIfNotFound);
};

/**
 * Get the denormalised entities from the given entity references.
 *
 * @param {Object} state the full Redux store
 *
 * @param {Array<{ id, type }} entityRefs References to entities that
 * we want to query from the data. Currently we expect that all the
 * entities have the same type.
 *
 * @return {Array<Object>} denormalised entities
 */
export const getMarketplaceEntities = (state, entityRefs) => {
  const { entities } = state.marketplaceData;
  const throwIfNotFound = false;
  return denormalisedEntities(entities, entityRefs, throwIfNotFound);
};

const getRefs = (ids, entityType = ENTITY_TYPE__LISTING) => {
  return ids.map(id => {
    if (typeof id === 'string') {
      return new UUID(id);
    }
    return id;
  }).map(uuid => {
    return {
      id: uuid,
      type: entityType,
    };
  });
};

export const getListingEntity = state => {
  return id => {
    const refs = getRefs([id], ENTITY_TYPE__LISTING);
    return get(getMarketplaceEntities(state, refs), '[0]', null);
  }
};

export const getListingsEntities = state => {
  return ids => {
    const refs = getRefs(ids, ENTITY_TYPE__LISTING);
    return getMarketplaceEntities(state, refs);
  };
};

export const getOwnListingEntity = state => {
  return id => {
    if (typeof id === 'string') {
      id = new UUID(id);
    }

    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };
};

// ================ Action creators ================ //

export const addMarketplaceEntities = sdkResponse => ({
  type: ADD_MARKETPLACE_ENTITIES,
  payload: sdkResponse,
});
