import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  IconSocialMediaYoutube,
  IconSocialMediaPinterest,
  Logo,
  ExternalLink,
  NamedLink,
} from '../../components';

import css from './Footer.module.scss';

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle, siteYoutubePage, sitePinterestPage } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });
  const goToYoutube = intl.formatMessage({ id: 'Footer.goToYoutube' });
  const goToPinterest = intl.formatMessage({ id: 'Footer.goToPinterest' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTwitter}
    >
      <IconSocialMediaTwitter />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;

  const youtubeLink = siteYoutubePage ? (
    <ExternalLink
      key="linkToYoutube"
      href={siteYoutubePage}
      className={css.icon}
      title={goToYoutube}
    >
      <IconSocialMediaYoutube />
    </ExternalLink>
  ) : null;

  const pinterestLink = sitePinterestPage ? (
    <ExternalLink
      key="linkToPinterest"
      href={sitePinterestPage}
      className={css.icon}
      title={goToPinterest}
    >
      <IconSocialMediaPinterest />
    </ExternalLink>
  ) : null;



  return [fbLink, twitterLink, instragramLink, youtubeLink, pinterestLink].filter(v => v != null);
};

const Footer = props => {
  const { rootClassName, className, intl } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.container}>
        <NamedLink name="LandingPage" className={css.logoLink}>
          <Logo
            className={css.logo}
            isFooter
          />
        </NamedLink>

        <ul className={css.list}>
          <li className={css.listItem}>
            <NamedLink name="AircraftParentLandingPage" className={css.link}>
              <FormattedMessage id="Footer.aircraftParentLanding" />
            </NamedLink>
            <NamedLink name="AboutPage" className={css.link}>
              <FormattedMessage id="Footer.toAboutPage" />
            </NamedLink>
          </li>
          <li className={css.listItem}>
            <NamedLink name="ContactPage" className={css.link}>
              <FormattedMessage id="Footer.toContactPage" />
            </NamedLink>
            <NamedLink name="TermsOfServicePage" className={css.link}>
              <FormattedMessage id="Footer.termsOfUse" />
            </NamedLink>
          </li>
          <li className={css.listItem}>
            <NamedLink name="PrivacyPolicyPage" className={css.link}>
              <FormattedMessage id="Footer.privacyPolicy" />
            </NamedLink>
            <NamedLink name="ContactPage" className={css.link}>
              <FormattedMessage id="Footer.feedback" />
            </NamedLink>
          </li>
          <li className={css.listItem}>
            <NamedLink name="AircraftRegistrationsPage" className={css.link}>
              <FormattedMessage id="Footer.searchRegistration" />
            </NamedLink>
          </li>
        </ul>

        <div className={css.sosialLinks}>
          {socialMediaLinks}
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
