import axios from 'axios';

import config from '../../config/config';

export class ContactPageService {
  submitContactForm(values) {
    return axios.post(`${config.apiEndpoint}/api/mailing/send-contact-form`, {
      f_n: values.firstName,
      l_n: values.lastName,
      e: values.email,
      r: values.reason,
      m: values.message,
    })
      .then(() => Promise.resolve())
      .catch(e => Promise.reject(e))
    ;
  }
}
