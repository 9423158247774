import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconQuote.module.css';

const IconQuote = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="26"
      fill="none"
      viewBox="0 0 37 26"
    >
      <path
        fill="#E32922"
        fillOpacity="0.15"
        d="M25.208 25.958l4.584-9.166h-9.167V.75h16.042v16.042l-4.584 9.166h-6.875zm-20.625 0l4.584-9.166H0V.75h16.042v16.042l-4.584 9.166H4.583z"
      ></path>
    </svg>
  );
};

IconQuote.defaultProps = { className: null, rootClassName: null };

const { string } = PropTypes;

IconQuote.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconQuote;
