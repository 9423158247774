import { handleJsonFetch } from '../../util/apiHelpers.js';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { currentUserShowSuccess } from '../../ducks/user.duck';
import { util as sdkUtil } from '../../util/sdkLoader';
import { createSlug } from '../../util/urlHelpers';
import {
  apiBaseUrl,
  listingsBulkUpdate,
} from '../../util/api';
import { restApiService } from '../../services/services';

// ================ Action types ================ //

export const CLEAR_UPDATED_FORM = 'app/ProfileSettingsPage/CLEAR_UPDATED_FORM';

export const UPLOAD_IMAGE_REQUEST = 'app/ProfileSettingsPage/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'app/ProfileSettingsPage/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'app/ProfileSettingsPage/UPLOAD_IMAGE_ERROR';

export const UPDATE_PROFILE_REQUEST = 'app/ProfileSettingsPage/UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'app/ProfileSettingsPage/UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'app/ProfileSettingsPage/UPDATE_PROFILE_ERROR';

export const VALIDATE_USERNAME_REQUEST = 'app/ProfileSettingsPage/VALIDATE_USERNAME_REQUEST';
export const VALIDATE_USERNAME_SUCCESS = 'app/ProfileSettingsPage/VALIDATE_USERNAME_SUCCESS';
export const VALIDATE_USERNAME_ERROR = 'app/ProfileSettingsPage/VALIDATE_USERNAME_ERROR';

// ================ Reducer ================ //

const initialState = {
  image: null,
  uploadImageError: null,
  uploadInProgress: false,
  updateInProgress: false,
  updateProfileError: null,
  isUsernameExists: false,
  validateUsernameInProgress: null,
  validateUsernameError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case UPLOAD_IMAGE_REQUEST:
      // payload.params: { id: 'tempId', file }
      return {
        ...state,
        image: { ...payload.params },
        uploadInProgress: true,
        uploadImageError: null,
      };
    case UPLOAD_IMAGE_SUCCESS: {
      // payload: { id: 'tempId', uploadedImage }
      const { id, uploadedImage } = payload;
      const { file } = state.image || {};
      const image = { id, imageId: uploadedImage.id, file, uploadedImage };
      return { ...state, image, uploadInProgress: false };
    }
    case UPLOAD_IMAGE_ERROR: {
      // eslint-disable-next-line no-console
      return { ...state, image: null, uploadInProgress: false, uploadImageError: payload.error };
    }

    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        updateInProgress: true,
        updateProfileError: null,
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        image: null,
        updateInProgress: false,
      };
    case UPDATE_PROFILE_ERROR:
      return {
        ...state,
        image: null,
        updateInProgress: false,
        updateProfileError: payload,
      };

    case CLEAR_UPDATED_FORM:
      return { ...state, updateProfileError: null, uploadImageError: null };

    case VALIDATE_USERNAME_REQUEST:
      return {
        ...state,
        isUsernameExists: false,
        validateUsernameInProgress: true,
        validateUsernameError: null,
      };

    case VALIDATE_USERNAME_SUCCESS:
      return {
        ...state,
        isUsernameExists: payload,
        validateUsernameInProgress: false,
      };

    case VALIDATE_USERNAME_ERROR:
      return {
        ...state,
        validateUsernameInProgress: false,
        validateUsernameError: payload,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const clearUpdatedForm = () => ({
  type: CLEAR_UPDATED_FORM,
});

// SDK method: images.upload
export const uploadImageRequest = params => ({ type: UPLOAD_IMAGE_REQUEST, payload: { params } });
export const uploadImageSuccess = result => ({ type: UPLOAD_IMAGE_SUCCESS, payload: result.data });
export const uploadImageError = error => ({
  type: UPLOAD_IMAGE_ERROR,
  payload: error,
  error: true,
});

// SDK method: sdk.currentUser.updateProfile
export const updateProfileRequest = params => ({
  type: UPDATE_PROFILE_REQUEST,
  payload: { params },
});
export const updateProfileSuccess = result => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: result.data,
});
export const updateProfileError = error => ({
  type: UPDATE_PROFILE_ERROR,
  payload: error,
  error: true,
});

export const validateUsernameRequest = () => ({
  type: VALIDATE_USERNAME_REQUEST,
});

export const validateUsernameSuccess = isUsernameExists => ({
  type: VALIDATE_USERNAME_SUCCESS,
  payload: isUsernameExists,
});

export const validateUsernameError = error => ({
  type: VALIDATE_USERNAME_ERROR,
  payload: error,
});

// ================ Thunk ================ //

// Images return imageId which we need to map with previously generated temporary id
export function uploadImage(actionPayload) {
  return (dispatch, getState, sdk) => {
    const id = actionPayload.id;
    dispatch(uploadImageRequest(actionPayload));

    const bodyParams = {
      image: actionPayload.file,
    };
    const queryParams = {
      expand: true,
      'fields.image': ['rectangle-crop', 'rectangle-crop2x', 'variants.rectangle-crop', 'variants.rectangle-crop2x'],
      'imageVariant.rectangle-crop': sdkUtil.objectQueryString({
        w: 180,
        h: 60,
        fit: 'scale',
      }),
      'imageVariant.rectangle-crop2x': sdkUtil.objectQueryString({
        w: 360,
        h: 120,
        fit: 'scale',
      }),
    };

    return sdk.images
      .upload(bodyParams, queryParams)
      .then(resp => {
        const uploadedImage = resp.data.data;
        dispatch(uploadImageSuccess({ data: { id, uploadedImage } }));
      })
      .catch(e => dispatch(uploadImageError({ id, error: storableError(e) })));
  };
}

export const updateProfile = actionPayload => {
  return (dispatch, getState, sdk) => {
    const { changedUserRole, ...bodyParams } = actionPayload;

    dispatch(updateProfileRequest());

    const queryParams = {
      expand: true,
      include: ['profileImage'],
      'fields.image': [
        'rectangle-crop', 'rectangle-crop2x', 'variants.rectangle-crop', 'variants.rectangle-crop2x'
      ],
      'imageVariant.rectangle-crop': sdkUtil.objectQueryString({
        w: 180,
        h: 60,
        fit: 'scale',
      }),
      'imageVariant.rectangle-crop2x': sdkUtil.objectQueryString({
        w: 360,
        h: 120,
        fit: 'scale',
      }),
    };

    return sdk.currentUser
      .updateProfile(bodyParams, queryParams)
      .then(async response => {
        if (changedUserRole) await listingsBulkUpdate(response?.data?.data?.id?.uuid);

        return response;
      })
      .then(response => {
        dispatch(updateProfileSuccess(response));

        const entities = denormalisedResponseEntities(response);
        if (entities.length !== 1) {
          throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
        }
        const currentUser = entities[0];

        // Update current user in state.user.currentUser through user.duck.js
        dispatch(currentUserShowSuccess(currentUser));
      })
      .catch(e => dispatch(updateProfileError(storableError(e))));
  };
};

export const deleteUploadedNewImage = (bucket, key) => {
  const url = new URL(`${apiBaseUrl()}/api/files/delete`);
  url.searchParams.append('bucket', bucket);
  url.searchParams.append('key', key);
  const options = {
    method: 'POST',
    credentials: 'include',
  };
  return window.fetch(url.toString(), options)
    .then(handleJsonFetch)
    .then(response => {
      return response;
    })
    .catch(e => {
      throw e;
    });
}

export const validateUsername = username => dispatch => {
  dispatch(validateUsernameRequest());
  return restApiService
    .publicInstance
    .getRaw(`/userProfile/validate-username?u_n=${createSlug(username.toLowerCase())}`)
    .then(response => {
      dispatch(validateUsernameSuccess(response.data.isExists));
      return response.data.isExists;
    })
    .catch(e => {
      dispatch(validateUsernameError(storableError(e)));
      throw e;
    })
  ;
};
