import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaTwitter.module.css';

const IconSocialMediaTwitter = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="14"
      fill="none"
      viewBox="0 0 17 14"
    >
      <path
        fill="#E32922"
        fillRule="evenodd"
        d="M16.509 2.098a6.505 6.505 0 01-1.868.502A3.236 3.236 0 0016.067.812a6.387 6.387 0 01-2.06.784 3.236 3.236 0 00-2.37-1.025 3.243 3.243 0 00-3.244 3.245c0 .251.03.502.08.743a9.222 9.222 0 01-6.69-3.395 3.22 3.22 0 00-.441 1.637c0 1.125.572 2.12 1.446 2.702a3.268 3.268 0 01-1.467-.411v.04a3.245 3.245 0 002.602 3.184 3.43 3.43 0 01-.854.11c-.21 0-.412-.02-.613-.05a3.25 3.25 0 003.034 2.25 6.498 6.498 0 01-4.028 1.386c-.271 0-.522-.01-.783-.04A9.168 9.168 0 005.66 13.43c5.966 0 9.23-4.942 9.23-9.231 0-.141 0-.282-.01-.422a6.976 6.976 0 001.628-1.678z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

IconSocialMediaTwitter.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaTwitter.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaTwitter;
