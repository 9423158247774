export const NAMESPACE = 'USER';

export const FIELD__USER_ADDITIONAL_DATA = `FIELD::${NAMESPACE}::USER_ADDITIONAL_DATA`;

export const INITIAL__USER_ADDITIONAL_DATA = {
  inProgress: false,
  error: null,
  value: {},
};

export const DEFAULT_APP_FIELDS = {
  [FIELD__USER_ADDITIONAL_DATA]: INITIAL__USER_ADDITIONAL_DATA,
};
