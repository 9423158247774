import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { StaticRouter } from 'react-router-dom';

import { IncludeMapLibraryScripts } from '../../util/includeScripts';
import Routes from '../../routing/Routes';
import routeConfiguration from '../../routing/routeConfiguration';
import { useEffects, connector } from './helpers';

const ServerAppContainer = (props) => {
  const {
    url,
    context,
    helmetContext,
  } = props;

  useEffects(props);

  return (
    <HelmetProvider context={helmetContext}>
      <IncludeMapLibraryScripts />
      <StaticRouter location={url} context={context}>
        <Routes routes={routeConfiguration()} />
      </StaticRouter>
    </HelmetProvider>
  );
};

export default connector(ServerAppContainer);
