import random from 'lodash/random';
import shuffle from 'lodash/shuffle';
import get from 'lodash/get';

import {
  restApiService,
  localStorageService,
} from '../services/services';
import {
  prepareSearchParams,
} from '../util/search';
import {
  stringify,
} from '../util/urlHelpers';
import {
  handleTransitAxiosResponse,
  handleAxiosResponse,
} from '../util/apiHelpers';
import {
  storableError,
} from '../util/errors';
import {
  getImagesParams,
} from '../util/listing';
import {
  fetchAirplanesMetadata,
} from '../modules/airplanes/airplanes.creator';
import {
  denormalisedResponseEntities,
} from '../util/data';

const SORT_LOCAL_STORAGE_KEY = 'featuredListingsSort';
const PAGE_LOCAL_STORAGE_KEY = 'featuredListingsPage';
const FEATURED_PER_PAGE = 8;

// ================ Action types ================ //

const AIR_FEATURED_REQUEST = 'app/SearchPage/AIR_FEATURED_REQUEST';
const AIR_FEATURED_SUCCESS = 'app/SearchPage/AIR_FEATURED_SUCCESS';
const AIR_FEATURED_ERROR = 'app/SearchPage/AIR_FEATURED_ERROR';

const SET_TOTAL_PAGES = 'app/SearchPage/SET_TOTAL_PAGES';

const RESET_STATE = 'app/SearchPage/RESET_STATE';

// ================ Reducer ================ //

const initialState = {
  totalPages: null,
  airFeaturedListings: [],
  airFeaturedInProgress: false,
  airFeaturedError: null,
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case AIR_FEATURED_REQUEST:
      return {
        ...state,
        airFeaturedInProgress: true,
        airFeaturedError: null,
        airFeaturedListings: [],
      };

    case AIR_FEATURED_SUCCESS:
      return {
        ...state,
        airFeaturedInProgress:  false,
        airFeaturedListings: payload,
      };

    case AIR_FEATURED_ERROR:
      return {
        ...state,
        airFeaturedInProgress: false,
        airFeaturedError: payload,
      };

    case SET_TOTAL_PAGES:
      return {
        ...state,
        totalPages: payload,
      };

    case RESET_STATE:
      return initialState;

    default:
      return state;
  }
};

// ================ Selectors ====================== //

const $subState = state => {
  return get(state, 'FeaturedListings', null);
};

export const $featuredListingsState = state => {
  const subState = $subState(state);
  return {
    data: subState.airFeaturedListings,
    isPending: subState.airFeaturedInProgress,
    error: subState.airFeaturedError,
  };
};

// ================ Actions ================ //

const airFeaturedRequest = () => ({
  type: AIR_FEATURED_REQUEST,
});

const airFeaturedSuccess = listings => ({
  type: AIR_FEATURED_SUCCESS,
  payload: listings,
});

const airFeaturedError = error => ({
  type: AIR_FEATURED_ERROR,
  payload: error,
});

const resetStateAction = () => ({
  type: RESET_STATE,
});

// ================ Action thunks ================ //

const getRandomSort = () => {
  const variants = [
    'pub_featured_order_1,pub_featured_order_2,pub_featured_order_3',
    'pub_featured_order_1,pub_featured_order_3,pub_featured_order_2',
    'pub_featured_order_2,pub_featured_order_1,pub_featured_order_3',
    'pub_featured_order_2,pub_featured_order_3,pub_featured_order_1',
    'pub_featured_order_3,pub_featured_order_1,pub_featured_order_2',
    'pub_featured_order_3,pub_featured_order_2,pub_featured_order_1',
  ];
  return shuffle(variants)[random(0, variants.length - 1)];
};

const getStoredSort = () => {
  let storedSort = localStorageService.getItem(SORT_LOCAL_STORAGE_KEY, null);
  if (!storedSort) {
    storedSort = getRandomSort();
    localStorageService.setItem(SORT_LOCAL_STORAGE_KEY, storedSort);
  }
  return storedSort;
};

const getStoredCurrentPage = () => {
  let storedPage = localStorageService.getItem(PAGE_LOCAL_STORAGE_KEY, 0);
  storedPage++;
  localStorageService.setItem(PAGE_LOCAL_STORAGE_KEY, storedPage);
  return storedPage;
};

const getFeaturedTotalPages = params => async (dispatch, getState) => {
  try {
    const resp = await restApiService.publicInstance
      .getRaw(`search/air-featured-total-pages?${stringify(params)}`);
    const parsedResp = handleAxiosResponse(resp);
    return parsedResp.totalPages;
  } catch (e) {
    throw e;
  }
};

const getFeaturedCurrentPage = allParams => async dispatch => {
  try {
    const storedCurrentPage = getStoredCurrentPage();
    const featuredTotalPages = await dispatch(getFeaturedTotalPages({ ...allParams, page: 1 }));
    const page = storedCurrentPage % featuredTotalPages
    return page ? page : featuredTotalPages;
  } catch (e) {
    throw e;
  }
};

export const featuredListingsForLandingPage = () => async dispatch => {
  const searchParams = {
    include: ['images'],
    ...getImagesParams(),
  };
  try {
    dispatch(airFeaturedRequest());
    let allParams = prepareSearchParams({}, searchParams);
    allParams = {
      ...allParams,
      sort: getRandomSort(),
    };
    const featuredResp = await restApiService.publicInstance
      .getRaw(`search/air-featured?${stringify(allParams)}`);
    const parsedFeaturedResp = handleTransitAxiosResponse(featuredResp);
    const listings = denormalisedResponseEntities(parsedFeaturedResp);
    dispatch(fetchAirplanesMetadata(parsedFeaturedResp.data.data));
    dispatch(airFeaturedSuccess(shuffle(listings)));
    return parsedFeaturedResp;
  } catch (e) {
    dispatch(airFeaturedError(storableError(e)));
    throw e;
  }
};

export const searchAirFeaturedListings = (urlParams, searchParams) => async dispatch => {
  try {
    dispatch(airFeaturedRequest());
    const sort = getStoredSort();
    let allParams = prepareSearchParams(urlParams, searchParams);
    allParams = {
      ...allParams,
      sort,
      per_page: FEATURED_PER_PAGE,
    };
    const currentPage = await dispatch(getFeaturedCurrentPage(allParams));
    if (currentPage === 0) {
      return Promise.resolve();
    }
    const featuredResp = await restApiService.publicInstance
      .getRaw(`search/air-featured?${stringify({ ...allParams, page: currentPage })}`);
    const parsedFeaturedResp = handleTransitAxiosResponse(featuredResp);
    let listings = denormalisedResponseEntities(parsedFeaturedResp);
    let ids = parsedFeaturedResp.data.data.map(l => l.id);
    const totalItems = get(parsedFeaturedResp, 'data.meta.totalItems', 0);

    if (totalItems > FEATURED_PER_PAGE && ids.length < FEATURED_PER_PAGE) {
      const needCount = FEATURED_PER_PAGE - ids.length;
      const needParams = { ...allParams, page: 1, per_page: needCount };
      const needResp = await restApiService.publicInstance
        .getRaw(`search/air-featured?${stringify(needParams)}`);
      const parsedNeedResp = handleTransitAxiosResponse(needResp);
      const needListings = denormalisedResponseEntities(parsedNeedResp);
      listings = [ ...listings, ...needListings ];
    }
    dispatch(fetchAirplanesMetadata(listings));
    dispatch(airFeaturedSuccess(listings));
    return parsedFeaturedResp;
  } catch (e) {
    dispatch(airFeaturedError(storableError(e)));
    throw e;
  }
};

export const resetState = () => dispatch => {
  dispatch(resetStateAction());
};
