/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

import {
  dateKey,
  yearOptions,
  scoreOptions,
  inspectExpiryYears,
  buildYearOptions,
  FILTER_KEY_MAKER,
  FILTER_KEY_MODEL,
  FILTER_KEY_YEAR,
  FILTER_KEY_PRICE,
  FILTER_KEY_AIRFRAME_TIME,
  FILTER_KEYS_ENGINE_HOURS,
  FILTER_KEY_CATEGORY,
  FILTER_KEY_SEATS,
  FILTER_KEY_EXPIRY_YEAR,
  FILTER_KEY_MODEL_FAMILY,
  FILTER_KEY_PRICE_DECREASE,
  FILTER_KEY_PRICE_INCREASE,
  FILTER_KEY_PRICE_CHANGED,
  FILTER_KEY_HOURS_FLOWN,
  FILTER_KEY_ESTIMATE_EVALUATION_TEXT,
  FILTER_KEY_AIRCRAFT_LISTING_TYPE,
  FILTER_KEY_REAL_ESTATE_LISTING_TYPE,
  FILTER_KEY_HOME_TYPE,
  FILTER_KEY_INDOOR_FEATURES,
  FILTER_KEY_ROOMS,
  FILTER_KEY_BASEMENT,
  FILTER_KEY_APPLIANCES,
  FILTER_KEY_FLOOR_COVERING,
  FILTER_KEY_ARCHITECTURAL_STYLE,
  FILTER_KEY_EXTERIOR,
  FILTER_KEY_PARKING,
  FILTER_KEY_ROOF,
  FILTER_KEY_COOLING,
  FILTER_KEY_HEATING,
  FILTER_KEY_HEATING_FUEL,
  FILTER_KEY_OUTDOOR_AMENITIES,
  FILTER_KEY_AVIATION_AMENITIES,
  FILTER_KEY_BUILDING_AMENITIES,
  FILTER_KEY_VIEW,
  FILTER_KEY_MONTHLY_HOA_DUES_TYPE,
  HOME_TYPE_KEY_SINGLE_FAMILY,
  HOME_TYPE_KEY_CONDO,
  HOME_TYPE_KEY_COMMERCIAL,
  HOME_TYPE_KEY_TOWNHOUSE,
  HOME_TYPE_KEY_MULTI_FAMILY,
  HOME_TYPE_KEY_APARTMENT,
  HOME_TYPE_KEY_MOBILE,
  HOME_TYPE_KEY_COOP,
  HOME_TYPE_KEY_LAND,
  HOME_TYPE_KEY_HANGAR,
  HOME_TYPE_KEY_FARM,
  HOME_TYPE_KEY_OTHER,
  FILTER_KEY_YEAR_BUILT,
  REAL_ESTATE_KEY_FOR_SALE,
  REAL_ESTATE_KEY_RENTAL,
  FILTER_KEY_HAS_ACCIDENT_HISTORY,
} from '../containers/SearchPage/SearchPage.constants';

const keywordFilter = {
  id: 'keyword',
  label: 'Keyword',
  type: 'KeywordFilter',
  active: false,
  group: 'primary',
  // Note: KeywordFilter is fixed filter,
  // you can't change "queryParamNames: ['keywords'],"
  queryParamNames: ['pub_keywords'],
  // NOTE: If you are ordering search results by distance
  // the keyword search can't be used at the same time.
  // You can turn on/off ordering by distance from config.js file.
  config: {},
}

const categoryFilter = {
  id: 'category',
  label: 'Category',
  type: 'CategoryFilter',
  active: true,
  group: 'primary',
  queryParamNames: [FILTER_KEY_CATEGORY],
  config: {},
}

const makeModelFilter = {
  id: 'make_model',
  label: 'Make / Model',
  type: 'MakeModalFilter',
  active: true,
  group: 'primary',
  queryParamNames: [FILTER_KEY_MAKER, FILTER_KEY_MODEL_FAMILY, FILTER_KEY_MODEL],
  config: {},
}

const yearFilter = {
  id: 'year',
  label: 'Year',
  type: 'RangeFilter',
  active: true,
  group: 'primary',
  queryParamNames: [FILTER_KEY_YEAR],
  config: {
    rangeLabel: 'Year range:',
    schemaType: 'enum',
    options: yearOptions,
    min: yearOptions[0].key,
    max: yearOptions[yearOptions.length - 1].key,
    step: 1,
    isFormatted: false,
  },
}

const priceFilter = {
  id: 'price',
  label: 'Price',
  type: 'AircraftPriceFilter',
  active: true,
  group: 'primary',
  // Note: PriceFilter is fixed filter,
  // you can't change "queryParamNames: ['price'],"
  queryParamNames: [FILTER_KEY_PRICE],
  // Price filter configuration
  // Note: unlike most prices this is not handled in subunits
  config: {
    min: 0,
    max: 100 * 1000 * 1000,
    step: 1,
  },
}

const airframeTotalTimeFilter = {
  id: 'airframeTotalTime',
  label: 'Airframe Total Time',
  type: 'RangeFilter',
  active: true,
  group: 'primary',
  queryParamNames: [FILTER_KEY_AIRFRAME_TIME],
  config: {
    rangeLabel: 'Airframe time range',
    schemaType: 'enum',
    min: 0,
    max: 10000,
    step: 1,
  },
}

const engineHoursFilter = {
  id: 'engine_hours',
  label: 'Engine Hours',
  type: 'EngineHoursFilter',
  active: true,
  group: 'primary',
  queryParamNames: FILTER_KEYS_ENGINE_HOURS,
  config: {
    min: 1,
    max: 10000,
    step: 1,
  }
}

const aircraftListingTypeFilter = {
  id: 'aircraftListingType',
  label: 'Aircraft Listing Type',
  type: 'SelectMultipleFilter',
  active: true,
  isTwoColums: true,
  group: 'secondary',
  queryParamNames: [FILTER_KEY_AIRCRAFT_LISTING_TYPE],
  config: {
    schemaType: 'enum',
    options: [
      { key: 'For Sale', label: 'For Sale' },
      { key: 'For Lease', label: 'For Lease' },
      { key: 'For Fractional Ownership', label: 'For Fractional Ownership' },
      { key: 'For Partnership', label: 'For Partnership' },
    ],
  },
}

const estimateEvaluationTextFilter = {
  id: 'estimateEvaluationText',
  label: 'Deal Rating',
  type: 'DealRatingFilter',
  active: true,
  isTwoColums: false,
  group: 'primary',
  queryParamNames: [FILTER_KEY_ESTIMATE_EVALUATION_TEXT],
  config: {
    schemaType: 'enum',
    options: [
      { key: 'Great Deal', label: 'Great Deal' },
      { key: 'Good Deal', label: 'Good Deal' },
      { key: 'Fair Deal', label: 'Fair Deal' },
      { key: 'High', label: 'High' },
      { key: 'Overpriced', label: 'Overpriced' },
      { key: 'No Evaluation Available', label: 'No Evaluation Available' },
    ],
  },
}

const conditionFilter = {
  id: 'condition',
  label: 'Condition',
  type: 'SelectMultipleFilter',
  active: true,
  isTwoColums: true,
  group: 'secondary',
  queryParamNames: ['pub_condition'],
  config: {
    schemaType: 'enum',
    options: [
      { key: 'New', label: 'New' },
      { key: 'Project', label: 'Project' },
      { key: 'Salvage', label: 'Salvage' },
      { key: 'Used', label: 'Used' },
    ],
  },
}

const specialUseFilter  = {
  id: 'specialUse',
  label: 'Special use',
  type: 'SelectMultipleFilter',
  active: true,
  isTwoColums: true,
  group: 'secondary',
  queryParamNames: ['pub_special_use'],
  config: {
    schemaType: 'multi-enum',
    searchMode: 'has_any',
    options: [
      { key: 'Aerobatic', label: 'Aerobatic' },
      { key: 'Agricultural', label: 'Agricultural' },
      { key: 'AmphibiousFloat', label: 'Amphibious / Float' },
      { key: 'Antique', label: 'Antique' },
      { key: 'Experimental', label: 'Experimental' },
      { key: 'Homebuilt', label: 'Homebuilt' },
      { key: 'LightSport', label: 'Light Sport' },
      { key: 'MilitaryWarbird', label: 'Military / Warbird' },
      { key: 'Utility', label: 'Utility' },
      { key: 'Ultralight', label: 'Ultralight' },
    ],
  },
}

const flightRulesFilter = {
  id: 'flightRules',
  label: 'Flight rules',
  type: 'SelectMultipleFilter',
  active: true,
  isTwoColums: true,
  group: 'secondary',
  queryParamNames: ['pub_flight_rules'],
  config: {
    schemaType: 'enum',
    options: [
      { key: 'VFR', label: 'VFR' },
      { key: 'IFR', label: 'IFR' },
    ],
  },
}

const hasAccidentHistory = {
  id: 'hasAccidentHistory',
  label: 'Has Accident / Incident History',
  type: 'SelectMultipleFilter',
  active: true,
  isTwoColums: true,
  group: 'secondary',
  queryParamNames: [FILTER_KEY_HAS_ACCIDENT_HISTORY],
  config: {
    schemaType: 'boolean',
    options: [
      { key: 'true', label: 'Yes' },
      { key: 'false', label: 'No' },
    ],
  },
}


const priceChangeFilter = {
  id: 'priceChange',
  label: 'Price change',
  type: 'SelectMultipleFilter',
  active: true,
  isTwoColums: true,
  group: 'secondary',
  queryParamNames: [FILTER_KEY_PRICE_CHANGED],
  config: {
    schemaType: 'enum',
    options: [
      { key: 'drops', label: 'Price Drops' },
      { key: 'increase', label: 'Price Increase' },
    ]
  },
}

const priceDecreaseFilter = {
    id: 'priceDecrease',
    label: 'Price Decrease %',
    type: 'RangeFilter',
    active: true,
    group: 'secondary',
    queryParamNames: [FILTER_KEY_PRICE_DECREASE],
    config: {
      min: 0,
      max: 100,
      step: 1,
    },
  }

const priceIncreaseFilter = {
  id: 'priceIncrease',
  label: 'Price Increase %',
  type: 'RangeFilter',
  active: true,
  group: 'secondary',
  queryParamNames: [FILTER_KEY_PRICE_INCREASE],
  config: {
    min: 0,
    max: 100,
    step: 1,
  },
}

const hoursFlownFilter = {
  id: 'hoursFlown',
  label: 'Total hours flown in the past 6 months',
  type: 'RangeFilter',
  active: true,
  group: 'secondary',
  queryParamNames: [FILTER_KEY_HOURS_FLOWN],
  config: {
    min: 0,
    max: 4000,
    step: 1,
  },
}

const seatsFilter ={
  id: 'seats',
  label: 'Number of seats',
  type: 'RangeFilter',
  active: true,
  group: 'secondary',
  queryParamNames: [FILTER_KEY_SEATS],
  config: {
    min: 0,
    max: 500,
    step: 1,
  },
}

const listingAgeFilter = {
  id: 'listingAge',
  label: 'Days on Aircraft for Sale',
  type: 'RadioGroupFilter',
  active: true,
  group: 'secondary',
  queryParamNames: ['createdAtStart'],
  config: {
    options: [
      { key: dateKey(1, 'day'), label: '1 day' },
      { key: dateKey(7, 'days'), label: '7 days' },
      { key: dateKey(14, 'days'), label: '14 days' },
      { key: dateKey(30, 'days'), label: '30 days' },
      { key: dateKey(90, 'days'), label: '90 days' },
      { key: dateKey(6, 'months'), label: '6 months' },
      { key: dateKey(12, 'months'), label: '12 months' },
      { key: dateKey(24, 'months'), label: '24 months' },
      { key: dateKey(36, 'months'), label: '36 months' },
    ],
  },
}

const sellerTypeFilter = {
  id: 'pub_sellerType',
  label: 'Seller Type',
  type: 'SelectMultipleFilter',
  active: true,
  group: 'secondary',
  queryParamNames: ['pub_sellerType'],
  isTwoColums: true,
  config: {
    schemaType: 'enum',
    options: [
      { key: 'personal', label: 'Private' },
      { key: 'professional', label: 'Dealer' },
    ],
  },
}

const addEquipFilter = {
  id: 'addlEquip',
  label: 'Additional Equipment',
  type: 'SelectMultipleFilter',
  active: true,
  isTwoColums: false,
  group: 'secondary',
  queryParamNames: ['pub_addl_equip'],
  config: {
    schemaType: 'multi-enum',
    searchMode: 'has_any',
    options: [
      { key: 'Air Conditioning', label: 'Air Conditioning' },
      { key: 'Dual-controls', label: 'Dual-controls' },
      { key: 'Wi-fi', label: 'Wi-fi' },
      { key: 'Sirius XM Weather', label: 'Sirius XM Weather' },
    ],
  },
}

const intScoreFilter = {
  id: 'intScore',
  label: 'Interior Score',
  type: 'SelectMultipleFilter',
  active: true,
  isTwoColums: true,
  group: 'secondary',
  queryParamNames: ['pub_int_score'],
  config: {
    schemaType: 'enum',
    options: scoreOptions(10),
  },
}

const paintScoreFilter = {
  id: 'paintScore',
  label: 'Paint Score',
  type: 'SelectMultipleFilter',
  active: true,
  isTwoColums: true,
  group: 'secondary',
  queryParamNames: ['pub_paint_score'],
  config: {
    schemaType: 'enum',
    options: scoreOptions(10),
  },
}

const inspectExpiryYearFilter = {
  id: 'inspectExpiryYear',
  label: 'Inspection Expiry',
  type: 'RangeFilter',
  active: true,
  group: 'secondary',
  queryParamNames: [FILTER_KEY_EXPIRY_YEAR],
  config: {
    min: inspectExpiryYears[0].key,
    max: inspectExpiryYears[inspectExpiryYears.length - 1].key,
    step: 1,
    isFormatted: false,
  },
}

const realEstateListingTypeFilter = {
  id: 'realEstateListingType',
  label: 'Real Estate Listing Type',
  type: 'SelectMultipleFilter',
  active: true,
  isTwoColums: true,
  group: 'secondary',
  queryParamNames: [FILTER_KEY_REAL_ESTATE_LISTING_TYPE],
  config: {
    schemaType: 'enum',
    options: [
      { key: REAL_ESTATE_KEY_FOR_SALE, label: REAL_ESTATE_KEY_FOR_SALE },
      { key: REAL_ESTATE_KEY_RENTAL, label: REAL_ESTATE_KEY_RENTAL },
    ],
  },
}

const homeTypeFilter = {
  id: 'homeType',
  label: 'Home Type',
  type: 'HomeTypeFilter',
  active: true,
  isTwoColums: true,
  group: 'primary',
  queryParamNames: [FILTER_KEY_HOME_TYPE],
  config: {
    schemaType: 'enum',
    options: [
      { key: HOME_TYPE_KEY_SINGLE_FAMILY, label: 'Single family' },
      { key: HOME_TYPE_KEY_CONDO, label: 'Condo' },
      { key: HOME_TYPE_KEY_TOWNHOUSE, label: 'Townhouse' },
      { key: HOME_TYPE_KEY_MULTI_FAMILY, label: 'Multi family' },
      { key: HOME_TYPE_KEY_APARTMENT, label: 'Apartment' },
      { key: HOME_TYPE_KEY_MOBILE, label: 'Mobile / Manufactured' },
      { key: HOME_TYPE_KEY_COOP, label: 'Coop Unit' },
      { key: HOME_TYPE_KEY_LAND, label: 'Land' },
      { key: HOME_TYPE_KEY_HANGAR, label: 'Hangar' },
      { key: HOME_TYPE_KEY_FARM, label: 'Farm' },
      { key: HOME_TYPE_KEY_COMMERCIAL, label: 'Commercial' },
      { key: HOME_TYPE_KEY_OTHER, label: 'Other' },
    ],
  },
}

const monthlyHOADuesFilter = {
  id: 'monthlyHOADues',
  label: 'Monthly HOA Dues',
  type: 'SelectMultipleFilter',
  active: true,
  isTwoColums: true,
  group: 'secondary',
  queryParamNames: [FILTER_KEY_MONTHLY_HOA_DUES_TYPE],
  config: {
    schemaType: 'enum',
    options: [
      { key: 'yes', label: 'Yes' },
      { key: 'no', label: 'No' },
      { key: 'unsure', label: 'Unsure' },
    ],
  },
}

const roomsFilter  = {
  id: 'rooms',
  label: 'Rooms',
  type: 'SelectMultipleFilter',
  active: true,
  isTwoColums: true,
  group: 'secondary',
  queryParamNames: [FILTER_KEY_ROOMS],
  config: {
    schemaType: 'multi-enum',
    searchMode: 'has_any',
    options: [
      { key: 'breakfastNook', label: 'Breakfast nook' },
      { key: 'diningRoom', label: 'Dining room' },
      { key: 'familyRoom', label: 'Family room' },
      { key: 'landryRoom', label: 'Landry room' },
      { key: 'library', label: 'Library' },
      { key: 'masterBath', label: 'Master bath' },
      { key: 'mudRoom', label: 'Mud room' },
      { key: 'office', label: 'Office' },
      { key: 'pantry', label: 'Pantry' },
      { key: 'recreationRoom', label: 'Recreation room' },
      { key: 'workshop', label: 'Workshop' },
      { key: 'solariumAtrium', label: 'Solarium / Atrium' },
      { key: 'sunRoom', label: 'Sun room' },
      { key: 'walkInCloset', label: 'Walk-in closet' },
    ],
  },
}

const indoorFeaturesFilter = {
  id: 'indoorFeatures',
  label: 'Indoor features',
  type: 'SelectMultipleFilter',
  active: true,
  isTwoColums: true,
  group: 'secondary',
  queryParamNames: [FILTER_KEY_INDOOR_FEATURES],
  config: {
    schemaType: 'enum',
    options: [
      { key: 'attic', label: 'Attic' },
      { key: 'cableReady', label: 'Cable ready' },
      { key: 'ceilingFans', label: 'Ceiling fans' },
      { key: 'doublePaneStormWindows', label: 'Double pane / storm windows' },
      { key: 'fireplace', label: 'Fireplace' },
      { key: 'hotTub', label: 'Hot tub' },
      {key: 'securitySystem', label: 'Security system'},
      { key: 'skylights', label: 'Skylights' },
      { key: 'sauna', label: 'Sauna' },
      { key: 'vaultedCeiling', label: 'Vaulted ceiling' },
      { key: 'wetBar', label: 'Wet bar' },
      { key: 'wiredInternet', label: 'Wired internet' },
    ],
  },
}

const basementFilter = {
  id: 'basement',
  label: 'Basement',
  type: 'SelectMultipleFilter',
  active: true,
  isTwoColums: true,
  group: 'secondary',
  queryParamNames: [FILTER_KEY_BASEMENT],
  config: {
    schemaType: 'enum',
    options: [
      { key: 'finished', label: 'Finished' },
      { key: 'partiallyFinished', label: 'Partially finished' },
      { key: 'unfinished', label: 'Unfinished' },
      { key: 'None', label: 'None' },
    ],
  },
}

const appliancesFilter = {
  id: 'appliances',
  label: 'Appliances',
  type: 'SelectMultipleFilter',
  active: true,
  isTwoColums: true,
  group: 'secondary',
  queryParamNames: [FILTER_KEY_APPLIANCES],
  config: {
    schemaType: 'enum',
    options: [
      { key: 'dishwasher', label: 'Dishwasher' },
      { key: 'dryer', label: 'Dryer' },
      { key: 'freezer', label: 'Freezer' },
      { key: 'garbageDisposal', label: 'Garbage disposal' },
      {key: 'microwave', label: 'Microwave'},
      { key: 'rangeOven', label: 'Range / Oven' },
      { key: 'refrigerator', label: 'Refrigerator' },
      { key: 'trashCompactor', label: 'Trash compactor' },
      { key: 'washer', label: 'Washer' },
    ],
  },
}

const floorCoveringFilter = {
  id: 'floorCovering',
  label: 'Floor covering',
  type: 'SelectMultipleFilter',
  active: true,
  isTwoColums: true,
  group: 'secondary',
  queryParamNames: [FILTER_KEY_FLOOR_COVERING],
  config: {
    schemaType: 'enum',
    options: [
      { key: 'carpet', label: 'Carpet' },
      { key: 'concrete', label: 'Concrete' },
      { key: 'hardwood', label: 'Hardwood' },
      { key: 'laminate', label: 'laminate' },
      { key: 'linoleumVinyl', label: 'Linoleum / Vinyl' },
      { key: 'slate', label: 'Slate' },
      { key: 'softwood', label: 'Softwood' },
      { key: 'tile', label: 'Tile' },
      { key: 'other', label: 'Other' },
    ],
  },
}

const architecturalStyleFilter = {
  id: 'architecturalStyle',
  label: 'Architectural style',
  type: 'SelectMultipleFilter',
  active: true,
  isTwoColums: true,
  group: 'secondary',
  queryParamNames: [FILTER_KEY_ARCHITECTURAL_STYLE],
  config: {
    schemaType: 'enum',
    options: [
      { key: 'bungalow', label: 'Bungalow' },
      { key: 'capeCCod', label: 'Cape Cod' },
      { key: 'colonial', label: 'Colonial' },
      { key: 'contemporary', label: 'Contemporary' },
      { key: 'craftsman', label: 'Craftsman' },
      { key: 'french', label: 'French' },
      { key: 'georgian', label: 'Georgian' },
      { key: 'loft', label: 'Modern' },
      { key: 'queenAnneVictorian', label: 'Queen Anne / Victorian' },
      { key: 'ranchRambler', label: 'Ranch / Rambler' },
      { key: 'santaFePueblo', label: ' Santa Fe / Pueblo' },
      { key: 'spanish', label: 'Spanish' },
      { key: 'splitLevel', label: 'Split-level' },
      { key: 'tudor', label: 'Tudor' },
      { key: 'other', label: 'Other' },
    ],
  },
}

const exteriorFilter = {
  id: 'exterior',
  label: 'Exterior',
  type: 'SelectMultipleFilter',
  active: true,
  isTwoColums: true,
  group: 'secondary',
  queryParamNames: [FILTER_KEY_EXTERIOR],
  config: {
    schemaType: 'enum',
    options: [
      { key: 'brick', label: 'Brick' },
      { key: 'cementConcrete', label: 'Cement / Concrete' },
      { key: 'composition', label: 'Colonial' },
      { key: 'metal', label: 'Metal' },
      { key: 'shingle', label: 'Shingle' },
      { key: 'stone', label: 'Stone' },
      { key: 'stucco', label: 'Stucco' },
      { key: 'vinyl', label: 'Vinyl' },
      { key: 'wood', label: 'Wood' },
      { key: 'other', label: 'Other' },
    ],
  },
}

const parkingFilter = {
  id: 'parking',
  label: 'Parking',
  type: 'SelectMultipleFilter',
  active: true,
  isTwoColums: true,
  group: 'secondary',
  queryParamNames: [FILTER_KEY_PARKING],
  config: {
    schemaType: 'enum',
    options: [
      { key: 'carport', label: 'Carport' },
      { key: 'garageAttached', label: 'Garage - attached' },
      { key: 'garageDetached', label: 'Garage - detached' },
      { key: 'offStreet', label: 'Off-street' },
      { key: 'onStreet', label: 'On-street' },
      { key: 'none', label: 'None' },
    ],
  },
}

const roofFilter = {
  id: 'roof',
  label: 'Roof',
  type: 'SelectMultipleFilter',
  active: true,
  isTwoColums: true,
  group: 'secondary',
  queryParamNames: [FILTER_KEY_ROOF],
  config: {
    schemaType: 'enum',
    options: [
      { key: 'asphalt', label: 'Asphalt' },
      { key: 'builtUp', label: 'Built-up' },
      { key: 'composition', label: 'Composition' },
      { key: 'metal', label: 'Metal' },
      { key: 'shakeShingle', label: 'Shake / shingle' },
      { key: 'slate', label: 'Slate' },
      { key: 'tile', label: 'Tile' },
      { key: 'other', label: 'Other' },
    ],
  },
}

const coolingFilter = {
  id: 'cooling',
  label: 'Cooling',
  type: 'SelectMultipleFilter',
  active: true,
  isTwoColums: true,
  group: 'secondary',
  queryParamNames: [FILTER_KEY_COOLING],
  config: {
    schemaType: 'enum',
    options: [
      { key: 'central', label: 'Central' },
      { key: 'evaporative', label: 'Evaporative' },
      { key: 'geothermal', label: 'Geothermal' },
      { key: 'heatPump', label: 'Heat pump' },
      { key: 'refrigeration', label: 'Refrigeration' },
      { key: 'solar', label: 'Solar' },
      { key: 'wall', label: 'Wall' },
      { key: 'other', label: 'Other' },
      { key: 'none', label: 'None' },
    ],
  },
}

const heatingFilter = {
  id: 'heating',
  label: 'Heating',
  type: 'SelectMultipleFilter',
  active: true,
  isTwoColums: true,
  group: 'secondary',
  queryParamNames: [FILTER_KEY_HEATING],
  config: {
    schemaType: 'enum',
    options: [
      { key: 'baseboard', label: 'Baseboard' },
      { key: 'forcedAir', label: 'Forced air' },
      { key: 'geothermal', label: 'Geothermal' },
      { key: 'heatPump', label: 'Heat pump' },
      { key: 'radiant', label: 'Radiant' },
      { key: 'stove', label: 'Stove' },
      { key: 'wall', label: 'Wall' },
      { key: 'other', label: 'Other' },
    ],
  },
}

const heatingFuelFilter = {
  id: 'heatingFuel',
  label: 'Heating fuel',
  type: 'SelectMultipleFilter',
  active: true,
  isTwoColums: true,
  group: 'secondary',
  queryParamNames: [FILTER_KEY_HEATING_FUEL],
  config: {
    schemaType: 'enum',
    options: [
      { key: 'Coal', label: 'Coal' },
      { key: 'Electric', label: 'Electric' },
      { key: 'Gas', label: 'Gas' },
      { key: 'Oil', label: 'Oil' },
      { key: 'Propane / Butane', label: 'Propane / Butane' },
      { key: 'Solar', label: 'Solar' },
      { key: 'Wood / Pellet', label: 'Wood / Pellet' },
      { key: 'other', label: 'Other' },
      { key: 'none', label: 'None' },
    ],
  },
}

const viewFilter = {
  id: 'view',
  label: 'View',
  type: 'SelectMultipleFilter',
  active: true,
  isTwoColums: true,
  group: 'secondary',
  queryParamNames: [FILTER_KEY_VIEW], // Replace FILTER_KEY_VIEW with your actual query param name
  config: {
    schemaType: 'enum',
    options: [
      { key: 'city', label: 'City' },
      { key: 'mountain', label: 'Mountain' },
      { key: 'park', label: 'Park' },
      { key: 'territorial', label: 'Territorial' },
      { key: 'water', label: 'Water' },
      { key: 'none', label: 'None' },
    ],
  },
};

const buildingAmenitiesFilter = {
  id: 'buildingAmenities',
  label: 'Building Amenities',
  type: 'SelectMultipleFilter',
  active: true,
  isTwoColums: true,
  group: 'secondary',
  queryParamNames: [FILTER_KEY_BUILDING_AMENITIES], // Replace FILTER_KEY_BUILDING_AMENITIES with your actual query param name
  config: {
    schemaType: 'enum',
    options: [
      { key: 'assistedLivingCommunity', label: 'Assisted Living Community' },
      { key: 'basketballCourt', label: 'Basketball Court' },
      { key: 'controlledAccess', label: 'Controlled Access' },
      { key: 'disabledAccess', label: 'Disabled Access' },
      { key: 'doorman', label: 'Doorman' },
      { key: 'elevator', label: 'Elevator' },
      { key: 'fitnessCenter', label: 'Fitness Center' },
      { key: 'nearTransportation', label: 'Near Transportation' },
      { key: 'over55Community', label: 'Over 55+ Community' },
      { key: 'sportsCourt', label: 'Sports Court' },
      { key: 'storage', label: 'Storage' },
      { key: 'tennisCourt', label: 'Tennis Court' },
      { key: 'pickleballCourt', label: 'Pickleball Court' },
    ],
  },
};

const aviationAmenitiesFilter = {
  id: 'aviationAmenities',
  label: 'Aviation Amenities',
  type: 'SelectMultipleFilter',
  active: true,
  isTwoColums: true,
  group: 'secondary',
  queryParamNames: [FILTER_KEY_AVIATION_AMENITIES], // Replace FILTER_KEY_AVIATION_AMENITIES with your actual query param name
  config: {
    schemaType: 'enum',
    options: [
      { key: 'hangar', label: 'Hangar' },
      { key: 'privateRunway', label: 'Private Runway' },
      { key: 'publicRunway', label: 'Public Runway' },
      { key: 'apMechanic', label: 'A&P Mechanic' },
      { key: 'fuel', label: 'Fuel' },
      { key: 'flyInCommunity', label: 'Fly-in Community' },
    ],
  },
};

const outdoorAmenitiesFilter = {
  id: 'outdoorAmenities',
  label: 'Outdoor Amenities',
  type: 'SelectMultipleFilter',
  active: true,
  isTwoColums: true,
  group: 'secondary',
  queryParamNames: [FILTER_KEY_OUTDOOR_AMENITIES], // Replace FILTER_KEY_OUTDOOR_AMENITIES with your actual query param name
  config: {
    schemaType: 'enum',
    options: [
      { key: 'balconyPatio', label: 'Balcony / Patio' },
      { key: 'bbqArea', label: 'BBQ Area' },
      { key: 'deck', label: 'Deck' },
      { key: 'dock', label: 'Dock' },
      { key: 'fencedYard', label: 'Fenced Yard' },
      { key: 'garden', label: 'Garden' },
      { key: 'greenhouse', label: 'Greenhouse' },
      { key: 'hotTubSpa', label: 'Hot Tub / Spa' },
      { key: 'lawn', label: 'Lawn' },
      { key: 'pond', label: 'Pond' },
      { key: 'pool', label: 'Pool' },
      { key: 'porch', label: 'Porch' },
      { key: 'rvParking', label: 'RV Parking' },
      { key: 'sauna', label: 'Sauna' },
      { key: 'sprinklerSystem', label: 'Sprinkler System' },
      { key: 'waterfront', label: 'Waterfront' },
    ],
  },
};

// const yearFilter = {
//   id: 'year',
//   label: 'Year',
//   type: 'RangeFilter',
//   active: true,
//   group: 'primary',
//   queryParamNames: [FILTER_KEY_YEAR],
//   config: {
//     rangeLabel: 'Year range:',
//     schemaType: 'enum',
//     options: yearOptions,
//     min: yearOptions[0].key,
//     max: yearOptions[yearOptions.length - 1].key,
//     step: 1,
//     isFormatted: false,
//   },
// }

const yearBuiltFilter = {
  id: 'year_built',
  label: 'Year',
  type: 'RangeFilter',
  active: true,
  group: 'primary',
  queryParamNames: [FILTER_KEY_YEAR_BUILT],
  config: {
    rangeLabel: 'Year range:',
    schemaType: 'enum',
    options: buildYearOptions,
    min: buildYearOptions[0].key,
    max: buildYearOptions[buildYearOptions.length - 1].key,
    step: 1,
    isFormatted: false,
  },
}

export const filters = [
  keywordFilter,
  categoryFilter,
  makeModelFilter,
  yearFilter,
  priceFilter,
  airframeTotalTimeFilter,
  engineHoursFilter,
  aircraftListingTypeFilter,
  estimateEvaluationTextFilter,
  conditionFilter,
  specialUseFilter,
  flightRulesFilter,
  hasAccidentHistory,
  priceChangeFilter,
  priceDecreaseFilter,
  priceIncreaseFilter,
  hoursFlownFilter,
  seatsFilter,
  listingAgeFilter,
  sellerTypeFilter,
  addEquipFilter,
  intScoreFilter,
  paintScoreFilter,
  inspectExpiryYearFilter,
];

export const filtersMobile = [
  keywordFilter,
  estimateEvaluationTextFilter,
  categoryFilter,
  makeModelFilter,
  yearFilter,
  priceFilter,
  airframeTotalTimeFilter,
  engineHoursFilter,
  aircraftListingTypeFilter,
  conditionFilter,
  specialUseFilter,
  flightRulesFilter,
  hasAccidentHistory,
  priceChangeFilter,
  priceDecreaseFilter,
  priceIncreaseFilter,
  hoursFlownFilter,
  seatsFilter,
  listingAgeFilter,
  sellerTypeFilter,
  addEquipFilter,
  intScoreFilter,
  paintScoreFilter,
  inspectExpiryYearFilter,
];

export const realEstateFilters = [
  realEstateListingTypeFilter,
  homeTypeFilter,
  priceFilter,
  yearBuiltFilter,
  roomsFilter,
  indoorFeaturesFilter,
  basementFilter,
  appliancesFilter,
  floorCoveringFilter,
  architecturalStyleFilter,
  exteriorFilter,
  parkingFilter,
  roofFilter,
  coolingFilter,
  heatingFilter,
  heatingFuelFilter,
  viewFilter,
  buildingAmenitiesFilter,
  aviationAmenitiesFilter,
  outdoorAmenitiesFilter,
  monthlyHOADuesFilter,
];

export const realEstateFiltersMobile = realEstateFilters;

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Relevance key is used with keywords filter.
  // Keywords filter also sorts results according to relevance.
  relevanceFilter: 'pub_keywords',

  // Keyword filter is sorting the results by relevance.
  // If keyword filter is active, one might want to disable other sorting options
  // by adding 'keyword' to this list.
  conflictingFilters: [],

  options: [
    { key: 'pub_publishDate', label: 'Recent listings' },
    { key: 'price', label: 'Price (High to Low)' },
    { key: '-price', label: 'Price (Low to High)' },
    { key: 'pub_airframe_time', label: 'Total time (High to Low)' },
    { key: '-pub_airframe_time', label: 'Total time (Low to High)' },
    { key: 'pub_engine_time_max', label: 'Engine hours (High to Low)' },
    { key: '-pub_engine_time_min', label: 'Engine hours (Low to High)' },
    { key: 'pub_year', label: 'Year (Newest to Oldest)' },
    { key: '-pub_year', label: 'Year (Oldest to Newest)' },
    { key: 'meta_pageViewCount', label: 'Most viewed' },
    { key: 'meta_watchListingCount', label: 'Most saved' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    // { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

export const realEstateSortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Relevance key is used with keywords filter.
  // Keywords filter also sorts results according to relevance.
  relevanceFilter: 'pub_keywords',

  // Keyword filter is sorting the results by relevance.
  // If keyword filter is active, one might want to disable other sorting options
  // by adding 'keyword' to this list.
  conflictingFilters: [],

  options: [
    { key: 'pub_publishDate', label: 'Recent listings' },
    { key: 'price', label: 'Price (High to Low)' },
    { key: '-price', label: 'Price (Low to High)' },
    { key: 'meta_pageViewCount', label: 'Most viewed' },
    { key: 'meta_watchListingCount', label: 'Most saved' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    // { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

export const listing = {
  // These should be listing details from public data with schema type: enum
  // SectionDetailsMaybe component shows these on listing page.
  enumFieldDetails: ['size', 'brand', 'category'],
};

export const usaStates = [
  { key: "Alaska", label: "Alaska" },
  { key: "Alabama", label: "Alabama" },
  { key: "Arkansas", label: "Arkansas" },
  { key: "Arizona", label: "Arizona" },
  { key: "California", label: "California" },
  { key: "Colorado", label: "Colorado" },
  { key: "Connecticut", label: "Connecticut" },
  { key: "District of Columbia", label: "District of Columbia" },
  { key: "Delaware", label: "Delaware" },
  { key: "Florida", label: "Florida" },
  { key: "Georgia", label: "Georgia" },
  { key: "Hawaii", label: "Hawaii" },
  { key: "Iowa", label: "Iowa" },
  { key: "Idaho", label: "Idaho" },
  { key: "IL", label: "Illinois" },
  { key: "Illinois", label: "Indiana" },
  { key: "Kansas", label: "Kansas" },
  { key: "Kentucky", label: "Kentucky" },
  { key: "Louisiana", label: "Louisiana" },
  { key: "Massachusetts", label: "Massachusetts" },
  { key: "Maryland", label: "Maryland" },
  { key: "Maine", label: "Maine" },
  { key: "Michigan", label: "Michigan" },
  { key: "Minnesota", label: "Minnesota" },
  { key: "Missouri", label: "Missouri" },
  { key: "Mississippi", label: "Mississippi" },
  { key: "Montana", label: "Montana" },
  { key: "North Carolina", label: "North Carolina" },
  { key: "North Dakota", label: "North Dakota" },
  { key: "Nebraska", label: "Nebraska" },
  { key: "New Hampshire", label: "New Hampshire" },
  { key: "New Jersey", label: "New Jersey" },
  { key: "New Mexico", label: "New Mexico" },
  { key: "Nevada", label: "Nevada" },
  { key: "New York", label: "NewYork" },
  { key: "Ohio", label: "Ohio" },
  { key: "Oklahoma", label: "Oklahoma" },
  { key: "Oregon", label: "Oregon" },
  { key: "Pennsylvania", label: "Pennsylvania" },
  { key: "Rhode Island", label: "Rhode Island" },
  { key: "South Carolina", label: "South Carolina" },
  { key: "South Dakota", label: "South Dakota" },
  { key: "Tennessee", label: "Tennessee" },
  { key: "Texas", label: "Texas" },
  { key: "Utah", label: "Utah" },
  { key: "Virginia", label: "Virginia" },
  { key: "Vermont", label: "Vermont" },
  { key: "Washington", label: "Washington" },
  { key: "Wisconsin", label: "Wisconsin" },
  { key: "West Virginia", label: "West Virginia" },
  { key: "Wyoming", label: "Wyoming" },
];

export const editorToolbarOptions = {
  options: ['inline', 'list', 'link', 'emoji'],
  link: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    dropdownClassName: undefined,
    showOpenOptionOnHover: true,
    defaultTargetOption: '_blank',
    options: ['link', 'unlink'],
    link: { className: undefined },
    unlink: { className: undefined },
    linkCallback: undefined
  },
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['bold', 'italic', 'underline', 'strikethrough'],
  },
  list: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['unordered', 'ordered'],
  },
  textAlign: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['left', 'center', 'right', 'justify'],
  },
  emoji: {
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    emojis: [
      '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
      '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
      '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
      '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
      '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
      '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
      '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
      '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
      '✅', '❎', '💯',
    ],
  },
}

export const useOptions = [
  { value: 'Personal Use (Part 91)', label: 'Personal Use (Part 91)' },
  { value: 'Business Use (Part 91)', label: 'Business Use (Part 91)' },
  { value: 'Charter Use (Part 135)', label: 'Charter Use (Part 135)' },
];
