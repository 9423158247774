import unionWith from 'lodash/unionWith';
import get from 'lodash/get';
import axios from 'axios';

import config from '../../config';
import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';
import {
  isOriginInUse,
  prepareSearchParams,
} from '../../util/search';
import {
  parse,
  stringify,
} from '../../util/urlHelpers';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  getMakerAndModelAndCategoryForSearchFormByCanNames,
  getMakerAndModelAndCategoryForSearchFormByIds,
} from '../../ducks/AirplanesData.duck';
import {
  FILTER_KEY_MAKER,
  FILTER_KEY_MODEL,
  FILTER_KEY_PRICE,
  FILTER_KEY_YEAR,
  FILTER_KEY_CATEGORY,
  FILTER_KEY_AIRFRAME_TIME,
  FILTER_KEY_ENGINE_HOURS_1,
  FILTER_KEY_ENGINE_HOURS_2,
  FILTER_KEY_ENGINE_HOURS_3,
  FILTER_KEY_ENGINE_HOURS_4,
  FILTER_KEYS_ENGINE_HOURS,
  FILTER_KEY_SEATS,
  FILTER_KEY_MODEL_FAMILY,
  FILTER_KEY_PRICE_DECREASE,
  FILTER_KEY_PRICE_INCREASE,
  FILTER_KEY_HOURS_FLOWN,
  FILTER_KEY_HOME_TYPE,
  FILTER_KEY_YEAR_BUILT,
  FILTER_KEY_HAS_ACCIDENT_HISTORY,
  SHOW_CALL_FOR_PRICE_LISTINGS,
} from './SearchPage.constants';
import {
  openFields as openAirplanesModuleFields,
  fetchCategories as fetchAirplanesCategories, fetchAirplanesMetadata,
} from '../../modules/airplanes/airplanes.creator';
import { handleTransitAxiosResponse } from '../../util/apiHelpers';
import { greatGoodDealsService, restApiService } from '../../services/services';
import { LISTING_TYPE__REAL_ESTATE, LISTING_TYPE__AIRPLANES } from '../../util/editListingHelpers';
import {
  searchAirFeaturedListings,
} from '../../ducks/FeaturedListings.ducs';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 24;

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/SearchPage/SEARCH_MAP_LISTINGS_ERROR';

export const SET_SEARCH_VALUES = 'app/SearchPage/SET_SEARCH_VALUES';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/SearchPage/SEARCH_MAP_SET_ACTIVE_LISTING';

export const SET_PER_PAGE = 'app/SearchPage/SET_PER_PAGE';

// ================ Reducer ================ //

const initialSearchValues = {
  [FILTER_KEY_MAKER]: null,
  [FILTER_KEY_MODEL]: null,
  [FILTER_KEY_MODEL_FAMILY]: null,
  [FILTER_KEY_CATEGORY]: null,
  [FILTER_KEY_PRICE]: null,
  [FILTER_KEY_YEAR]: null,
  [FILTER_KEY_AIRFRAME_TIME]: null,
  [FILTER_KEY_ENGINE_HOURS_1]: null,
  [FILTER_KEY_ENGINE_HOURS_2]: null,
  [FILTER_KEY_ENGINE_HOURS_3]: null,
  [FILTER_KEY_ENGINE_HOURS_4]: null,
  [FILTER_KEY_SEATS]: null,
  [FILTER_KEY_PRICE_INCREASE]: null,
  [FILTER_KEY_PRICE_DECREASE]: null,
  [FILTER_KEY_HOURS_FLOWN]: null,
  [FILTER_KEY_HOME_TYPE]: null,
  [FILTER_KEY_HAS_ACCIDENT_HISTORY]: null,
};

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  searchMapListingIds: [],
  searchMapListingsError: null,
  searchValues: initialSearchValues,
  perPage: RESULT_PAGE_SIZE,
};

const resultIds = data => data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case SEARCH_MAP_LISTINGS_REQUEST:
      return {
        ...state,
        searchMapListingsError: null,
      };
    case SEARCH_MAP_LISTINGS_SUCCESS: {
      const searchMapListingIds = unionWith(
        state.searchMapListingIds,
        resultIds(payload.data),
        (id1, id2) => id1.uuid === id2.uuid
      );
      return {
        ...state,
        searchMapListingIds,
      };
    }
    case SEARCH_MAP_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchMapListingsError: payload };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };

    case SET_SEARCH_VALUES:
      return {
        ...state,
        searchValues: {
          ...state.searchValues,
          ...payload,
        },
      };

    case SET_PER_PAGE:
      return {
        ...state,
        perPage: payload,
      };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Selectors ====================== //

const $subState = state => {
  return state.SearchPage;
};

export const $searchValues = state => {
  return state.SearchPage.searchValues;
};

export const $searchMetadata = state => {
  const searchValues = $searchValues(state);
  return {
    category: get(searchValues, 'pub_category[0]', null),
    maker: get(searchValues, 'pub_make', null),
    modelFamily: get(searchValues, 'pub_model_family', null),
    model: get(searchValues, 'pub_model', null),
  };
};

const $perPage = state => {
  return $subState(state).perPage;
};

// ================ Actions ================ //

const setSearchValuesAction = values => ({
  type: SET_SEARCH_VALUES,
  payload: values,
});

const setPerPageAction = perPage => ({
  type: SET_PER_PAGE,
  payload: perPage,
});

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = (data, meta) => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data, meta },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchMapListingsRequest = () => ({ type: SEARCH_MAP_LISTINGS_REQUEST });

export const searchMapListingsSuccess = response => ({
  type: SEARCH_MAP_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchMapListingsError = e => ({
  type: SEARCH_MAP_LISTINGS_ERROR,
  error: true,
  payload: e,
});

const searchReListings = (urlParams, searchParams) => dispatch => {
  dispatch(searchListingsRequest(searchParams));
  const allParams = prepareSearchParams(urlParams, searchParams);
  const urlSearchParams = new URLSearchParams(allParams);
  const url = new URL(`${config.apiEndpoint}/api/search/re`);
  url.search = urlSearchParams.toString();
  return axios.get(url)
    .then(handleTransitAxiosResponse)
    .then(response => {
      const data = get(response, 'data.data', null);
      if (!get(response, 'data.data', null)) {
        return Promise.resolve();
      }
      dispatch(addMarketplaceEntities(response));
      const meta = get(response, 'data.meta', {});
      dispatch(searchListingsSuccess(data, meta));
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};

export const searchAirListings = (urlParams, search) => async dispatch => {
  const searchParams = listingsRequestParams(urlParams, search);
  dispatch(searchListingsRequest(searchParams));
  try {
    const currentPage = get(searchParams, 'page', 1);
    if (currentPage === 1) {
      dispatch(searchAirFeaturedListings(urlParams, searchParams));
    }
    const allParams = prepareSearchParams(urlParams, searchParams);
    const listingsResponse = await restApiService.publicInstance.getRaw(`search/air?${stringify(allParams)}`);
    const parsedListingsResponse = handleTransitAxiosResponse(listingsResponse);
    dispatch(addMarketplaceEntities(parsedListingsResponse));
    const data = parsedListingsResponse.data.data;
    const meta = parsedListingsResponse.data.meta;

    dispatch(searchListingsSuccess(data, meta));
    dispatch(fetchAirplanesMetadata(data));
    return Promise.resolve(parsedListingsResponse);
  } catch (e) {
    dispatch(searchListingsError(storableError(e)));
    return Promise.reject(e);
  }
};

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

export const searchMapListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchMapListingsRequest(searchParams));

  const { perPage, ...rest } = searchParams;
  const params = {
    ...rest,
    per_page: perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchMapListingsSuccess(response));
      dispatch(fetchAirplanesMetadata(response.data.data));
      return response;
    })
    .catch(e => {
      dispatch(searchMapListingsError(storableError(e)));
      throw e;
    });
};

const listingsRequestParams = (params, search) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = isOriginInUse(config) && origin ? { origin } : {};
  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;

  return {
    ...rest,
    ...originMaybe,
    page,
    perPage: RESULT_PAGE_SIZE,
    pub_listingType: LISTING_TYPE__AIRPLANES,
    include: ['author', 'images'],
    'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData.companyName'],
    'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
  };
};

export const loadData = (params, search) => dispatch => {
  dispatch(setSearchValues(initialSearchValues));
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  dispatch(setInitialFilterValues(params, search));
  dispatch(openAirplanesModuleFields());
  dispatch(fetchAirplanesCategories());
  dispatch(greatGoodDealsService.fetchDealsCount(queryParams));
  return Promise.resolve();
};

export const setSearchValues = values => dispatch => {
  dispatch(setSearchValuesAction(values));
};

const rangeSearchParamToRange = param => {
  if (!param) {
    return null;
  }
  if (param.includes(',')) {
    const splitet = param.split(',');
    const min = Number(splitet[0]);
    const max = Number(splitet[1]);
    return `${min},${max - 1}`;
  }
  return `${param},${param}`;
};

const setInitialFilterValues = (params, search) => dispatch => {
  search = new URLSearchParams(search);
  dispatch(setSearchValues({
    [FILTER_KEY_YEAR]: rangeSearchParamToRange(search.get(FILTER_KEY_YEAR)),
    [FILTER_KEY_PRICE]: search.get(FILTER_KEY_PRICE),
    [FILTER_KEY_AIRFRAME_TIME]: rangeSearchParamToRange(search.get(FILTER_KEY_AIRFRAME_TIME)),
    ...FILTER_KEYS_ENGINE_HOURS.reduce((acc, curr) => {
      return { ...acc, [curr]: rangeSearchParamToRange(search.get(curr)) };
    }, {}),
    [FILTER_KEY_SEATS]: rangeSearchParamToRange(search.get(FILTER_KEY_SEATS)),
    [FILTER_KEY_PRICE_INCREASE]: rangeSearchParamToRange(search.get(FILTER_KEY_PRICE_INCREASE)),
    [FILTER_KEY_PRICE_DECREASE]: rangeSearchParamToRange(search.get(FILTER_KEY_PRICE_DECREASE)),
    [FILTER_KEY_HOURS_FLOWN]: rangeSearchParamToRange(search.get(FILTER_KEY_HOURS_FLOWN)),
    [FILTER_KEY_HAS_ACCIDENT_HISTORY]: search.get(FILTER_KEY_HAS_ACCIDENT_HISTORY),
  }));
  // set maker, model and category values
  const categoryParam = params.category;
  const categoryQueryId = search.get(FILTER_KEY_CATEGORY);
  const makerQueryId = search.get(FILTER_KEY_MAKER);
  const modelFamilyQueryId = search.get(FILTER_KEY_MODEL_FAMILY);
  const modelQueryId = search.get(FILTER_KEY_MODEL);
  const isSearchByCanNames = !!categoryParam;
  const isSearchByIds = categoryQueryId || makerQueryId || modelFamilyQueryId || modelQueryId;
  let api;
  let apiParams;
  if (isSearchByCanNames) {
    api = getMakerAndModelAndCategoryForSearchFormByCanNames;
    apiParams = [
      categoryParam,
      params.maker,
      params.modelFamily,
      params.model,
    ];
  } else if (isSearchByIds) {
    api = getMakerAndModelAndCategoryForSearchFormByIds;
    apiParams = [
      categoryQueryId,
      makerQueryId,
      modelFamilyQueryId,
      modelQueryId,
    ];
  }
  if (isSearchByIds || isSearchByCanNames) {
    return dispatch(api(...apiParams))
      .then(response => {
        if (!response) {
          return ;
        }
        dispatch(setSearchValues(response));
        return response
      })
    ;
  }
  return Promise.resolve();
};

const setRealEstateInitialFilterValues = search => dispatch => {
  search = new URLSearchParams(search);
  const homeType = search.get(FILTER_KEY_HOME_TYPE);
  dispatch(setSearchValues({
    [FILTER_KEY_HOME_TYPE]: homeType && homeType.split(','),
    [FILTER_KEY_PRICE]: search.get(FILTER_KEY_PRICE),
    [FILTER_KEY_YEAR_BUILT]: rangeSearchParamToRange(search.get(FILTER_KEY_YEAR_BUILT)),
  }));
};

export const loadRealEstateData = (params, search) => dispatch => {
  dispatch(setSearchValues(initialSearchValues));
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = isOriginInUse(config) && origin ? { origin } : {};

  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;
  dispatch(setRealEstateInitialFilterValues(search));

  return dispatch(searchReListings(params, {
    ...rest,
    ...originMaybe,
    page,
    perPage: RESULT_PAGE_SIZE,
    pub_listingType: LISTING_TYPE__REAL_ESTATE,
    include: ['author', 'images'],
    'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData.companyName'],
    'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
  }));
};
