export const replaceEmails = (content, replacement) => {
  let html = content;

  // Replace hyperlinks like <a href="mailto:one@two.three" target="__blank">one@two.three</a>
  const mailtoWithEmailMatch = html.match(/<a(.*?)>(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))<\/a>/g);
  if (mailtoWithEmailMatch && mailtoWithEmailMatch.length) {
    mailtoWithEmailMatch.forEach(match => {
      const emailMatch = match.match(/<a(.*?)>(.*?@.*?)<\/a>/);
      html = html.replace(emailMatch[0], replacement);
    });
  }

  // Replace hyperlinks like <a href="mailto:one@two.three" target="__blank">Mail me</a>
  const mailtoWithoutEmailMatch = html.match(/<a.*?(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})).*?>(.*?)<\/a>/g);
  if (mailtoWithoutEmailMatch && mailtoWithoutEmailMatch.length) {
    mailtoWithoutEmailMatch.forEach(match => {
      const emailMatch = match.match(/<a.*?(.*?@.*?).*?>.*?<\/a>/);
      html = html.replace(emailMatch[0], replacement);
    });
  }

  // Replace string like one@two.three
  const re = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g;
  return html.replace(re, replacement);
};
