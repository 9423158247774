import {
  restApiService,
} from '../services/services';

const COUNTS_BY_CATEGORIES_REQUEST = 'app/AirMetadata/COUNTS_BY_CATEGORIES_REQUEST';
const COUNTS_BY_CATEGORIES_SUCCESS = 'app/AirMetadata/COUNTS_BY_CATEGORIES_SUCCESS';
const COUNTS_BY_CATEGORIES_ERROR = 'app/AirMetadata/COUNTS_BY_CATEGORIES_ERROR';

const CATEGORIES_REQUEST = 'app/AirMetadata/CATEGORIES_REQUEST';
const CATEGORIES_SUCCESS = 'app/AirMetadata/CATEGORIES_SUCCESS';
const CATEGORIES_ERROR = 'app/AirMetadata/CATEGORIES_ERROR';

const CLEAR_COUNTS_BY_CATEGORIES = 'app/AirMetadata/CLEAR_COUNTS_BY_CATEGORIES';
const CLEAR_CATEGORIES = 'app/AirMetadata/CLEAR_CATEGORIES';
const CLEAR_STATE = 'app/AirMetadata/CLEAR_STATE';

const initialCountsByCategories = {
  countsByCategoriesIsPending: false,
  countsByCategoriesError: null,
  countsByCategories: {},
};

const initialCategories = {
  categoriesIsPending: false,
  categoriesError: null,
  categories: [],
};

const initialState = {
  ...initialCountsByCategories,
  ...initialCategories,
};

export default function aircraftParentLandingPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case COUNTS_BY_CATEGORIES_REQUEST:
      return {
        ...state,
        countsByCategoriesIsPending: true,
        countsByCategoriesError: null,
      };

    case COUNTS_BY_CATEGORIES_SUCCESS:
      return {
        ...state,
        countsByCategoriesIsPending: false,
        countsByCategories: payload,
      };

    case COUNTS_BY_CATEGORIES_ERROR:
      return {
        ...state,
        countsByCategoriesIsPending: false,
        countsByCategoriesError: payload,
      };

    case CATEGORIES_REQUEST:
      return {
        ...state,
        categoriesIsPending: true,
        categoriesError: null,
      };

    case CATEGORIES_SUCCESS:
      return {
        ...state,
        categoriesIsPending: false,
        categories: payload,
      };

    case CATEGORIES_ERROR:
      return {
        ...state,
        categoriesIsPending: false,
        categoriesError: payload,
      };

    case CLEAR_COUNTS_BY_CATEGORIES:
      return {
        ...state,
        ...initialCountsByCategories,
      };

    case CLEAR_CATEGORIES:
      return {
        ...state,
        ...initialCategories,
      };

    case CLEAR_STATE:
      return initialState;

    default:
      return state;
  }
}

const countsByCategoriesRequest = () => ({
  type: COUNTS_BY_CATEGORIES_REQUEST,
});

const countsByCategoriesSuccess = counts => ({
  type: COUNTS_BY_CATEGORIES_SUCCESS,
  payload: counts,
});

const countsByCategoriesError = error => ({
  type: COUNTS_BY_CATEGORIES_ERROR,
  payload: error,
});

const categoriesRequest = () => ({
  type: CATEGORIES_REQUEST,
});

const categoriesSuccess = categories => ({
  type: CATEGORIES_SUCCESS,
  payload: categories,
});

const categoriesError = error => ({
  type: CATEGORIES_ERROR,
  payload: error,
});

const clearCountsByCategories = () => ({
  type: CLEAR_COUNTS_BY_CATEGORIES,
});

const clearCategories = () => ({
  type: CLEAR_CATEGORIES,
});

const clearStateAction = () => ({
  type: CLEAR_STATE,
});

const $subState = state => {
  return state.AirMetadata;
};

export const $countsByCategoriesState = state => {
  const subState = $subState(state);
  return {
    isPending: subState.countsByCategoriesIsPending,
    error: subState.countsByCategoriesError,
    counts: subState.countsByCategories,
  };
};

export const $categoriesState = state => {
  const subState = $subState(state);
  return {
    isPending: subState.categoriesIsPending,
    error: subState.categoriesError,
    categories: subState.categories,
  };
};

export const clearCountByCategoriesState = () => dispatch => {
  dispatch(clearCountsByCategories());
};

export const clearCategoriesState = () => dispatch => {
  dispatch(clearCategories());
};

export const clearState = () => dispatch => {
  dispatch(clearStateAction());
};

export const fetchCountsByCategories = () => dispatch => {
  dispatch(countsByCategoriesRequest());
  return restApiService.publicInstance
    .getRaw('airMetadata/categoriesCounts', {
      params: { idsStr: '2,9,7,4,6,5,1,8,3' },
    })
    .then(resp => {
      const counts = resp.data;
      dispatch(countsByCategoriesSuccess(counts));
      return resp;
    })
    .catch(error => {
      dispatch(countsByCategoriesError(error));
      throw e;
    })
  ;
}

export const fetchCategories = () => dispatch => {
  dispatch(categoriesRequest());
  return restApiService.publicInstance
    .getRaw('airMetadata/categories')
    .then(resp => {
      const data = resp.data;
      dispatch(categoriesSuccess(data));
      return data;
    })
    .catch(error => {
      dispatch(categoriesError(error));
      throw e;
    })
  ;
};
