import { $fields } from 'simple-redux-field';

import {
  FIELD__AIRPLANES_CATEGORIES,
  INITIAL__AIRPLANES_CATEGORIES,
  FIELD__AIRPLANES_METADATA,
  INITIAL__AIRPLANES_METADATA,
} from './airplanes.constants';

export const $airplanesCategoriesField = state => {
  return $fields(state)[FIELD__AIRPLANES_CATEGORIES]
    || INITIAL__AIRPLANES_CATEGORIES;
};

export const $airplanesMetadataField = state => {
  return $fields(state)[FIELD__AIRPLANES_METADATA]
    || INITIAL__AIRPLANES_METADATA;
};
