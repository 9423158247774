import React from 'react';

const FairDealIcon = (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        width="18"
        height="18"
        fill="#759F75"
        {...props}
      />
      <g clipPath="url(#clip0_3856_336)">
        <path
          d="M12.3686 4.82629L7.27331 9.92164L5.62077 8.26909L4 9.88985L5.65254 11.5424L7.28389 13.1738L8.90467 11.553L14 6.45766L12.3686 4.82629Z"
          fill={props.symbol || "white"}
        />
      </g>
      <defs>
        <clipPath id="clip0_3856_336">
          <rect
            width="10"
            height="10"
            fill="white"
            transform="translate(4 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FairDealIcon;
