import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaFacebook.module.css';

const IconSocialMediaFacebook = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="18"
      fill="none"
      viewBox="0 0 9 18"
    >
      <path
        fill="#E32922"
        fillRule="evenodd"
        d="M8.679.763c-.272-.04-1.206-.12-2.29-.12-2.27 0-3.828 1.386-3.828 3.927v2.19H0v2.973h2.561v7.624h3.074V9.733h2.551l.392-2.973H5.635V4.862c0-.854.231-1.447 1.467-1.447h1.577V.763z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

IconSocialMediaFacebook.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaFacebook.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaFacebook;
