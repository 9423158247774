import { BaseReducer } from 'redux-di-container';
import omit from 'lodash/omit';

import {
  FILTER_KEY_ESTIMATE_EVALUATION_TEXT,
  FILTER_KEY_ESTIMATE_PUBLISHABLE,
  FILTER_KEY_AIRCRAFT_LISTING_TYPE,
} from '../containers/SearchPage/SearchPage.constants.js';
import {
  addMarketplaceEntities,
  getMarketplaceEntities,
} from '../ducks/marketplaceData.duck.js';
import { fetchAirplanesMetadata } from '../modules/airplanes/airplanes.creator.js';
import { denormalisedResponseEntities } from '../util/data.js';
import { getImagesParams } from '../util/listing';
import { handleTransitAxiosResponse } from '../util/apiHelpers';
import { stringify } from '../util/urlHelpers';

export class GreatGoodDealsService extends BaseReducer {
  static GREAT_DEAL = 'Great Deal';
  static GOOD_DEAL = 'Good Deal';
  static HIGH_DEAL = 'High';
  static OVERPRICED_DEAL = 'Overpriced';
  static FAIR_DEAL = 'Fair Deal';
  static NO_EVALUATION_AVAILABLE_DEAL = 'No Evaluation Available';
  static GREAT_COLOR = '#2E9C2C';
  static GOOD_COLOR = '#407E3F';
  static HIGH_COLOR = '#D58E4D';
  static OVERPRICED_COLOR = '#E32922';
  static FAIR_COLOR = '#759F75';
  static NO_EVALUATION_AVAILABLE_COLOR = '#B6B6B6';

  static getDealColor(estimate_evaluation_text) {
    switch (estimate_evaluation_text) {
      case GreatGoodDealsService.GREAT_DEAL:
        return GreatGoodDealsService.GREAT_COLOR;
      case GreatGoodDealsService.GOOD_DEAL:
        return GreatGoodDealsService.GOOD_COLOR;
      case GreatGoodDealsService.OVERPRICED_DEAL:
        return GreatGoodDealsService.OVERPRICED_COLOR;
      case GreatGoodDealsService.FAIR_DEAL:
        return GreatGoodDealsService.FAIR_COLOR;
      case GreatGoodDealsService.HIGH_DEAL:
        return GreatGoodDealsService.HIGH_COLOR;
      default:
        return GreatGoodDealsService.NO_EVALUATION_AVAILABLE_COLOR;
    }
  }

  static DEALS = [
    GreatGoodDealsService.GREAT_DEAL,
    GreatGoodDealsService.GOOD_DEAL,
    GreatGoodDealsService.HIGH_DEAL,
    GreatGoodDealsService.OVERPRICED_DEAL,
    GreatGoodDealsService.FAIR_DEAL,
    GreatGoodDealsService.NO_EVALUATION_AVAILABLE_DEAL,
  ];

  constructor(opts) {
    super();
    this.restApiService = opts.restApiService;
  }

  getInitialState() {
    const dealsObject = {};
    GreatGoodDealsService.DEALS.forEach(deal => {
      dealsObject[deal] = 0;
    });

    return {
      greatGoodDealsIds: [],
      dealCounts: dealsObject,
    }
  }

  $getAllDealsCount(state) {
    return this.select(state).dealCounts;
  }

  $getGreatGoodDeals(state) {
    const goodDealListingsRefs = this.select(state).greatGoodDealsIds.map(id => ({ type: 'listing', id }));
    return getMarketplaceEntities(state, goodDealListingsRefs);
  }

  fetchDealsCount = (queryParams = null) => (dispatch, getState, sdk) => {
    queryParams = {
      [FILTER_KEY_ESTIMATE_PUBLISHABLE]: true,
      ...queryParams ? omit(queryParams, [FILTER_KEY_ESTIMATE_EVALUATION_TEXT]) : {},
      'fields.listing': [],
    };
    return GreatGoodDealsService.DEALS.map(dealName => {
      queryParams = {
        ...queryParams,
        [FILTER_KEY_ESTIMATE_EVALUATION_TEXT]: [dealName],
      }
      const paramsStr = stringify(queryParams);
      this.restApiService.publicInstance
        .getRaw(`search/air-good-deals?${paramsStr}`)
        .then(handleTransitAxiosResponse)
        .then(async response => {
          this.dispatchAction((state) => {
            state.dealCounts[dealName] = response.data.meta.totalItems;
          }, `${dealName}Success`);
        })
        .catch(e => {
          throw e;
        });
      }
    )
  }

  fetchGreatGoodDealListings = searchParams => (dispatch, getState, sdk) => {
    const params = {
      ...searchParams,
      per_page: 4,
      [FILTER_KEY_ESTIMATE_EVALUATION_TEXT]: ['Great Deal', 'Good Deal'],
      [FILTER_KEY_ESTIMATE_PUBLISHABLE]: true,
      [FILTER_KEY_AIRCRAFT_LISTING_TYPE]: 'For Sale',
      sort: 'pub_publishDate',
      include: ['images'],
      ...getImagesParams(),
    };

    return this.restApiService.publicInstance
      .getRaw('search/air-good-deals', { params })
      .then(handleTransitAxiosResponse)
      .then(async response => {
        dispatch(addMarketplaceEntities(response));
        dispatch(fetchAirplanesMetadata(response.data.data));
        const listings = denormalisedResponseEntities(response);
        const listingsIds = listings.map(listing => listing.id);
        this.dispatchAction(state => {
          state.greatGoodDealsIds = listingsIds;
        }, 'greatGoodDealsIdsSuccess')
        return response;
      })
      .catch(e => {
        throw e;
      });
  };
}
