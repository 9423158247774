import { fieldSet } from 'simple-redux-field';

import { fetchUserApi } from './user.api';
import { FIELD__USER_ADDITIONAL_DATA, NAMESPACE } from './user.constants';

export const fetchUser = id => dispatch => {
  dispatch(fieldSet(FIELD__USER_ADDITIONAL_DATA, {
    inProgress: true,
    error: null,
  }, NAMESPACE));

  return fetchUserApi(id.uuid)
    .then(response => {
      const profile = response.user.data.data.attributes.profile;
      dispatch(fieldSet(FIELD__USER_ADDITIONAL_DATA, {
        inProgress: false,
        value: {
          [id.uuid]: {
            attributes: {
              profile,
            },
          },
        },
      }, NAMESPACE));
      return response;
    })
    .catch(e => {
      dispatch(fieldSet(FIELD__USER_ADDITIONAL_DATA, {
        inProgress: false,
        error: e.message,
      }, NAMESPACE));
      throw e;
    })
  ;
};
