import axios from 'axios';

import { apiEndpoint } from '../../../util/apiHelpers';

export const fetchUnauthEnquiry = (toUserId, lName, fname, lname, email, message, phone, isFinance, leads) => {
  const url = `${apiEndpoint}/listing/unauth-enquiry`;
  return axios.post(url, {
    t_u_i: toUserId,
    l_u: window.location.href,
    l_n: lName,
    e: email,
    first_n: fname,
    last_n: lname,
    m: message,
    ...(phone && { p: phone }),
    i_f: isFinance,
    ...(leads && { leads }),
  });
};
