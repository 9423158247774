import classNames from 'classnames';
import React from 'react';
import numeral from 'numeral';

import {
  getPrice,
} from '../../util/listing.js';
import { FormattedMessage } from '../../util/reactIntl.js';

import IconArrow from '../IconArrowUp/IconArrow.js';

import css from './PriceDifference.module.scss';

const PriceDifference = (props) => {
  const {
    formattedPrice,
    priceTitle,
    lastItem
  } = props;

  const defaultPrice = lastItem?.defaultPrice / 100;
  const formattedDefaultPrice = numeral(defaultPrice).format('$0,0');

  const price = numeral(formattedPrice).value();

  const {percents, priceChangeUp} = getPrice(defaultPrice, price);

  const priceChangeStyles = classNames(css.percentsDifference, {
    [css.percentsDifferenceUp]: priceChangeUp,
    [css.percentsDifferenceDown]: !priceChangeUp,
  })

  const callForPrice = <FormattedMessage id='AirListingPage.callForPrice' />;

  return (
    <div className={css.priceDifferenceWrapper}>
      {!!percents &&
        <div className={css.firstPrice}>
          {defaultPrice === 0 ? callForPrice : formattedDefaultPrice}
        </div>
      }
      <div className={css.lastPrice} title={priceTitle}>
        {price === 0 ? callForPrice : formattedPrice}
      </div>
      {!!percents &&
        <div className={priceChangeStyles}>
          ({priceChangeUp && '+'}{percents}%)
          <IconArrow className={priceChangeStyles && classNames( {[css.rotateArrow]: !priceChangeUp})} />
        </div>
      }
    </div>
  )
}

export default PriceDifference;
