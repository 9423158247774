import React, { useState, useEffect } from 'react';
import config from '../../config';
import { EditorState, convertToRaw, convertFromRaw, getDefaultKeyBinding, KeyBindingUtil } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg';

import css from './TextEditor.module.scss';

const {hasCommandModifier} = KeyBindingUtil;

const TextEditor = props => {

  const { fieldValue, label, placeholder, input, meta } = props

  const fieldValueToString = !!fieldValue ? JSON.stringify(fieldValue) : JSON.stringify('');
  const fieldValueToObject = JSON.parse(fieldValueToString);

  const validContent = fieldValueToObject.indexOf("blocks") !== -1;
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  const myKeyBindingFn = (e) => {
    if (e?.keyCode === 90 && e?.ctrlKey && hasCommandModifier(e)) {
      return 'myeditor-save';
    }
    return getDefaultKeyBinding(e);
  }

  useEffect(() => {
    if (!!fieldValue?.length) {

      const contentForOldListing = {
        "entityMap": {},
        "blocks": [{
          "key": "637gr", "text": `${fieldValueToString.replaceAll('"', '')}`,
          "type": "unstyled", "depth": 0,
          "inlineStyleRanges": [],
          "entityRanges": [],
          "data": {}
        }]
      };

      if (validContent) {
        const content = convertFromRaw(JSON.parse(fieldValue))
        const editorStateInitialValues = EditorState.createWithContent(content)
        setEditorState(editorStateInitialValues)
      } else {
        setEditorState(EditorState.createWithContent(convertFromRaw(contentForOldListing)))
      }
    }
  }, [])

  const handleEditorChange = (state) => {
    setEditorState(state);
    return input.onChange(JSON.stringify(convertToRaw(state.getCurrentContent())))
  }

  const selection = editorState.getSelection();
  const selectedType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className={css.root}>
      {!!label && <label className={css.label}>{label}</label>}
      <Editor
        stripPastedStyles={true}
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        wrapperClassName={css.editorWrapper}
        editorClassName={css.editor}
        toolbar={config.custom.editorToolbarOptions}
        placeholder={selectedType === 'unstyled' ? placeholder : null}
        // keyBindingFn={myKeyBindingFn}
      />
    </div>
  )
}

export default TextEditor;
